import {observable, observe} from "../../lib/mobx/mobx-2.6.2.umd";
import _String from "../../lib/base/string";
import Base64 from "../../lib/base/base64";

let errorInfo = {
    "400": "请求无效JSON格式或缺少的字段",
    "401": "无效的Token",
    "403": "没有权限修改",
    "404": "不存在",
    "409": "已经存在"
};

function encodePassword(pwd) {
    if (pwd) {
        var base64Val = Base64.encode(pwd);
        var items1 = [];
        var items2 = [];
        for (var i = 0; i < base64Val.length; i++) {
            if (i % 2 === 0) {
                items1.push(base64Val.charAt(i));
            } else {
                items2.push(base64Val.charAt(i));
            }
        }
        var ret = ":pd:" + items1.join("") + items2.join("") + ":pd:";
        return ret;
    }
    return pwd;
}

function processPassword(pw) {
    return encodePassword(typeof (pw) === 'string' ? _String.trim(pw) : pw);
}

function processUser(user) {
    if (user.name && typeof user.name === "object") {
        user.name = user.name.formatted || user.name.givenName;
    }
    user.name = user.name || user.userName;
    try {
        user.name = decodeURI(user.name);
    } catch (e) {/*忽略异常*/
    }
    if (user.emails) {
        user.email = (user.emails && user.emails[0] && user.emails[0].value) || "";
    }
    user._groups = user.groups;
    user.groups = getGroupsName(user.groups || []);
    if (user.created) {
        user.createTime = new Date(user.created);
    }

    user.phone = user.phoneNumber || ((user.phoneNumbers && user.phoneNumbers[0]) ? user.phoneNumbers[0].value : "") || "";
}

function getGroupsName(groups) {
    var sysGroups = ['uaa.offline_token', 'approvals.me', 'user_attributes', 'cloud_controller.read', 'roles', 'oauth.approvals',
        'cloud_controller_service_permissions.read', 'scim.me', 'openid', 'uaa.user', 'profile', 'cloud_controller.write', 'password.write',
        'scim.userids', "scim.read", "acme", "acme.dev"];
    var ret = [];
    for (let i = 0, len = groups.length; i < len; i++) {
        let group = groups[i];
        var name = group.name;
        if (sysGroups.indexOf(name) < 0)
            ret.push(decodeURI(name));
    }
    return ret;
}

class UserImpl {

    static getInstance() {
        if (!window?.UserImplInstance) {
            window.UserImplInstance = new UserImpl();
        }
        return window.UserImplInstance;
    }

    constructor() {
        this.currentUserObservable = observable(null);
        this.userIdObservable = observable(null);
        this.userSessionObservable = observable(null);
        this.isLoginObservable = observable(false);
    }


    login({username, password, pretoken,captcha, type,header},page) {
        header=Object.assign(header||{},{
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
        });
        return new Promise((resolve, reject) => {
            page.request({
                method: "POST",
                url: page.getConfig(['components','uixContainer','UserImplement','loginUrl']) || "/login",
                dataType: "json",
                header,
                data: {
                    username: username,
                    password: processPassword(password),
                    pretoken,
                    captcha
                }
            }).then((res) => {
                page.getConfig(['components','uixContainer','UserImplement','loginResultProcess'])?.(res);
                if (res && res.statusCode == 200) {
                    this.userIdObservable.set(res.data.user_id);
                    this.isLoginObservable.set(true);
                    this.getUserInfo(page).then(user => {
                        this.currentUserObservable.set(user);
                        resolve(user);
                    }, err => {
                        reject(err);
                    });
                } else {
                    reject(res);
                }
            }).catch((error) => {
                page.getConfig(['components','uixContainer','UserImplement','loginResultProcess'])?.(error);
                reject(error.data.message);
            });
        });
    }

    resetPassword({tel,code,newPassword},page){
        if(!tel||!code||!newPassword){
            return;
        }
        return new Promise((resolve, reject) => {
            page.request({
                method : "POST",
                url :  "/entry/uaa/reset_password",
                header: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                dataType : "json",
                data : {
                    code : code,
                    tel : tel,
                    password: processPassword(newPassword)
                }
            }).then((res)=>{
                resolve(res);
            }).catch((error)=>{
                reject(error);
            });
        });
    }

    sendVerifyCode({type,tel},page){
        if(!tel){
            return;
        }
        return new Promise((resolve, reject) => {
            return page.request({
                method : "POST",
                url : "/entry/sms/verifyCode",
                xhrFields : {
                    withCredentials : true
                },
                dataType : "json",
                data : JSON.stringify({
                    template : type,
                    phone : tel
                })
            }).then((res)=>{
                resolve(res);
            }).catch((error)=>{
                reject(error);
            });
        });
    }

    checkLogin(page) {
        return new Promise((resolve, reject) => {
            page.request({
                url:  "/entry/uaa/userinfo",
                method : "GET",
                dataType:"json",
                cache: false
            }).then((res) => {
                if (res && res.statusCode == 200) {
                    this.userIdObservable.set(res.data.user_id);
                    this.isLoginObservable.set(true);

                    //存储userSession，打开第三方页面时作为token参数
                    let userSession = res.header["x-user-session"];
                    this.userSessionObservable.set({userSession:userSession});
                    window.microService.currentUserSession = userSession;

                    this.getUserInfo(page).then(user => {
                        resolve(user);
                    }, err => {
                        reject(err);
                    });
                } else {
                    reject(res);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    logout(page) {
        return new Promise((resolve, reject) => {
            page.request({
                url: page.getConfig(['components','uixContainer','UserImplement','logoutUrl']) || "/logout",
                xhrFields: {
                    withCredentials: true
                },
                dataType: "text",
                cache: false
            }).then((res) => {
                this.userIdObservable.set(null);
                this.userSessionObservable.set(null);
                this.currentUserObservable.set(null);
                this.isLoginObservable.set(false);
                resolve(res);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    changePassword(oldPassword, password,page) {
        return new Promise((resolve, reject) => {
            let uid = this.getUserIdObservable().get();
            if (this.getLoginObservable().get() && uid) {
                page.request({
                    method: "PUT",
                    url:  "/entry/uaa/Users/" + uid + "/password",
                    data: JSON.stringify({
                        oldPassword: processPassword(oldPassword),
                        password: processPassword(password)
                    }),
                    header: {
                        'content-type': 'application/json',
                        "DBREST-RESOURCE": "class",
                        "X-MODULE": "uaa",
                        "Accept": "application/json"
                    }
                }).then((res) => {
                    resolve(res);
                }).catch((error) => {
                    reject(error.data.message);
                });
            } else{
                reject("用户未登录");
            }
        });
    }


    getUserInfo(page) {
        return new Promise((resolve, reject) => {
            let uid = this.getUserIdObservable().get();
            if (this.getLoginObservable().get() && uid) {
                page.request({
                    method: "GET",
                    url: "/entry/uaa/Users/" + uid,
                    header: {
                        'content-type': 'application/json',
                        "DBREST-RESOURCE": "class",
                        "X-MODULE": "uaa",
                        Accept: "application/json"
                    }
                }).then((res) => {
                    processUser(res.data);
                    this.currentUserObservable.set(res.data);
                    resolve(res.data);
                }).catch((error) => {
                    reject(errorInfo[error.status]);
                });
            } else
                reject({errorCode: "NOT_LOGINED"});
        });
    }

    getLoginObservable = () => this.isLoginObservable;

    getCurrentUserObservable = () => this.currentUserObservable;

    getUserIdObservable = () => this.userIdObservable;

    getUserSessionObservable = () => this.userSessionObservable;
}


export default UserImpl.getInstance();
