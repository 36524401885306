import Component from "../../lib/base/component";
import ShareData from "../../lib/base/shareData";
import Schema from "./schema";
import {untracked, computed, transaction, observable, extendObservable, autorun, reaction,toJS, isObservableArray, isObservableObject, isObservable, isComputed } from  "../../lib/mobx/mobx-2.6.2.umd";
import {parsePath} from "../../lib/base/pageImpl";
import {isArray, isObject, cloneJSON,requireBatchTransition} from "../../lib/base/util";
import BatchRequest from "../../lib/base/batchRequest";
import UUID from "../../lib/base/uuid";
import {cloneConfig, appendCols, buildConfig} from "./util";
import _String from "../../lib/base/string";


/**
 * data要求： 1. 路径中不允许使用特殊的标识"@, [, ]"(因为@, [, ]作为数组的过虑条件, 例如: a.b.c[@id=001]); 2.
 * 数组数据项的key值不允许使用特殊的标识"[, ]"
 * 
 * 数据的操作 1. 删除数据: 直接操作mobx数组, 例如this.comp("data").value.split(0, 1); 2. 修改数据:
 * 直接操作mobx对象, 例如this.comp("data").value[0].name = "xxx"; 3. 添加数据: 3.1 数组添加行:
 * 3.2 添加对象:
 */
export default class Data extends Component {
     constructor(page, id, props, context){
    	let config = buildConfig(id, props);
		let configCopy = cloneConfig(config);
        super(page, id, config, context);
		this._configCopy = configCopy;
		this.$isData = true;
		this._sorted = {};
     }

	requireBatchTransition(){
		if(this.page?.state?.loading == false){
			requireBatchTransition();
		}
	}
	 
     //TODO 由于java生成是处理后的config, 所以克隆基于处理后的config
	 //option结构:
     /*
      	{
      		id: "xx", 
      		cloneData: false, 
      		appendCols: {
	      		"code":{
					
					"label":"编码",
					"type":"string",
					"rules": {
						"readonly": fn($row),
						"default": fn(),
						"required": {
							"fn": fn($row),
							"msg": "",
						},
						"constraint": {
							"fn": fn($row),
							"msg": "",
						},
						"calculate": fn($row)
					}
				}
			}
		}	
      */
	 clone(option){
		 if (option.id){
			 let config = cloneConfig(this._configCopy);
			 appendCols(config, option.appendCols); 
			 config.options = config.options || {};
			 config.options.dynamicCreate = true; //标识是动态创建的数据集
			 delete config.options.autoMode; //不需要autoMode, 数据后续加载
			 delete config.options.master; //不感知master
			 delete config.options.isMain; //不感知isMain
			 delete config.initData; //不需要初始数据
			 
			 
			 let clz = option.class || this.getClass();
			 if (typeof option.buildConfig === "function"){
				 option.buildConfig(config);
			 }
			 let ret = new clz(this.page, option.id, config, null);
			 
			 if (option.cloneData){
				 let data = this.toJson({includeComputed: false, includeState: false, excludeCalculateCol: true, resultType: "list"});
				 if (this.isTree()){
					 ret.loadAllTreeData(data);					 
				 }else{
					 ret.loadData(data);
				 }
			 }
			 return ret;
		 }else{
			 throw new Error(this.page.i18n("复制数据集时, id标识不允许为空"));			 
		 }
	 }

		
	 getClass(){
		 return Data;
	 }
	 
     
     constructed(){
    	 this.context.vars[this.id] = this; // 将自己注册到变量中
         this.init();
    	 super.constructed();
     }
     
     isVirtualRow(row){
 		if (row){
 			let treeOption = this.getTreeOption()||{};
 			if (treeOption.virtualCol){
 				return row[treeOption.virtualCol] === "virtual";
 			}
 		}
 	 }
	 isExactTotal(){
		return this.exactTotal.get();
	 }


     getCalcTotal(){
    	 return this.calcTotal.get();
     }
     
     getCalcTotalOB(){
    	 return this.calTotal;
     }
     
     init(){
    	let self = this; 
		this.InitDataPromise = new Promise(function(resolve, reject) {
			self.InitDataResolve = resolve;
			self.InitDataReject = reject;
		});
		//防止Uncaught (in promise)异常
		this.InitDataPromise.catch(()=>{});
 
    	this.delim = ",";
    	this._initDefinition();

    	this.loading = observable(false);
    	
    	this._dataChangedUUID = observable("");
    	
    	this._readonlyOB = observable(false);

    	this.obsOpened = observable(false);
    	var checkMode = this.props.options && this.props.options.checkMode;
    	this.checkRange = this.props.options && this.props.options.checkRange;
    	this.calcTotal = observable(this.props.options.calcTotal);
		this.exactTotal = observable(true);
    	this.enabledCheck = observable(checkMode !== "beforeSave");
    	this.obsCurrentByMaster = observable();
    	this.__value = this._allvalue = this.schema.buildObservable(null);
    	if (this.props.options && this.props.options.dynamicCreate){
    		//动态创建data
    		this._initData();
    	}else{
    		//初始新增和加载数据放到load后保证其他组件已经初始化
            this.page.on('load',()=>{
    	    	 if(!isArray(this.props.options.depends)){
    	    		 this._initData();
    	    	 }else{
    	    		 let comps = [];
    	    		 for(let i=0;i<this.props.options.depends.length;i++){
    	    			 let compId = this.props.options.depends[i];
    	    			 if(this.id!==compId){
    	    				 let comp = this.page.comp(compId);
    	    				 if(comp instanceof Data) comps.push(comp.InitDataPromise);//数据组件特殊处理
    	    				 else comps.push(this.compPromise(compId)); 
    	    			 }
    	    		 }
    	    		 if(comps.length>0){
    	    			 Promise.all(comps).then(()=>{
    	    				 this._initData(); 
    	    			 },(error)=>{
    	    				 throw new Error(_String.format(this.page.i18n("数据集{0}初始化失败, {1}"), self.id, error));
    	    			 });
    	    		 }else this._initData();
    	    	 }
            });
    	}
        
        this.page.on('show',()=>{
        	if(this.opened) this._loadShareData();
        });
        this.page.on('loadShareData',()=>{
        	if(this.opened) this._loadShareData();
        });
        this.page.on('updateShareData',()=>{
        	if(this.opened) this._updateShareData(true);
        });
        this.page.on('unload',()=>{
        	if(this.opened) this._updateShareData(true);
        });
        this.page.on('hide',()=>{
        	if(this.opened) this._updateShareData(true);
        });
        
        this.page.on('refresh',()=>{
        	if(self.open) self.refreshData();
        });
        
        if(this.props.options.allowEmpty!==true){
        	this.page.on('loaded',()=>{
        		this.checkHasData();
        	});
        }
     }
     
     isValueReadied(){
    	 
     }
     
     getAllValue(){
    	 return this._allvalue;
     }
     
     get value(){
    	 this.isValueReadied();
    	 return !isComputed(this.__value)?this.__value:this.__value.get()
     }
     
 	get open(){
 		return this.obsOpened.get();
 	}
 	
 	set open(v){
 		this.obsOpened.set(!!v);
 	}
 	
     _getShareData(){
     	if(this.options.share && this.options.share.name){
     		let ret = ShareData.getShareData(this.options.share.name);
     		if(!ret){
     			var cfg = this.page.getConfig();
     			var shareCfg = cfg && cfg['shareDatas'] && cfg.shareDatas[this.options.share.name];
     			if(shareCfg) ret = new ShareData(shareCfg); 
     		}
     		return ret;
     	}
     }
     
	 getSlaveData(id){
		 //后代类实现
	 }

	 currentByMaster(){
		 return this.obsCurrentByMaster.get();
	 }
	 
     canCalculate($row, depends){
    	 let dependOk = true;
    	 let calculateDepends = isObject(depends) && Object.keys(depends);
    	 if(isArray(calculateDepends) && calculateDepends.length>0){
    		 let page = this.page;
    		 for(let i=0,len=calculateDepends.length;i<len;i++){
    			 var comp = page.comp(calculateDepends[i]);
    			 if(comp instanceof Data){
    				 if(!comp.obsOpened.get()){
    					 dependOk = false;
    					 break;
    				 }else{
    					 let slaveData = this.getSlaveData(calculateDepends[i]);
    					 if($row && slaveData){
    						 let idCol = this.getIdColumn();
    						 let mrid = $row[idCol];
    						 slaveData.currentByMaster();//建立关联
    						 if(!slaveData.byMaster[mrid] || (slaveData.byMaster[mrid] && !slaveData.byMaster[mrid].loaded)){
    							 dependOk = false;
    							 break;
    						 }
    					 }
    				 }
    			 }
    		 }
    	 }
    	 return dependOk;
     }
     
     checkHasData(){
    	 
     }
     
     _loadShareData(){
    	 let shareData = this._getShareData();
    	 if(shareData instanceof ShareData && shareData.hasData(this._shareFlag)){
    		 let sData = shareData.data||[];
    		 this.loadData(sData);
    		 this.updateCurrent(undefined,sData.currentID);
    		 this._shareFlag = shareData.flag;
    		 this.opened = true;
    		 this.obsOpened.set(true);
    		 return true;
    	 }
    	 return false;
     }
     
     _updateShareData(direct){
		 let shareData = this._getShareData();
		 if(shareData instanceof ShareData){
			 if(this._updateShareDataHandle) clearTimeout(this._updateShareDataHandle);
			 let fn = ()=>{
				 this._updateShareDataHandle = null;
				 let shareData = this._getShareData();
				 if(shareData instanceof ShareData){
					 let sData = this.toJSON();
					 sData && (sData.currentID = this.getCurrentRowID());
					 shareData.data = sData;
					 this._shareFlag = shareData.flag;
				 }
			 };
			 if(!direct) this._updateShareDataHandle = setTimeout(fn, 50);
			 else fn();
		 }
     }

     _updateShareDataCurrentIndex(){
		 let shareData = this._getShareData();
		 if(shareData instanceof ShareData){
			 let data = shareData.data;
			 if(data){
				 let cid = this.getCurrentRowID();
				 (cid != data.currentID) && (data.currentID=cid);
			 }
		 }
     }

     _getDefaultStorageKey(){
    	return this.page.owner.route+'@'+this.id; 
     }
     
     saveToStorage(key){
    	key = key || this._getDefaultStorageKey();
    	let sData = this.toJSON();
    	wx.setStorageSync(key, sData);
     }
     
     loadFromStorage(key){
    	 key = key || this._getDefaultStorageKey();
    	 let sData = wx.getStorageSync(key) || [];
    	 return this.loadData(sData);
     }
     
     removeStorage(key){
    	 key = key || this._getDefaultStorageKey();
    	 wx.removeStorageSync(key);
     }
     
     getCurrentRowID(){}//后代类继承实现
     
     getCurrentRow(){}//后代类继承实现
     
     hasDependence(){
  		return true;
  	 }
      
     getCount(){
    	 if (this.value){
	    	 if (typeof this.each == "function"){
		 		let len = 0;
    			//tableData逻辑
	    		//遍历，建议依赖
				this.each((p)=>{
					len++;
				}, null); //默认是this.value
				return len;
	    	 }else{
	    			 //jsonData逻辑
	        		 if (isObservableArray(this.value)){
	        			 let len = 0; 
	        			 for (let i=0; i<this.value.length; i++){
	        				 this.value[i];
	        				 len ++; 
	        			 }
	        			 return len;
	        		 }else{
	        			 return 1;
	        		 }
	    		 }
    	 }else{
    		 return 0;
    	 }
     }
     
     isComputedData(){
    	 return !!this.options.sourceData;
     }
     
     _toJSObject(items){
    	 if (items){
    		 if (typeof items.toJson === "function"){
				 items = items.toJson();
			 }else if (isObservable(items) ){
				 items = toJS(items);
			 }
			 return items;
    	 }else{
    		 return items;
    	 }
     }
     
     to(){
    	 //子类重载
     }
	 
	 existID(id){
		//子类重载
	 }
     
     _initComputedData(){
    	 this.page.compPromise(this.options.sourceData).then((sourceData)=>{
    		 reaction(()=>{
    			 //添加data的数据依赖
    			 let rows = sourceData.toJson({ui: false, resultType: "list"}) || [];
    			 let onComputedRow = null;
    			 if (this.options.onComputedRow){
    				 if (typeof this.options.onComputedRow === "string" && typeof this.page[this.options.onComputedRow] === "function"){
    					 onComputedRow = this.page[this.options.onComputedRow].bind(this.page);
    				 }else if (typeof this.options.onComputedRow === "function"){
    					 onComputedRow = this.options.onComputedRow;
    				 }
    			 }
    			 let newRows = [];
    			 for (let i=0; i<rows.length; i++){
    				 let row = rows[i];
    				 let items = onComputedRow ? onComputedRow({sourceData: sourceData, row: row}) : row;
    				 if (items){
    					 items = this._toJSObject(items);
    					 if (isArray(items)){
    						 for (let j=0; j<items.length; j++){
    							 let item = this._toJSObject(items[j]);
    							 item && newRows.push(item);
    						 }
    					 }else{
    						 newRows.push(items);    
    					 }
    				 }
    			 }
    			 
    			 return newRows;
    		 }, (rows) => {
				 rows = this.buildRowsByMapping(rows);
    			 this.loadData(rows);
				 if (this.options.syncCurrentRow){
					 let id = sourceData.getCurrentRowID();
					 this._doSyncComputedCurrentRow(id, sourceData);
				 }else{
					if (rows.length && typeof this.first === "function"){
						this.first();
					}
				 }
    		 }, {fireImmediately: true, delay: 200});
    		 
        	 if (this.options.syncCurrentRow){
        		 reaction(()=>{
					 return sourceData.getCurrentRowID();
					 
        		 }, (id)=>{
					this._doSyncComputedCurrentRow(id, sourceData);
        		 });
				 reaction(()=>{
        			 return this.getCurrentRowID();
        		 }, (id)=>{
					 let sourceColumn = this.getSourceColumn(this.getIdColumn());
					 if (sourceColumn){
						let row = sourceData.find(sourceColumn, id, true);  
						if (row){
							sourceData.to(row);
						}
					 }
					 
        		 });
        	 }
    	 });    	 
		 this.InitDataResolve && this.InitDataResolve();
		 this.inited();
     }
	 
	 _doSyncComputedCurrentRow(id, sourceData){
		 let computedIdColumn = this.getComputedColumn(sourceData.getIdColumn());
		 if (!computedIdColumn) return;
		 let row = this.find(computedIdColumn, id, true);
		 if (row && row.length>0){
			this.to(row[0]); 
		 }
	 }	 
	 
	 buildRowsByMapping(rows){
		 let newRows = [];
		 if (this.options.mapping && this.options.mapping.length>0){
			 for (let i=0; i<rows.length; i++){
				let row = rows[i];
				let newRow = {};
				for (let name in row){
					if (row.hasOwnProperty(name)){
						let newName = this.getComputedColumn(name);
						if (newName)
							newRow[newName] = row[name];
					}
				}
				newRows.push(newRow);
			 }			 
		 }else{
			newRows = rows;
		 }
		 
		 return newRows;
	 }
	 
	 getSourceData(){
		if (this.isComputedData()){
			return this.page.comp(this.options.sourceData);
		}else{
			return null;
		}
	 }
	 
	 //假设计算数据集和源数据集的主键一致
	 getForeignKeyColumnForSourceData(){
		 let sourceData = this.getSourceData();
		 let sourceIdColumn = sourceData.getIdColumn();
		 return this.getComputedColumn(sourceIdColumn);		 
	 }
	 
	 getComputedColumn(name){
		if (this.options.mapping){
			for (let i=0; i<this.options.mapping.length; i++){
				if (this.options.mapping[i].from === name){
					return this.options.mapping[i].to; 						
				}
			}	 
		}
		 
		 if (this.props.schema.items.props[name]){
			 return name;
		 }
		 
		 return null;	
	 }
	 
	 //默认字段名一致
	 getSourceColumn(name){
		 if (this.options.mapping){
			 for (let i=0; i<this.options.mapping.length; i++){
				if (this.options.mapping[i].to === name){
					return this.options.mapping[i].from; 						
				}
			 }	 
			 
		 }
		 let sourceData = this.getSourceData();
		 if (sourceData.props.schema.items.props[name]){
			 return name;
		 }
		 
		 return null;	
	}
     
     _initData(append){
    	 if (this.isComputedData()){
    		 this._initComputedData();
    		 return;
    	 }
    	 
         if (this.autoMode === "new"){
        	//data初始新增需要先加载共享数据中的 
        	this._loadShareData();			

         	this.newData({onSuccess:()=>{this.InitDataResolve();this.inited();},onError:()=>{this.InitDataReject();}});
         }else if (this.autoMode === "load" || this.autoMode === "loadOrNew"){
        	//加载数据判断如果存在共享数据使用共享数据，否则正常逻辑
        	if(this._loadShareData()){
        		if(this.autoMode === "load"){
        			this.InitDataResolve && this.InitDataResolve();
        			this.inited();
        		}else if(this.getCount()<=0 && this.autoMode === "loadOrNew"){
                 	this.newData({onSuccess:()=>{this.InitDataResolve();this.inited();},onError:()=>{this.InitDataReject();}});
        		}else{
        			this.InitDataResolve && this.InitDataResolve();
        			this.inited();
        		}
        	}else
        		this.refreshData({
        			confirm:false,
        			append:append,
        			parent: this._allvalue,
        			onSucceeded:()=>{
						//解决从表有数据，但判断为无数据的情况
						setTimeout(()=>{
							if(this.getCount()<=0 && this.autoMode === "loadOrNew"){
	                         	this.newData({onSuccess:()=>{this.InitDataResolve();this.inited();},onError:()=>{this.InitDataReject();}});
	                		}else{
	        					this.InitDataResolve && this.InitDataResolve();this.inited();
	                		}
						}, 1);
        				
        			},
        			onError:()=>{
        				this.InitDataReject && this.InitDataReject();
        				this.inited();
        			}
        			});
         }else{
     		 this.InitDataResolve && this.InitDataResolve();
        	 this.inited();
         }
     }
     
     _initTreeDefinition(){
    	 if (this.props.options && this.props.options.treeOption && !this.props.options.treeOption.children){
    		 this.props.options.treeOption.children = "_sys_children_";
    		 this.props.schema.items.props._sys_children_ = {
    			isCal:true,
				define:"EXPRESS",
				label:"子数据",
				type:"recursiveSelf"
    		 }
    	 }
    	 if (this.props.options && this.props.options.treeOption && this.props.options.treeOption.children){
    		 if (!this.props.schema.items.props[this.props.options.treeOption.children]){
    			 throw new Error(_String.format(this.page.i18n("组件{0}的子节点{1}列不存在"), this.id, this.props.options.treeOption.children));
    		 }else{
    			 this.props.schema.items.props[this.props.options.treeOption.children].type = "recursiveSelf";
    		 }
    	 }
     }
     
     _initDefinition(){
    	 this._initTreeDefinition();
    	 this.autoMode = null;
    	 if (this.props.options && this.props.options.autoMode){
    		 this.autoMode = this.props.options.autoMode;
    	 }
    	 this.versionLock = null;
    	 if (this.props.options && this.props.options.versionLock){
    		 this.versionLock = this.props.options.versionLock;
    	 }
    	 this.schema = new Schema(this.id, this.props.schema || {}, this);  
    	 this.initSubDataDefine(this.props.schema);
         this.options = this.props.options;
         this.conformRefresh = (this.options.confirmRefresh === false) ? false : true;
         this.confirmRefreshText = this.options.confirmRefreshText || this.page.i18n("数据已经修改，刷新将丢失修改数据，是否确定刷新数据");
         this.confirmDelete = (this.options.confirmDelete === false) ? false : true;
         this.confirmDeleteText = this.options.confirmDeleteText || this.page.i18n("是否确定删除数据");
         this.directDeleteMode = (this.options.directDelete === false) ? false : true;
     }
     
     initSubDataDefine(schema){
    	 this.subDataDefine = {};
    	 if (schema.items && schema.items.props){
    		 for (let name in schema.items.props){
    			 if (schema.items.props.hasOwnProperty(name) && schema.items.props[name]){
    				 let refData = schema.items.props[name].refData;
    				 if (refData){
    					 this.subDataDefine[name] = refData;
    				 }
    			 }
    		 }
    	 }
     }

	 buildState(context){
    	 var state = super.buildState(context);
    	 state.value = this._toJSON({includeComputed: true, includeState: true}, !isComputed(this.__value)?this.__value:this.__value.get());
    	 state._dataChangedUUID = this._dataChangedUUID.get();
    	 return state;
     }
     
     getValueByPath(path){
    	 var items = parsePath(path, true);
    	 var ret = this;
    	 for (let i=0; i<items.length; i++){
    		 if(!ret) throw new Error(_String.format(this.page.i18n("组件{0}根据路径{1}获取值出错"), this.id, path));
			 if (items[i].indexOf("@")==0){
				 let filter = this._parseArrayFilter(items[i]);
				 ret = this._findItemByFilter(ret, filter);
			 }else{
				 ret = ret[items[i]];
			 }
    	 }
    	 return ret;
     }

	 _findItemByFilter(items, filter){
		 var item = null;
		 for (let i=0; i<items.length; i++){
			 if (items[i][filter.key] == filter.value){
				 return items[i];
			 }
		 }

		 return item;
	 }

	 _parseArrayFilter(filter){
		var index = filter.indexOf("=");
		if (index != -1){
			var key = filter.substr(1, index-1);
			var value = filter.substr(index+1);
			return {key: key, value: value};
		}else{
			throw new Error(_String.format(this.page.i18n("非法的数组过虑条件: {0}"), filter));
		}
	 }
     
     check(force){
    	 var ret = {valid: true, msg: []};
	 let oldEnabledCheck = this.enabledCheck.get();
	 //强制检查时修改enabledCheck的值为true, 后面还原
	 if (force && !oldEnabledCheck){
	 	this.enabledCheck.set(true)
	 }
    	 if (this.checkRange === "currentRow"){
			this._doCheck(this.getCurrentRow(), ret);
		 }else{
			this._doCheck(this._allvalue, ret);
		 }
	if (force && !oldEnabledCheck){
		this.enabledCheck.set(oldEnabledCheck)
	}
    	 
    	 return ret;
     }

     _toJSON(option, rows){
		 option = option || {};
    	 rows = rows || this.value;  //解决从表时导致输出的所有从表记录的问题
		 let result = null;
		 if (option.includeComputed){
			result = toJS(rows, true, null, true);
		 }else{
			result = toJS(rows);
		 }
		 if (result) result = cloneJSON(result);
    	 option.result = [];
    	 this._prepareJsonArray(result, option);
    	 
    	 if (option.resultType === "list" && this.isTree()){
    		 return option.result;
    	 }else{
    		 return result;
    	 }
     }
     
     _prepareJsonArray(items, option){
    	 if (items){
    		 if (items instanceof Array){
    			 for (let i=0; i<items.length; i++){
    				 this._prepareJsonObject(items[i], option);
    			 }
    		 }else{
    			 this._prepareJsonObject(items, option);
    		 }
    	 }
     }
     
     //子类实现
     isCalculateCol(col) {
    	 return false;
     }
     
     _prepareJsonObject(item, option){
    	 if (item && option){
    		if (!option.includeState){
    			delete item._recoredState;
    		}else{
    			if (!item.hasOwnProperty('_recoredState')){
    				item._recoredState = this.getRowState(item[this.getIdColumn()]); 
    			}
    		}
				
    		
    		let childCol = this.isTree() ? this.getTreeOption().children : null;
			if (option.excludeCalculateCol){
				for(var col in item){
					if (item.hasOwnProperty(col)){
						var defCol = this.getColumnDef(col);
						//排除childCol
						if(this.isCalculateCol(defCol) && (childCol != col)){
							delete item[col]	
						}
					}
					
				}
			}
			
			//处理子数据集
			for (let col in this.subDataDefine){
				if (this.subDataDefine.hasOwnProperty(col)){
					let id = item[this.getIdColumn()];
					let subData = this.getSubData(id, col);
					if (subData){
						if (option.includeComputed){
							//界面展现
							item[col] = subData.buildState(subData.context);
						}else{
							item[col] = subData.toJson(option);
						}
						
					}
				}
			}
			
			if (this.isTree()){
				if (option.resultType === "list")
					option.result.push(item);
				
				let treeOption = this.getTreeOption();
	    		let childCol = treeOption.children;
	    		if (childCol){
	    			if (option.excludeChildren){
	    				delete item[childCol];
	    			}else{
	    				let children = item[childCol];
			    		let row = this.getRowByID(item[this.getIdColumn()]); 
			    		if (children && children.length==0 && (this.isLeaf(row) || this.isLoaded(row))){
			    			 delete item[childCol];
			    		}else{
			    			this._prepareJsonArray(children, option);
			    		}
			    		
			    		if (option.resultType === "list")
							delete item[childCol];
	    			}
	    		}
	    		
	    		
			}
    	 }
     }
     
     //子类实现
     isTree(){
    	 return false;
     }
     //子类实现
     getTreeOption(){
    	 return {};
     }
     //子类实现
     setTreeParentIdColumn(parentIdColumn){
    	 
     }
     
     //子类实现
     isLeaf(row){
    	 return false;
     }
     //子类实现
     isLoaded(row){
    	 return false;
     }
     
     
     
     //兼容保留，建议使用toJson
     toJSON(all){
		 //兼容参数是boolean值的情况
		 let option = {};
		 if (typeof all == "object"){
		 	option = all || {};	
		 }else{
			option.includeComputed = all;	
			option.includeState = true;
		 }
		 
		 if (option.hasOwnProperty("ui")){
			 option.includeComputed = option.includeComputed || option.ui;
		 }
		 return this._toJSON(option);
     }
	 
     
     /**
      *  option： 
      *  {
      *  	includeComputed(或ui): true/false,     是否用来界面展现, 默认值: false
      *  	includeState: true/false,		 是否包含行状态, 默认值: false
      *  	excludeCalculateCol: true/false, 是否包含计算列, 默认值: false
      *  	resultType: "list"/"tree",		 结果类型, list或tree, 只有tree时才起作用, tree，默认值是tree
      *  	excludeChildren: true/false		 是否排除子节点, 只有tree时才起作用, 默认值: false	
      *  }
      */
	 toJson(option){
		return this.toJSON(option||{}); 
	 }
     
     _doCheck(value, ret){
		
		 if(!ret.invalidRequired) {
			ret.invalidRequired = [];
		 }
		 if(!ret.invalidConstrait) {
			ret.invalidConstrait = [];
		 }
    	 if (isObservableArray(value)){
    		 for (let i=0; i<value.length; i++){
    			 this._doCheck(value[i], ret);
    		 }
    	 }else if (isObservableObject(value)){
    		 let checkRow = true;
    		 if (this.checkRange === "editRow" && typeof value.getState==="function" && value.getState()!=Data.STATE.EDIT && value.getState()!=Data.STATE.NEW){
    			 checkRow = false;
    		 }
    		 if (checkRow){
	    		 let userdata = value._userdata;
	    		 for (let prop in userdata){
	    			 let required = userdata[prop].required;
	    			 if (required && required.val && this._isEmptyValue(value.$mobx.getRealValue(prop))){
	    				 ret.valid = false;
						 if(!ret.invalidRequired.includes(prop)) {
							ret.invalidRequired.push(prop);
							ret.msg.push(required.msg || ((value.$schema.props[prop] && value.$schema.props[prop].label || prop) + this.page.i18n("不允许为空")));
						 }
	    			 }
	    			 
	    			 let constraint = userdata[prop].constraint;
	    			 if (constraint && !constraint.val){
	    				 ret.valid = false;
						 if(!ret.invalidConstrait.includes(prop)) {
							ret.invalidConstrait.push(prop);
							ret.msg.push(constraint.msg || ((value.$schema.props[prop] && value.$schema.props[prop].label || prop) + this.page.i18n("不符合规则")));
						 }
	    			 }
	    		 }
	    		 
	    		 for (let p in value){
	    			 if (p == "_userdata" || this.isSubDataCol(p)) continue;
	    			 this._doCheck(value[p], ret);
	    		 }
    		 }	 
    	 }
     }
     
     valid(force){
    	 let ret = this.check(force);
    	 return ret.valid;
     }
     
     validInfo(force){
    	 let ret = this.check(force);
    	 let msg = ret.msg;
    	 return msg.length>0?msg.join("; "):"";
     }
     
     getCellStateOB(row, col){
    	 if (row){
    		 row = this._toRow(row);
    		 if (row){
    			 return row.getCellStateOB(col);
    		 }
    	 }
    	 return null;
     }
     
     getCellState(row, col){
    	 if (row){
    		 row = this._toRow(row);
    		 if (row){
    			 return row.getCellState(col);
    		 }
    	 }
    	 return null;
     }
     
     getRowState(row){
    	 if (row){
    		 row = this._toRow(row);
    		 if (row){
    			 return row.getState();
    		 }
    	 }
    	 return null;
     }
     
     getRowStateOB(row){
    	 if (row){
    		 row = this._toRow(row);
    		 if (row){
    			 return row.getStateOB();
    		 }
    	 }
    	 return null;
     }
     
     //兼容保留
     getColValid(row, col){
    	 //val表示是否合法
    	 let ret = {required: {val: false, msg: "", defVal: false}, constraint: {val:false, msg: ""}, readonly: false, hidden: false};
    	 if (row){
    		 row = this._toRow(row);
    		 if (row){
	    		 let userdata = row._userdata;
	    		 if(userdata){
	    			 let uProp = userdata[col];
	    			 if(uProp){
	    				 let required = uProp.required;
	    				 if (required && required.val && this._isEmptyValue(row.$mobx?row.$mobx.getRealValue(col):row[col])){
	       					 ret.required.val = true;
	       					 ret.required.msg = required.msg;
	    				 }
	    				 ret.required.defVal = required && required.defVal;
	    				
	    				 let constraint = uProp.constraint;
	    				 if (constraint && !constraint.val){
	    					 ret.constraint.val = true;
	    					 ret.constraint.msg = constraint.msg;
	    				 }
	    				 
	    				 ret.readonly = uProp.readonly;
	    				 ret.hidden = uProp.hidden;
	    			 }
	    		 }
	    		 
	    		 if (!ret.required.msg){
	    			 ret.required.msg = (((row.$schema && row.$schema.props[col] && row.$schema.props[col].label) || (typeof(this.label)==='function' && this.label(col)) || col) + "不允许为空");
	    		 }
	    		 if (!ret.constraint.msg){
	    			 ret.constraint.msg = (((row.$schema && row.$schema.props[col] && row.$schema.props[col].label) || (typeof(this.label)==='function' && this.label(col)) || col) + "不符合规则");
	    		 }
    		 }	 
    	 }
    	 return ret;
     }

     colValid(row,col){
    	if (!this.enabledCheck.get()) return true;
    	if(row){
    		 row = this._toRow(row);
    		 let userdata = row._userdata;
    		 if(userdata){
    			 let uProp = userdata[col];
    			 if(uProp){
    				 let required = uProp.required;
    				 if (required && required.val && this._isEmptyValue(row.$mobx?row.$mobx.getRealValue(col):row[col])){
       					 return false;
    				 }
    				 
    				 let constraint = uProp.constraint;
    				 if (constraint && !constraint.val){
       					 return false;
    				 }
    			 }
    		 }
    	}
		return true; 
     }
     
     _toRow(row){
    	 let ret = null;
    	 untracked(()=>{
    		 if (row && (typeof row == 'string' || typeof row == 'number')){
    			 if (typeof(this._findRowByID)==='function' && this.existID(row)){
    				 ret = this._findRowByID(row);
    			 }else{
    				 ret = null;
    			 }
        	 }else{
        		 if (row && row.$$isProxy){
    				 ret = this._findRowByID(row[this.getIdColumn()]);
    			 }else{
    				 ret = row;
    			 }
        	 }
    	 });
    	 return ret;
     }
     
     colValidInfo(row,col){
    	if (!this.enabledCheck.get()) return "";
    	let msg = [];
     	if(row){
	       row = this._toRow(row);
   		   let userdata = row._userdata;
   		   if(userdata){
   			 let uProp = userdata[col];
   			 if(uProp){
   				 let required = uProp.required;
   				 if (required && required.val && this._isEmptyValue(row.$mobx?row.$mobx.getRealValue(col):row[col])){
					 msg.push(required.msg || (((row.$schema && row.$schema.props[col] && row.$schema.props[col].label) || (typeof(this.label)==='function' && this.label(col)) || col) + "不允许为空"));
   				 }
   				 
   				 let constraint = uProp.constraint;
   				 if (constraint && !constraint.val){
    				 msg.push(constraint.msg || (((row.$schema && row.$schema.props[col] && row.$schema.props[col].label) || (typeof(this.label)==='function' && this.label(col)) || col) + "不符合规则"));
   				 }
   			 }
   		  }
     	}
		return msg.length>0?msg.join("; "):""; 
     }
     
     _isEmptyValue(value){
    	//空格也认为没有值 
    	 return value===undefined || value===null || value==="" || ""===_String.trim(value+"");
     }
     
     destroy(){
    	 //this.clear(); 释放时不能清除数据，会导致vue界面组件重绘，重绘中又找data组件时为null
    	 this._destroySubData();
    	 this.schema.destroy();
    	 super.destroy();
     }
     
     // ---- array start---------
     getArrayCount(array){
    	 return array.length;
     }
     
     getArrayOrderBys(array) {
    	 var result = '';
    	 var items = this._getArrayOrderBys(array);
    	 for ( var i=0;i<items.length;i++) {
    		 var o = items[i];
    		 result += (result !== '' ? ',' : '') + o.name + (0 === o.type ? ' DESC' : ' ASC');
    	 }
    	 return result;
     }
     
     _getArrayOrderBys(array){
    	 //array.getUserData().orderBy = array.getUserData().orderBy || []; 
    	 return array.getUserData().orderBy;
     }
     
     getArrayOrderBysOB(array){
    	 return array.getUserData().orderBy;
     }
     
   
     isCalulateProp(ob, name){
    	 var schema = null;
    	 if (isObservableArray(ob)){
    		 schema = ob.$schema.items;
    	 }else if (isObserableObject(ob)){
    		 schema = ob.$schema;
    	 }
    	 return schema && schema.props && schema.props[name] 
    	 	&& schema && schema.props && schema.props[name].isCal; 
     }
     
     // 如果type为undefined/null时, 表示删除orderby
     setArrayOrderBy(array, name, type) {
     	if(this.isCalulateProp(array, name)) return;// 计算属性不支持orderby
     	var items = this._getArrayOrderBys(array);
		var o = this.getArrayOrderBy(array, name);
		if (null !== type && undefined !== type){
			if(o) o.type = type;
			else items.push({name:name,type:type});
		}else{
			if(o){
				var i = items.indexOf(o);
				if(i>=0) items.splice(i,1);
			}
		}
     }
     
	 getArrayOrderBy(array, name) {
		 var items = this._getArrayOrderBys(array);
		 for (let i=0; i<items.length; i++){
			 if (items[i].name === name){
				 return items[i];
			 }
		 }
		 return null;
	 }
	 
     setArrayLimit(array, limit){
    	 array.getUserData().limit.set(limit);	 
     }
     
     getArrayLimit(array){
    	 this._updateLimitByTreeOption(array);
		 return array.getUserData().limit.get();
     }
     
     _updateLimitByTreeOption(array){
    	 let treeOption = this.getTreeOption() || {};
    	 if (treeOption.isTree && treeOption.loadAll){
    		 array.getUserData().limit.set(-1);
    	 }
     }
     
     getArrayLimitOB(array){
    	 this._updateLimitByTreeOption(array);
    	 return array.getUserData().limit;
     }
     
     setArrayOffset(array, offset){
    	 array.getUserData().offset.set(offset);
     }
     
     getArrayOffset(array){
   		 return array.getUserData().offset.get();
     }
     
     getArrayOffsetOB(array){
   		 return array.getUserData().offset;
     }
     
     hasArrayMore(array){
   		 if (array.getUserData().limit.get() == -1) return false;
   		 return this.getArrayTotal(array) > this.getArrayOffset(array);
     }
     
     getArrayTotal(array){
   		 return array.getUserData().total.get();
     }
     
     setArrayTotal(array, total){
   		 array.getUserData().total.set(total); 
     }
     
     getArrayTotalOB(array){
   		 return array.getUserData().total;
     }

     isArrayLoaded(array){
   		 return array.getUserData().loaded.get();
     }
     
     setArrayLoaded(array, loaded){
   		 array.getUserData().loaded.set(loaded); 
     }
     // ------array end---------

     // ---------------CRUD start---------------------
     refreshData(options){
		 if (this.isComputedData()){
			 console.error("计算数据集不支持刷新");
			 return Promise.resolve();
		 }
    	 options = options || {};
    	 options.append = (options.append===true);
    	 //options.parent = options.parent || this._allvalue;
    	 //if (isObservableArray(options.parent)){
    	//	 options.offset = options.parent.$schema.offset || 0;
    	 //}
    	 //如果options指定了offset,使用options中的
    	 if (!options.hasOwnProperty("offset")){
    		 options.offset = 0;
    	 }
    	 return this._refreshData(options);
     }

     _refreshData(options){
		 if (this.isComputedData()){
			 console.error("计算数据集不支持刷新");
			 return Promise.resolve();
		 }

		 this.loading.set(true);

    	 let result = false, async = false, promise = null, cancelEvent=false;
    	 options = options || {};
    	 var retPromise = this._createPromise(options);
    	 this.__lastRefreshPromise = retPromise;
    	 
		 
		 options.parent = options.parent || this._allvalue;
    	 var eventData = {
    		 cancel : false,
    		 options : options,
    		 source : this
    	 };
		 this.fireEvent(Data.EVENT_REFRESHDATA_BEFORE, eventData);
		 if (eventData.cancel) {
			 this.loading.set(false);
			 retPromise.rej();
			 return retPromise;
		 }
		 
		 var self = this;
		 var func = () => {
		 	 if (isObservableArray(options.parent)){
		 		 //刷新前不设置offset,limit, 否则会导致分页组件切换到前一页时，页号会先跳到前前一页，再切换到前一页
		 		 //offset,limit由options带着走，刷新成功后再设置数据集上的offset和limit
				 /*
				 if (options && 'number' == typeof (options.offset)) 
					 self.setArrayOffset(options.parent, options.offset);
				 if (options && 'number' == typeof (options.limit))
					 self.setArrayLimit(options.parent, options.limit);
				 */
				 if (!options.hasOwnProperty("offset")) options.offset = self.getArrayOffset(options.parent);
				 if (!options.hasOwnProperty("limit")) options.limit = self.getArrayLimit(options.parent);
			 }
		 	 if (self.hasListener(Data.EVENT_REFRESHDATA)) {
				eventData = {
					cancel : false,
					options : options,
					source : self,
					promiseFromReturn: true
				};
				if (isObservableArray(options.parent)){
					eventData.limit = options.limit;
					eventData.offset = options.offset;
				}
				self.fireEvent(Data.EVENT_REFRESHDATA, eventData);
				result = !eventData.cancel;
				async = eventData.async;
				promise = eventData.promise;
			 }else{
				var ret = this.doRefreshData(options);
				result = ret.success;
				async = ret.async;
				promise = ret.promise;
				cancelEvent = ret.cancelEvent;
			 }
			 
			 if (async || (promise && promise.then)){
				 if (promise && promise.then){
					 promise.then(function(){
					 	 self.loading.set(false);
					 	 if (cancelEvent){
					 		retPromise.res();
					 	 }else{
					 		self.doRefreshAfter(true, options);	
					 	 }
					 },function(){
						 self.loading.set(false);
					 	 if (cancelEvent){
					 		retPromise.rej();
					 	 }else{
					 		self.doRefreshAfter(false, options);
					 	 }
					 });
				 }else{
					 self.loading.set(false);
					 retPromise.res();
				 }
			 }else{
				 self.loading.set(false);
				 if (cancelEvent){
					 result ? retPromise.res() : retPromise.rej();
			 	 }else{
			 		self.doRefreshAfter(result, options);	
			 	 }
			 }
		 }
		 
		 var confirmRefresh = options.hasOwnProperty("confirm") ? options.confirm : this.confirmRefresh;
		 if (options.append){
			 func();
		 }else if (confirmRefresh && this.isChanged(options.parent)){
    		 var confirmRefreshText = this.confirmRefreshText;
    		 this._confirm(confirmRefreshText, func, null);
		 }else{
			 func();	 
		 }
		 return retPromise;
     }
     
     _confirm(msg, onOk, onCancel){
    	 wx.showModal({
    		 title: this.page.i18n('提示'),
    		 showCancel: true,
			 content: msg,
			 success: function(res) {
				 if (res.confirm){
					 onOk && onOk();
				 }else{
					 onCancel && onCancel();
				 }
			 }
    	 });
     }
     
     // TODO 只支持整个data更新
     doRefreshData(options) {
    	 this.open = true;
    	 if (options.parent === this._allvalue && this.props.initData){
             var initData = JSON.parse(JSON.stringify(this.props.initData));// 必须clone,
																// 兼容list中嵌套page的情况,
																// 否则会导致data中的行对象是同一个
             if (this.isTree()){
            	 this.loadAllTreeData(initData);
             }else{
            	 this.loadData(initData, false, options.parent);
             }
     	 }
    	 return {
 			success : true,
 			async : false
 		 };
     }

     loadAllTreeData(data, useVTree){
    	 //子类实现
     }

     updateCurrent(parent, item){} // 子类重载
     
     _clsSlaveDatasState(){
    	 var slaveDatas = this.slaveDatas;
    	 if(slaveDatas){
    		 for (let i=0; i<slaveDatas.length; i++){
    			 let slaveData = slaveDatas[i];
			 slaveData.byMaster = {};
    			 slaveData.clear();
    			 slaveData._clsSlaveDatasState();
    		 }
    	 }
     }
     
     doRefreshAfter(success, options, params){
    	 options = options || {};
    	 params = params || {};
    	 params.source = params.source || this;
		 if (success){
	    	 this.opened = true;
			 this.obsOpened.set(true);
			 //如果不是增量刷新清除从数据的加载状态
			 if(!options.append) this._clsSlaveDatasState();
			 var eventData = {
					 options : options,
					 source : this,
					 success : success,
					 changedSource : this,
					 rows: params.rows,
					 type : 'refresh',
					 selfChanged : true,					 
			 };			 
			 if (isObservableArray(options.parent || this._allvalue)){
			 this.setArrayLoaded(options.parent || this._allvalue, true);
			 }
			 if (isObservableArray(options.parent)){
				 //没有当前行时才设置当前行
				 if (!this.getCurrentRowID()){
					 this.updateCurrent(options.parent);
				 }
				 this.setArrayLimit(options.parent, options.limit);
				 if (options.limit!=-1){
					 this.setArrayOffset(options.parent, options.offset + options.limit);
				 }
				 eventData.limit = options.limit;
				 eventData.offset = this.getArrayOffset(options.parent);
				 
			 }
				 
			 this.doDataChanged(eventData);
			 
			 if (options.onSuccess && (typeof options.onSuccess === 'function')){
				 options.onSuccess(params);
			 }
			 
			 this.fireEvent(Data.EVENT_REFRESHDATA_AFTER, eventData);
			 
			 //内部使用
			 if (options.onSucceeded && (typeof options.onSucceeded === 'function')){
				 options.onSucceeded(params);
			 }
			 //以下代码不需要，因为offset已经是可观察对象了
			 //解决uix界面组件取到的offset不是最新的问题
	    	 //this._dataChangedUUID.set(new UUID().toString());
		 }else{
			 if (options.onError && (typeof options.onError === 'function')) {
				 options.onError(params);
			 }
			 this.fireEvent(Data.EVENT_REFRESHDATA_ERROR, params);
		 }
     }
     
     doDataChanged(eventData) {
    	 let type = eventData.type;
    	 if(eventData.selfChanged && (['new','clear','delete','refresh','loadData','valueChanged'].indexOf(type)>=0)){
    		 //数据变化时进行共享数据同步
    		 this._updateShareData();
    	 }
    	 if (this.hasListener(Data.EVENT_DATA_CHANGE)) {
    		 var eData = Object.assign({}, eventData);
			 this.fireEvent(Data.EVENT_DATA_CHANGE, eData);
		 }
    	 
    	 if ((['new','clear','delete','valueChanged'].indexOf(type)>=0) && this.$ownerData){
    		 let source = this.$ownerData;
    		 while (source){
    			 let evtData = {source: source, type: "subDataChanged"}
        		 source.fireEvent(Data.EVENT_SUBDATA_CHANGED, evtData);
    			 source = source.$ownerData;
    		 }
    	 }
     }
     
     getIdColumn(){
    	 return "";//后代类实现
     }
     
     setIdColumn(name){
    	//后代类实现
     }     
     
  	_rows2indexMapping(rows){
		let ret = {};
		let idColumn = this.getIdColumn();
		if(rows && isObservableArray(rows)){
			for(var i=0;i<rows.length;i++){
				let row = rows[i];
				var id = row[idColumn];
				ret[id] = i;
			}
		}
		return ret;
 	}

  	smartLoad(keyName,orgList,newData){
  	    let orgIndexMap = {};
  	    let list = orgList;
  	    let newDatalist = newData;
  	    let kName = keyName;
  	    let ret = [];
  	    transaction(() => {
  	    	for(let i=list.length-1;i>-1;i--){
  	    		let item = list[i];
  	    		let k = item[kName];
  	    		orgIndexMap[k] = item;
  	    	}
  	    	
  	    	for(let i=0,len=newDatalist.length;i<len;i++){
  	    		let item = newDatalist[i];
  	    		let k = item[kName];
  	    		let orgItem = list.length>i?list[i]:null;
                if(!orgItem || orgItem[kName]!==k){//判断原位置是不是同一条数据，如果不是从索引中获取
                    orgItem = orgIndexMap[k];
                    if(orgItem){
                    	list.splice(i,1,orgItem);
                    	orgItem = list[i];
                    }
                }    
                if(orgItem){
                    for (let [key, value] of Object.entries(item)) {
                    	let v = orgItem[key];
                    	if(v===value){
                    		//为了刺激计算规则
                    		orgItem[key] = null;
                    	}
                    	orgItem[key] = value; 
                    }  
                    this.resetState(orgItem, true);
                }else{
                    orgItem = item;
                    list.splice(i,1,orgItem);
                } 
  	    		ret.push(orgItem);
  	    	}
  	    	if(list.length>newDatalist.length){//清除多余的行
  	    		list.splice(newDatalist.length,list.length-newDatalist.length);
  	    	}
  	    });
  	    return ret;
  	}
  	
  	canSmartLoad(){
		var eventData = {
				'smartLoad': false,
				'source' : this
			};
		this.fireEvent(Data.EVENT_SMART_LOAD_BEFORE, eventData);
  		
  		return eventData.smartLoad;
  	}
  	
  	collectSubData(idColumn, data){
  		let ret = [];
  		for (let i=0; i<data.length; i++){
  			if (!data[i]) continue;
  			
  			let id = data[i][idColumn]
  			for (let col in this.subDataDefine){
  				if (this.subDataDefine.hasOwnProperty(col)){
					let val = data[i][col]||[];
					if (val && typeof val == "string"){
						try{
							val = JSON.parse(val);
						}catch(e){}	
					}
  					ret.push({id: id, col: col, value: val, refData: this.subDataDefine[col]});
  				}
  			}
  		}
  		return ret;
  	}
  	
  	loadSubData(subData){
  		this.$subDataComps = this.$subDataComps || {};
  		let idColumn = this.getIdColumn();
  		for (let i=0; i<subData.length; i++){
			let item = subData[i];
			let refDataComp = item.refData ? this.page.comp(item.refData) : null;
			if (refDataComp){
				let dataId = this.getSubDataId(item.id, item.col);
				let dataComp = this.page.comp(dataId) || refDataComp.clone({id: dataId});
				dataComp.$ownerData = this;
				this.$subDataComps[dataId] = true;
				if (item.value){
					let option = {};
					if (dataComp.isTree()){
						//树形数据默认构建树
						option.buildTreeData = true;
					}
					dataComp.loadData(item.value, option);
					if (item.value.length>0){
						dataComp.first();
					}
				}
			}
  		}
  	}
  	
    loadData2Array(data, userdata, append, parent, index, override){
		 let ret = [];
		 let idColumn = this.getIdColumn();
		 this.updatePaginationVar(userdata, parent);
		 if (!append){
			 //hcr 不使用智能加载, 智能加载会导致数据变化多次，有性能问题
			 if(false && this.canSmartLoad()){//智能加载
				 return this.smartLoad(idColumn,parent,data);
			 }else this._clear(parent);
		 }
		 
		 let subData = this.collectSubData(idColumn, data);
		 
		 let indexMapping = (override && idColumn)?this._rows2indexMapping(parent):{};
		 index = (index==undefined || index==null) ? -1: index;
		 for (let i=0; i<data.length; i++){
			 let row = data[i];
			 let rid = row[idColumn];
		     //删除平台默认属性
			 if (row && row.hasOwnProperty("_recoredState")){
				delete row._recoredState;
			 }
			 
			 if(!indexMapping.hasOwnProperty(rid)){
				 let newIndex;
				 if ((index == -1) || index >= parent.length){
					 parent[parent.length] = row;
					 ret[ret.length] = parent[parent.length-1];
					 newIndex = parent.length-1;
				 }else{
					 parent.splice(index+i, 0, data[i]);
					 ret[ret.length] = parent[index+i];
					 newIndex = index+i;
				 }
				 if(override && rid!==undefined) indexMapping[rid] = newIndex;
			 }else{
				 let orgRowIndex = indexMapping[rid];
				 parent[orgRowIndex] = row; 
				 ret[ret.length] = parent[orgRowIndex];
			 }
		 }
		 
		 this.loadSubData(subData);
		 return ret;
    }
     
     loadData(data, append, parent, index, override){
    	 if (arguments.length == 2 && wx.Util.isObject(arguments[1])){
    		 if (!arguments[1].hasOwnProperty("fireEvt")){
    			 arguments[1].fireEvt = true;
    		 }
    		 //新的api, 选项通过options传递
    		 return this.doLoadData(arguments[0], arguments[1]);
    	 }else{
    		 //兼容老的api
    		 return this._loadData(data, append, parent, index, override, true); 
    	 }
	 }
	 
	 loadData1(data, append, index, override){
    	return this._loadData(data, append, null, index, override, true); 
     }
	 
	 _loadData(data, append, parent, index, override, fireEvt){
		 return this.doLoadData(data, {append: append, parent: parent, index: index, override: override, fireEvt: fireEvt});
	 }
	 
	 loadTreeData(data, options){
		 //子类实现
	 }
	 
	 beforeLoadData(data, options){
		 var eventData = {
			 source: this,
			 data: data
		 };
		 this.fireEvent(Data.EVENT_LOAD_BEFORE, eventData);
	 }
	 
	 afterLoadData(data, options){
		 this.checkIdColumn();
	 }
	 
	 //error, console, hint
	 setCheckIdMode(mode){
		 this._checkIdMode = mode;
	 }
	 
	 showCheckIdError(msg){
		 if (this._checkIdMode == "error"){
			 throw new Error(msg);
		 }else if (this._checkIdMode == "hint"){
			 this.page.hint(msg);
		 }else{
			 console.error(msg);
		 }
	 }
		 
	 checkIdColumn(){
		 if (typeof this.each == "function"){
			 let idColumn = this.getIdColumn();
			 if (!idColumn) {
				 this.showCheckIdError(_String.format(this.page.i18n("数据集{0}未定义主键"), this.id));
				 return;
			 }
			 let ids = [];
			 this.each((p)=>{
				 let id = p.row[idColumn];
				 if (!id){
					 this.showCheckIdError(_String.format(this.page.i18n("数据集{0}加载的数据的主键不允许为空"), this.id));
				 }else{
					 if (ids.indexOf(id) != -1){
						 this.showCheckIdError(_String.format(this.page.i18n("数据集{0}加载的数据的主键{1}重复"), this.id, id));
					 }else{
						 ids.push(id);
					 }
				 }
			 });
		 }
	 }
	 
	 doSort(){
		 if (typeof this.each == "function"){
			 let idColumn = this.getIdColumn();
			 this._sorted = {};
			 this.each((p)=>{
				 let row = p.row;
				 let id = null;
				 if (idColumn && row[idColumn]!==undefined && row[idColumn]!==null){
					 id = row[idColumn];
				 }else{
					 id = new UUID().toString();
				 }
				 this._sorted[id] = p.row;
			 });
		 }
	 }
	
	 doLoadData(data, options){
		 let ret = null;
		 try{
			 this._sorting = true;
			 options = options || {};
			 if (this.isTree()){
				 if (options.parent){
					 if (!isObservableArray(options.parent)){
						 options.parentRow = options.parent; //parent是行对象
						 delete options.parent;
					 }
				 }
				 ret = this.loadTreeData(data, options);
				 if(options.fireEvt){
		    		 var loadEventData = {
		    				 source : this,
		    				 changedSource : this,
		    				 type : 'loadData',
		    				 selfChanged : true,
		    		 };
		    		 this.doDataChanged(loadEventData);
		    	 }
			 }else{
				 ret = this.loadTableData(data, options);
			 }
		 }finally{
			 this.doSort();
			 this._sorting = false;
		 }
		 //加载完数据行默认更新当前行
		 this.updateCurrent();
		 
		 //因为ui层需要在数据加载后会触界面变化，此时需要loading的状态为false
		 if (this.loading.get()){
			 this.loading.set(false);
		 }
		 return ret;
	 }

	 _clearObject(obValue){
		 transaction(() => {
			 let schema = obValue.$schema;
			 if (schema && schema.type == "object"){
				 let props = schema.props || {};
				 for (let key in props){
					 if (props.hasOwnProperty(key)){
						 obValue[key] = null;
					 }
				 }
			 }
		 });
	 }
     
	 _updateObject(obValue, data){
		 transaction(() => {
			 this._clearObject(obValue);
			 if (data){
				 for (let key in data){
					 if (data.hasOwnProperty(key)){
						 obValue[key] = data[key];
					 }
				 }
			 }
		 });
	 }

     
	 //加载二维表
     loadTableData(data, options){
    	 options = options || {};
    	 let {append, parent, index, override, fireEvt} = options;
    	 if (!data)  return;
    	 var ret = null;
    	 this.beforeLoadData(data, options);
    	 transaction(() => {//加载数据放在一个事务
        		 parent = parent || this._allvalue;
        		 var userdata = {};
        		 if (data.userdata){
        			 userdata = data.userdata;
        			 data = data.value;
        		 }
        		 if (isObservableObject(parent)){
        			 if (parent === this._allvalue){
        				 this._updateObject(this._allvalue, data);
        				 ret = this._allvalue;
        			 }else{
        				 throw new Error(this.page.i18n("数据集加载对象类型时, 只允许加载整个数据"));
        			 }
        		 }else if (isObservableArray(parent)){
        			 ret = this.loadData2Array(data, userdata, append, parent, index, override);
        		 }
    	 });
    	 this.afterLoadData(data, options);
    	 if(fireEvt){
    		 var loadEventData = {
    				 source : this,
    				 changedSource : this,
    				 type : 'loadData',
    				 selfChanged : true,
    		 };
    		 this.doDataChanged(loadEventData);
    	 }
    	 return ret;
     }     
     
     updatePaginationVar(userdata, parent) {
    	 if (userdata.hasOwnProperty('sys.count')){
    		 this.setArrayTotal(parent, userdata['sys.count']);
    	 }
    	 if (userdata.hasOwnProperty('sys.offset')){
    		 this.setArrayOffset(userdata['sys.offset'], parent);
    	 }
     }     
          
     
     // 子类重载, 更新当前行
     _clear(array){
    	 this._sorted = {};
    	 if (isObservableArray(array)){
    		 array.clear();	 
    	 }else{
    		 this._clearObject(array);
    	 }
    	 this._destroySubData();
     }
     
     _destroySubData(){
    	 for (let id in this.$subDataComps){
    		 if (!this.$subDataComps.hasOwnProperty(id)) continue;
    		 try{
    			 let comp = this.page.comp(id);
    			 if (comp){
    				 comp.destroy();
    			 }
    		 }catch(e){
    			 console.error(e);
    		 }
    	 }
    	 this.$subDataComps = {};
     }
     
     clear(){
    	 this._clear(this._allvalue);
    	 var eventData = {
    		 source: this,
    		 changedSource: this,
    		 type: 'clear',
    		 selfChanged: true
    	 };
		 this.doDataChanged(eventData);
     }
     
     async doBuildDefaultValue(data, defaultValues){
    	 if (!data) return; 
    	 if (isArray(data)){
    		 for (let i=0; i<data.length; i++){
    			 data[i].setState(Data.STATE.NEW);
    			 let defaultValue = await data[i].$initDefaultValue();
    			 defaultValues.push({row: data[i], defaultValue: defaultValue});
    			 if (this.isTree() && data[i][this.getTreeOption().children]){
        			 await this.doBuildDefaultValue(data[i][this.getTreeOption().children], defaultValues);
        		 }	
			 }
    	 }else{
    		 data.setState(Data.STATE.NEW);
    		 let defaultValue = await data.$initDefaultValue();
    		 defaultValues.push({row: data, defaultValue: defaultValue});
    		 
    		 if (this.isTree() && data[this.getTreeOption().children]){
    			 await this.doBuildDefaultValue(data[this.getTreeOption().children], defaultValues);
    		 }
    	 }
     }

     /**
		 * 创建新的数据, 可以同时创建多行数据 兼容支持原来的3个参数写法index, parent, rows
		 * 
		 * @method newData
		 * @param [options]
		 *            可以设置默认值, 父, 如果创建多条数据可以设置默认值为默认值数组 参数结构 {defaultValues:
		 *            [{column1: (value), column2: (value), ...},{...},...],
		 *            parent: ({Data.Row}), onSuccess: ({Function}), onError:
		 *            ({Function})}
		 * @returns {Array(String)} rows
		 */
     async newData(options){
     	 options = options || {};
    	 var retPromise = this._createPromise(options);
		 //100ms内执行两次new操作报警告
		 if (this._lastNewDataTime && (new Date().getTime()-this._lastNewDataTime<100)){
			 console.warn("数据集" + this.id + "在100ms内连续执行newData性能不优化, 建议准备好数据后执行批量newData, " + (new Date().getTime()-this._lastNewDataTime));
		 }
		 this._lastNewDataTime = new Date().getTime();
 		 if (this.isComputedData()){
			 console.error("计算数据集不支持新建");
			 return Promise.resolve();
		 }
		 
    	options.parent = options.parent || this._allvalue;
    	options.index = options.hasOwnProperty('index') ? ('string'===typeof(options.index)?parseInt(options.index):options.index) : -1;

		var eventData = {
    		'cancel' : false,
			'options': options,
			'source' : this
		};
		this.fireEvent(Data.EVENT_NEWDATA_BEFORE, eventData);
		if (eventData.cancel) {
			retPromise.rej();
			return retPromise;
		}
		
		var data = null;
		if (this.hasListener(Data.EVENT_NEWDATA)) {
			eventData = {
				cancel: false,
				data : data,
				options: options,
				source : this
			};
			this.fireEvent(Data.EVENT_NEWDATA, eventData);
			if (eventData.cancel) {
				retPromise.rej();
				return retPromise;
			}
			data = eventData.data;
		}else{
			data = this.doNewData(options.defaultValues, options);
		}
		if (!data) {
			retPromise.rej();
			return retPromise;
		}
		
		data = this.loadData(data, true, options.parent, options.index);
		
		if (isObservableArray(options.parent) && (data.length > 0)){
			this.updateCurrent(options.parent, data[0]);
		}
		
		if (data && !((options||{}).disableRecordChange)){
			let defaultValues = [];
			await this.doBuildDefaultValue(data, defaultValues);
			this.requireBatchTransition();
			//解决默认值异步导致mobx多次traction的问题
			transaction(()=>{
				for (let i=0; i<defaultValues.length; i++){
					let row = defaultValues[i].row;
					let defaultValue = defaultValues[i].defaultValue;
					for (let key in defaultValue){
						if (defaultValue.hasOwnProperty(key)){
							row[key] = defaultValue[key];
						}
					}
				}
			});
		}
		
		
		//记录打开状态
		this.opened = true;
		this.obsOpened.set(true);
		if (isObservableArray(options.parent) && (data.length > 0)){
		this.setArrayLoaded(options.parent || this._allvalue, true);
		}
		
		eventData = {
			'data' : data,
			'options': options,
			'source' : this
		};
		this.fireEvent(Data.EVENT_NEWDATA_AFTER, eventData);
		eventData.changedSource = this;
		eventData.type = 'new';
		eventData.selfChanged = true;
		this.doDataChanged(eventData);
		

		if (options.onSuccess && (typeof options.onSuccess === 'function'))
			options.onSuccess({
				'source' : this,
				'options': options,
				'data' : data
			});

		return Promise.resolve(data);
     }
     
     // 子类重载
     doNewAfter(){}
     

     doNewData(defaultValues, options){
	 	 var ret = defaultValues;
		 if (isObservableArray(options.parent)){
			 ret = ret || [{}];
			 if (!isArray(ret)) ret = [ret];
		 }else{
			 ret = ret || {};
		 }
		 return ret;
     }
     
     _createPromise(options){
    	 var res = null;
    	 var rej = null;
    	 var retPromise = new Promise((resolve, reject) => {
    		 let oldOnSuccess = options.onSuccess;
    		 let oldOnError = options.onError;
    		 options.onSuccess = function(p){
    			 oldOnSuccess && oldOnSuccess(p);
    			 resolve();
    		 };
    		 options.onError = function(p){
    			 oldOnError && oldOnError(p);
    			 reject();
    		 };
    		 
    		 rej = reject;
    		 res = resolve;
    	 });
    	 //catch防止 Uncaught (in promise)异常
    	 retPromise.catch(()=>{});
		 retPromise.rej = rej;
		 retPromise.res = res;
    	 return retPromise;
     }
     
     
     _openCheck(){
    	 if (!this.enabledCheck.get()){
    		 this.enabledCheck.set(true);
    	 }
    	 if (this.slaveDatas){
    		 for (let i=0; i<this.slaveDatas.length; i++){
        		 let slaveData = this.slaveDatas[i];
        		 slaveData && slaveData._openCheck();
        	 }
    	 }
     }
     
     /**
		 * 业务数据保存方法
		 * 
		 * @param [options]
		 *            可以设置成功失败的回调 参数结构 {ignoreInvalid: true, onSuccess:
		 *            ({Function}), onError: ({Function})}
		 * @return {boolean}
		 */
     saveData(options){
    	 this._openCheck();
    	 
    	 //判断值变化前先检查数据合法性
    	 options = options || {};
    	 var retPromise = this._createPromise(options);
    	 
    	 var onSuccess = options.onSuccess, onError = options.onError, ignoreInvalid = !! options.ignoreInvalid;
    	 let result = false;
    	 if (!ignoreInvalid){
    		 var info = this.check();
    		 if (!info.valid){
    			 wx.showModal({
    				    showCancel:false,
    				    title: this.page.i18n('提示'),
    				    content: info.msg.join("; ")
    				  });
    			 retPromise.rej();
    			 return retPromise;
    			 //throw new Error(info.msg.join("; "));
    		 }
    	 }
    	 
    	 if (!this.isChanged(options)){
    		 return Promise.resolve();
    	 }
    	 
    	 
    	 let useBatch = !!options.batch;
    	 let selfBatch = false;
    	 let batch;
    	 if(this.hasListener(Data.EVENT_SAVEDATA_BATCH_READY)){
    		 let eventData = {
    				 'options' : options,
    				 'useBatch': useBatch,
    				 'source' : this
    		 };
    		 this.fireEvent(Data.EVENT_SAVEDATA_BATCH_READY, eventData);
    		 options = eventData.options;
    		 if(!useBatch && eventData.useBatch){
    			 options.batch = new BatchRequest({page: this.page});
    			 selfBatch = true;
    			 useBatch = true;
    		 }
    	 }    	 
    	 
    	 
    	 result = false;
    	 let async = false, promise = null;
    	 
    	
    	 
    	 try{
    		 var eventData = {
    			cancel : false,
				source : this,
				options : options,
				batch: options.batch,
				promises: []
    		 };
			 this.fireEvent(Data.EVENT_SAVEDATA_BEFORE, eventData);
			 if (eventData.cancel) {
				 retPromise.rej();
    			 return retPromise;
			 }

			 let callback = ()=>{
				 if (this.hasListener(Data.EVENT_SAVEDATA)) {
					 eventData = {
							 cancel: false,
							 source: this,
							 options: options,
							 batch: options.batch
					 };
					 this.fireEvent(Data.EVENT_SAVEDATA, eventData);
					 async = eventData.async;
					 result = !eventData.cancel;
					 promise = eventData.promise;
				 }else{
					 var ret = this.doSaveData(options);
					 async = ret.async;
					 result = ret.success;
					 promise = ret.promise;
				 }
				 
				 if (!result) {
					 retPromise.rej();
	    			 return retPromise;
				 }
				 
				 
				 eventData = {
						 options: options,
						 source : this,
						 batch: options.batch
				 };
				 this.fireEvent(Data.EVENT_SAVEDATA_AFTER, eventData);
				 

				 if (async || (promise && promise.then)){
					 if(promise && promise.then){
						 var self = this;
						 promise.then(function(){
							 self.doSaveAfter(true, options);
						 },function(){
							 self.doSaveAfter(false, options);
						 });
					 }
				 }else{
					 this.doSaveAfter(result, options);
				 }

				 
				 //进行批提交
				 if(useBatch && selfBatch && options.batch){
					options.batch.exec();
				 }
			 };
			 
			 let promises = eventData.promises || [];
			 if (eventData.promise && eventData.promise.then){
				 promises.push(eventData.promise);
			 }
			 //BEFORE支持异步逻辑
			 if(promises.length>0){
				 Promise.all(promises).then(()=>{
					 callback();
				 },(err)=>{
					 let msg = _String.format(this.page.i18n("保存数据失败! 原因： {0}"), (err.message || err));
					 throw new Error(msg);
				 });
			 }else{
				 callback();
			 }
    	 }catch(e){
    		 let msg = _String.format(this.page.i18n("保存数据失败! 原因： {0}"), (e.message || e));
    		 throw new Error(msg);
    	 }
    	 return retPromise;
     }
     
     doSaveData(options){
    	 return {success: true};
     }
     
     doSaveAfter(success, options, params){
    	 options = options || {};
		 params = params || {};
		 params.source = params.source || this;
		 var onError = options.onError;
		 var onSuccess = options.onSuccess;
		 if(success){
			 this.applyUpdates(params, options);
	    	 //保存成功同步共享数据，保存失败不进行共享数据同步依赖修改等操作同步
	    	 this._updateShareData();

			 if (onSuccess && (typeof onSuccess === 'function'))
				onSuccess(params);
			 this.fireEvent(Data.EVENT_SAVEDATA_COMMIT, params);
		 }else{
			if (onError && (typeof onError === 'function')) {
				onError(params);
			}
			this.fireEvent(Data.EVENT_SAVEDATA_ERROR, params);
		 }					
	 }
     
     applyUpdates(data,options){
    	 // 更新版本字段和状态不触发事件和状态变化
		 this.disableRecordChange();
		 try {
			 if(data && data.data && data.data.saveRows && data.data.saveRows.length>0){
				 var self = this;
				 for(let i=0,len=data.data.saveRows.length;i<len;i++){
					 let row = data.data.saveRows[i];
					 row && this.resetState(row);
				 };
			 }else{
				 options = options || {};
				 var parent = options.parent || this._allvalue;
				 this.resetState(parent);
			 }
		} finally {
			this._dataChangedUUID.set(new UUID().toString());
			this.enabledRecordChange();
		}
	 }
     
     disableRecordChange() {
    	 this._disableRecordChange = true;
	 }
	 enabledRecordChange() {
		this._disableRecordChange = false;
	 }
	 canRecordChange() {
		return !this._disableRecordChange;
	 }
     
	 getVersionLock() {
		let versionCol = this.versionLock;
	 	return versionCol && this.getColumnDef(versionCol);
	 }

	 //刷新时的智能加载忽略版本号+1
     resetState(ob, ignoreVersion){
    	 if (isObservableObject(ob)){
    		 if (ob.getState){
    			 let state = ob.getState();
        		 let versionCol = this.versionLock;
        		 if (!ignoreVersion && versionCol && Data.STATE.EDIT == state) {
    				if (ob[versionCol] || 0===ob[versionCol]) {
    					ob[versionCol] = parseInt(ob[versionCol],10) + 1;
    				}
        		 }
        		 
        		 ob.setState(Data.STATE.NONE);
        		 for (let name in ob){
        			 this.resetState(ob[name], ignoreVersion);
        		 }    			 
    		 }
    	 }else if (isObservableArray(ob)){
    		 //ob.setState(Data.STATE.NONE);
    		 for (let i=0; i<ob.length; i++){
    			 this.resetState(ob[i], ignoreVersion);
    		 }
    	 }else if (ob && ob.$isData){
    		 //解决嵌套数据集保存后状态没有还原的问题
    		 ob.resetState(ob.value, ignoreVersion);
    	 }
     }
     
     deleteAllData(options){
 		 if (this.isComputedData()){
			 console.error("计算数据集不支持删除");
			 return Promise.resolve();
		 }

    	 var ret = this.deleteData(this._allvalue, options);
    	 var eventData = {
    		 source: this,
    		 changedSource: this,
    		 type: 'clear',
    		 selfChanged: true
    	 };
		 this.doDataChanged(eventData);
		 return ret;    	 
    	 
     }
     
     removeData(value, options){
    	 options = options || {};
    	 options.parent = options.parent || this._allvalue;
    	 if (!isArray(value)){
			 value = [value];
		 }
    	 var beforeRemoveEventData = {
    			 cancel: false,
				 source: this,
				 options: options,
				 value: value
		 };
		 this.fireEvent(Data.EVENT_REMOVEDATA_BEFORE, beforeRemoveEventData);
		 value = beforeRemoveEventData.value;
		 try{
			 this._sorting = true;
			 for (let i=0; i<value.length; i++){
				 this.remove(value[i], options.parent); // 没有支持tree
			 }
		 }finally{
			 this.doSort();
			 this._sorting = false;
		 }
    	 var eventData = {
    		 source: this,
    		 changedSource: this,
    		 type: 'delete',
    		 selfChanged: true
    	 };
		 this.doDataChanged(eventData);
     }
     
     deleteData(value, options){
 		 if (this.isComputedData()){
			 console.error("计算数据集不支持删除");
			 return Promise.resolve();
		 }
		 
		 
    	 options = options || {};
    	 var retPromise = this._createPromise(options);
    	 var result = false, async = (options&&options.async)||false,promise;
    	 options.parent = options.parent || this._allvalue;
    	 if (isObservableArray(options.parent)){
    		 if (options.parent.length == 0) return true;
    		 if (!isArray(value)){
    			 value = [value];
    		 }
    	 }else{
    		 value = value || this._allvalue;
    	 }
    	 
    
    	 var eventData = {
    			 cancel: false,
				 source: this,
				 options: options,
				 deleteValue: value
		 };
		 this.fireEvent(Data.EVENT_DELETEDATA_BEFORE, eventData);
		 if (eventData.cancel){
			 retPromise.rej();
			 return retPromise;
		 }    	 
    	 
    	 var fn = () => {
	    	 if (this.hasListener(Data.EVENT_DELETEDATA)) {
	    		 eventData = {
	    			source: this,
	    			cancel: false,
	    			options: options,
	    			deleteValue: value
	    		 }
	    		 this.fireEvent(Data.EVENT_DELETEDATA, eventData);
	    		 async = eventData.async;
	    		 result = !eventData.cancel;
	    		 promise = eventData.promise;
	    	 }else{
	    		 options = Object.assign({}, options);
				 var oldSuccessFn = options.onSuccess; 
				 options.onSuccess = (params) => {
					 if (isObservableObject(options.parent)){
						 if ((options.parent === this._allvalue)){
							 this._clearObject(this._allvalue);
						 }else{
							 throw new Error(this.page.i18n("删除对象时, 只能删除整个数据集"));
						 }
					 }else{
						 let values = (params && params.deleteValue)||value;
						 for (let i=0; i<values.length; i++){
							 this.remove(values[i], options.parent); // 没有支持tree
						 }
					 }
					 if('function'===typeof(oldSuccessFn)) oldSuccessFn(options);
				 };

				 var ret = this.doDeleteData(value, options);
				 result = ret.success;
				 async = ret.async;
				 promise = ret.promise;

	    	 }

	    	 options.deleteValue = value;
			 if (async || (promise && promise.then)){
				if (promise && promise.then){
					var self = this;
					promise.then(function(){
						self.doDeleteAfter(true, options);
					},function(){
						self.doDeleteAfter(false, options);
					});
				} 
			 }else{
		    	this.doDeleteAfter(result, options);
			 }
    	 };
    	 
    	 var confirmDelete = this.confirmDelete;
    	 if (options && options.hasOwnProperty("confirm")){
    		 confirmDelete = options.confirm;
    	 }
    	 if (confirmDelete){
    		 var confirmDeleteText = this.confirmDeleteText;
    		 this._confirm(confirmDeleteText, fn, function(){
    			 retPromise.rej();
    		 });
    	 }else{
    		 fn();
    	 }
    	 
    	 return retPromise;
     }
     
     doDeleteData(value, options){
    	 return this.doDirectDeleteData(value, options);
     }
     
     remove(value, parent){
    	 parent = parent || this._allvalue;
    	 if (isObservableArray(parent)){
    		 var index = parent.indexOf(value);
        	 if (index > -1){
        		 var total = this.getArrayTotal(parent)-1;
        		 total = (total>0) ? total : 0;
        		 this.setArrayTotal(parent, total);
        		 let id = value[this.getIdColumn()];
        		 parent.remove(value);
        		 this.removeSubData(id);
        	 }
    	 }
     }
     
     getSubDataId(id, col){
    	 return col + "_" + id;
     }
     
     getSubData(id, col){
    	 let compid = this.getSubDataId(id, col);
    	 return this.page.comp(compid);
     }
     
     getSubDatas(id){
    	 let ret = [];
    	 for (let col in this.subDataDefine){
    		 if (this.subDataDefine.hasOwnProperty(col)){
    			 let compid = this.getSubDataId(id, col);
    			 let comp = this.page.comp(compid);
    			 if (comp){
    				 ret.push(comp);
    			 }
    		 }
    	 }
    	 return ret;
     }
     
     isSubDataCol(col){
    	 return this.subDataDefine[col];
     }
     
     removeSubData(id){
    	 for (let col in this.subDataDefine){
    		 if (this.subDataDefine.hasOwnProperty(col)){
    			 let compid = this.getSubDataId(id, col);
    			 let comp = this.page.comp(compid);
    			 if (comp){
    				 try{
    					 comp.destroy();
    				 }catch(e){
    					 console.error(e);
    				 }
    			 }
    			 delete this.$subDataComps[compid];
    		 }
    	 }
     }
     
     doDirectDeleteData(rows, options){
    	 return {success : true, async : false};
     }
     
     doDeleteAfter(success, options, params){
    	params = params || {};
		if(!params.source) params.source = this;
    	var onError = options.onError;
		var onSuccess = options.onSuccess;
		
		var parent = options.parent || this._allvalue;
		if (success){
			if (onSuccess && (typeof onSuccess === 'function')){
				onSuccess(params);
			}
			this.doSort();
			var eventData = {
				'source' : this,
				'deleteValue' : params.value || options.value || options.deleteValue
	 		 };
			 this.fireEvent(Data.EVENT_DELETEDATA_AFTER, eventData);
	         eventData.changedSource = this;
			 eventData.type = 'delete';
			 eventData.selfChanged = true;
			 this.doDataChanged(eventData);
		 }else{
			 if (onError && (typeof onError === 'function')) {
				 onError(params);
			 }
			 this.fireEvent(Data.EVENT_DELETEDATA_ERROR, params);
		 }
     }
     
    
     loadAllPageData(parent, options){
    	 if (isObservableArray(parent)){
    		 options = options || {};
    		 options.parent = parent;
    		 var limit = this.getArrayLimit(parent);
    		 if (limit == -1){
    			 return this.refreshData(options);
    		 }else{
    			 var total = this.getArrayTotal(parent), offset = this.getArrayOffset(parent);
    			 if ((total <= offset) || (total < 1)) return Promise.resolve();
   				 options.append = true;
				 options.limit = total;
				 return this._refreshData(options);
    		 }
    	 }else{
    		 return Promise.resolve();
    	 }
     }
     
     loadPageData(parent, index, options){
    	 if (isObservableArray(parent)){
    		 options = options || {};
    		 options.parent = parent;
    		 if (index < 1) index = 1;
        	 var limit = this.getArrayLimit(parent); 
    		 if ((limit==-1) || (index==1)){
    			 return this.refreshData(options);
    		 }else{
    			 if ((index===null || index===undefined) && options.hasOwnProperty("offset")){
    				 //使用options中的offset
    			 }else{
    				 options.offset = (index-1) * limit;
    			 }
    			 options.append = false;
    			 return this._refreshData(options);
    		 }
    	 }else{
    		 return Promise.resolve();
    	 }
     }
     loadNextPageData(parent, options){
    	 if (isObservableArray(parent)){
    		 //解决加载下一页时，如果正在刷新时等待刷新的结果后执行
    		 return new Promise((resolve, reject)=>{
        		 let p = this.__lastRefreshPromise || Promise.resolve();
        		 p.finally(()=>{
            		 if (this.getArrayLimit(parent) == -1)  {resolve(); return}
                     var total = this.getArrayTotal(parent), offset = this.getArrayOffset(parent);
                     if ((total <= offset) || (total < 1)) {resolve(); return}      
                	 options = options || {append:true};
                	 options.parent = parent;
                     this._refreshData(options).then((data)=>{
                    	 resolve(data);
                     }, (err)=>{
                    	 reject(err);
                     });	 
        		 });
    		 });
    	 }else{
    		 return Promise.resolve();
    	 }
     }
     
     setReadonly(val){
    	 this._readonlyOB.set(!!val);
     }
     
     getReadonly(){
    	 if (this.props.options && (this.props.options.enableContextReadonly === false)){
    		 return false;
    	 }
    	 
    	 if (this._readonlyOB.get()){
    		 return true;
    	 }
    	 
    	 if (this.page.$isReadonlyMode && this.page.$isReadonlyMode()){
    		 return true;
    	 }else{
        	 if (this.props.options && this.props.options.dataReadonly){
        		 if (typeof this.props.options.dataReadonly === "function"){
        			 return this.props.options.dataReadonly(this);
        		 }else if ( this.page[this.props.options.dataReadonly]){
        			 return this.page[this.props.options.dataReadonly](this);
        		 }else{
        			 return false;
        		 }
        	 }else{
            	 return false;
        	 }
    	 }
     }
     
     isChanged(options){
		this._dataChangedUUID.get();
    	 options = options || {};
    	 var parent = options.parent || this._allvalue;
    	 if (isObservableArray(parent)){
    		 for (let i=0; i<parent.length; i++){
    			 var item = parent[i];
    			 if (item && (item.getState()===Data.STATE.NEW 
        				 || item.getState()===Data.STATE.EDIT)){
    				 return true;
    			 }
    		 }
    		 return false;
    		 
    	 }else if (isObservableObject(parent)){
    		 return parent.getState()===Data.STATE.NEW 
    				 || parent.getState()===Data.STATE.EDIT;
    	 }else{
    		 return false;
    	 }
     }

     reaction(expression, sideEffect , options){
		return reaction(expression,sideEffect,options);
	 }
}

Data.STATE = {
	EDIT: "edit",
	NEW: "new",
	DELETE: "delete",
	NONE: "none"
};

// 新增业务数据的事件＝＝＝＝＝＝＝＝＝
Data.EVENT_NEWDATA_ERROR = "newError";
Data.EVENT_NEWDATA_CREATEPARAM = "newCreateParam";
Data.EVENT_NEWDATA_BEFORE = "beforeNew";
Data.EVENT_NEWDATA = "customNew";
Data.EVENT_NEWDATA_AFTER = "afterNew";

Data.EVENT_REMOVEDATA_BEFORE = "beforeRemove";

// 删除业务数据的事件＝＝＝＝＝＝＝＝＝
Data.EVENT_DELETEDATA_ERROR = "deleteError";
Data.EVENT_DELETEDATA_BEFORE = "beforeDelete";
Data.EVENT_DELETEDATA = "customDelete";
Data.EVENT_DELETEDATA_AFTER = "afterDelete";

// 刷新业务数据的事件＝＝＝＝＝＝＝＝＝
Data.EVENT_REFRESHDATA_ERROR = "refreshError";
Data.EVENT_REFRESHDATA_CREATEPARAM = "refreshCreateParam";
Data.EVENT_REFRESHDATA_BEFORE = "beforeRefresh";
Data.EVENT_REFRESHDATA = "customRefresh";
Data.EVENT_REFRESHDATA_AFTER = "afterRefresh";
Data.EVENT_SMART_LOAD_BEFORE = "beforeSmartLoad";

// 保存业务数据的事件＝＝＝＝＝＝＝＝＝
Data.EVENT_SAVEDATA_ERROR = "saveError";
Data.EVENT_SAVEDATA_COMMIT = "saveCommit";
Data.EVENT_SAVEDATA_BATCH_READY = "saveBatchReady";
Data.EVENT_SAVEDATA_CREATEPARAM = "saveCreateParam";
Data.EVENT_SAVEDATA_BEFORE = "beforeSave";
Data.EVENT_SAVEDATA = "customSave";
Data.EVENT_SAVEDATA_AFTER = "afterSave";
//保存或者删除数据影响记录数异常
Data.EVENT_SAVEDATA_EXCEPTION = "saveException";

// 行改变事件
Data.EVENT_INDEX_CHANGED = "indexChanged";
Data.EVENT_INDEX_CHANGING = "indexChanging";

Data.EVENT_LOAD_BEFORE = "beforeLoad";

Data.EVENT_VALUE_CHANGED = Schema.EVENT_VALUE_CHANGED;
Data.EVENT_VALUE_CHANGE = Schema.EVENT_VALUE_CHANGE;
Data.EVENT_DATA_CHANGE = Schema.EVENT_DATA_CHANGE;
Data.EVENT_SUBDATA_CHANGED = "subDataChanged";

Data.updateShareData = function(page){
	page && page.fireEvent('updateShareData',{});
};

Data.loadShareData = function(page){
	page && page.fireEvent('loadShareData',{});
};

wx.comp = wx.comp || {};
wx.comp.Data = Data;

