import React,{Fragment} from 'react';


export default class LoadErrorPage extends React.Component{
  constructor(props,context){
    let wxPageStyle = {
    };
    let wxPageConfig ={};
    let wxPagePathResolver = function (path){
      return path;
    }
    super(props,context);
    this.wxPagePathResolve = wxPagePathResolver;
    this.wxPageStyle = wxPageStyle;
    this.wxPageConfig = wxPageConfig;
    this.wxPageDeclaration = {
      onShow:()=>{},
      onHide:()=>{},
      onReady:()=>{},
      onPullDownRefresh: ()=>{},
      onReachBottom:()=>{},
      onUnload: ()=>{},
      onShareAppMessageHandle: ()=>{},
      onPageScroll: ()=>{},
      $page : {}
    };
  }

  getPageConfig(){
    return {};
  }

  render(){
    return <div>
      <h1 style={{"fontSize":"25px"}}>加载失败</h1>
      <div  style={{"fontSize":"16px","wordWrap":"break-word"}}>
        {this.props.msg}
      </div>
      {this.props.stack?<Fragment>
        <h1 style={{"fontSize":"18px"}}>详细信息:</h1>
        <div dangerouslySetInnerHTML={{__html:this.props.stack}}  style={{"fontSize":"16px","wordWrap":"break-word"}}></div>
      </Fragment>:''}
  </div>
  }
  }
