import {createHashHistory,parsePath,createPath} from "./history/index";
import URI from 'urijs';
let history = createHashHistory();
import mitt from 'mitt';
//兼容小程序webview
window.appHistory || (window.appHistory = history);
history.router = {
  emitter : mitt(),
  locationProcessor:undefined //如果做门户路由控制 可以设置地址处理器
}

let push = history.push;
let replace = history.replace;
let listen = history.listen;

history.push = (to,state)=>{
  if(createPath(history.getLocation()) == to){
    return;
  }

  if(to){
    if(typeof to == "string"){
      to = parsePath(to);

    }

    if(to.search === undefined){
      to.search = "";
    }
    to = fixShortAddressToStandardAddress(to);
    to = history.router.locationProcessor?history.router.locationProcessor(to,"push"):to;
  }
  if(to && to.search === undefined){
    to.search = "";
  }
  return push(to,state);
}

history.replace = (to,state)=>{
  if(to){
    if(typeof to == "string"){
      to = parsePath(to);
    }

    if(to.search === undefined){
      to.search = "";
    }
    to = fixShortAddressToStandardAddress(to);

    to = history.router.locationProcessor?history.router.locationProcessor(to,"replace"):to;
  }
  if(to && to.search === undefined){
    to.search = "";
  }
  return replace(to,state);
}

/**
 * 修正短地址为微服务路径
 * /entry/pcxapp/#/pcx/index -> /entry/pcxapp/pcx/index
 *
 */
function fixShortAddressToStandardAddress(to){
   to = createPath(to);

   let uri = new URI(to);
    if(to.indexOf('http') == 0){
      return parsePath(uri.toString());
    }
   let fragment = uri.path();
   if(fragment && fragment.search(/(pcxapp\/|mobileapp\/)/g) == -1){
     let path = window.location.pathname;
     //是微服务模式加载
     if(path.search(/(pcxapp\/|mobileapp\/)/g) != -1){
       let serviceName,contextName;
       path.split("/").find((item)=>{
         if(item.search(/(pcxapp|mobileapp)$/g) != -1){
            contextName = item;
           return true;
         }else {
           serviceName = item;
         }
       })
       fragment = serviceName?`/${serviceName}/${contextName}${fragment}`:`/${contextName}${fragment}`;
       uri.path(fragment);
     }
   }
   return parsePath(uri.toString());
}


history.listen = (listenCallback) =>{
  return listen(({location, action}) => {
    location = history.router.locationProcessor?history.router.locationProcessor(location,"listen"):location;

    return listen(listenCallback({location,action}));
  })
}

history.getLocation = function(){
  let location = history.location;
  return history.router.locationProcessor?history.router.locationProcessor(location,"listen"):location;
}

// history.type
export * from './history/index';

export default history;
