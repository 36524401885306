import _ from 'lodash';
import {
  doThen,
  doCatch,
  isX5App,
  isWeixinBrowser,
  isLocalFile,
  resolveLocalFile,
  getSaveElement,
  isWeixinWebView
} from '../../utils';
import Preview from './preview/preview';

/*
 * count Number 否 最多可以选择的图片张数，默认9 sizeType StringArray 否 original 原图，compressed
 * 压缩图，默认二者都有 sourceType StringArray 否 album 从相册选图，camera 使用相机，默认二者都有 success
 * Function 是 成功则返回图片的本地文件路径列表 tempFilePaths fail Function 否 接口调用失败的回调函数
 * complete Function 否 接口调用结束的回调函数（调用成功、失败都会执行）
 */
function chooseAllFile(params) {
  params.count = (typeof params.count == undefined) ? 9 : params.count;
  if(params.count === 0){
    params.count = 1;
  }
  let fileChoose = document.createElement('input');
  fileChoose.type = "file";
  if(params.count > 1){
    fileChoose.multiple = "multiple";
  }
  fileChoose.style = "position: absolute;left:-8888px;";
  fileChoose.addEventListener('change', function () {
    let results = [];
    window.inputFiles = {};
    _.forEach(fileChoose.files, (file) => {
      const id = _.uniqueId('inputFile://') + "/" + file.name;
      window.inputFiles[id] = file;
      results.push(id);
    });
    doThen(params, {
      tempFilePaths: results
    });
  }, false);
  document.body.appendChild(fileChoose);
  fileChoose.click();
}


/*sourceType: ['album', 'camera'],*/
function chooseImage(params) {
  params.count = (typeof params.count == undefined) ? 9 : params.count;
  if(params.count === 0){
    params.count = 1;
  }
  if(isX5App && params.sourceType && params.sourceType.length == 1 && params.sourceType[0] == "camera"){
    navigator.device.capture.captureImage((mediaFiles) =>{
      let tempFilePaths = [];
      for (let i = 0; i < mediaFiles.length; i++) {
        tempFilePaths.push(mediaFiles[i].localURL);
      }
      doThen(params, {
        tempFilePaths: tempFilePaths
      });
    }, (error)=>{
      doCatch(params, error.code);
    }, {limit:1});
    return;
  }
/*  if (isX5App) {
    window.imagePicker.getPictures(function (results) {
      doThen(params, {
        tempFilePaths: results
      });
    }, function (error) {
      doCatch(params, error);
    }, {
      "maximumImagesCount": params.count,
      "width": 800,
      "outputType": window.imagePicker.OutputType.FILE_URI
    });
  } else {*/
    let imageChoose = document.createElement('input');
    imageChoose.type = "file";
    if(params.count > 1 && !isWeixinWebView){
      imageChoose.multiple = "multiple";
    }
    if(isX5App || isWeixinBrowser){
      imageChoose.accept = "image/*" ;
		}else{
	    imageChoose.accept = "image/gif,image/jpeg,image/jpg,image/png,image/svg,image/webp,image/tiff";
		}
    imageChoose.style = "position: absolute;left:-8888px;";
    imageChoose.addEventListener('change', function () {
      window.wx && window.wx.chooseImageOnChange && window.wx.chooseImageOnChange(imageChoose,params);
      let results = [];
      window.inputFiles = {};
      let filePostProcessPromises = [];
      _.forEach(imageChoose.files, (file) => {
        if (results.length == params.count) {
          return;
        }
        const id = _.uniqueId('inputFile://') + "/" + file.name;
        results.push(id);
        let _promise = new Promise(async function(resolve,reject){
          let {default:lrz} = await import('./plugins/compress/dist/lrz.all.bundle');
          lrz(file).then(function(result){
            window.inputFiles[id] = result.file;
          }).catch(function(){
            window.inputFiles[id] = file;
          }).always(function(){
            resolve();
          });
        });
        filePostProcessPromises.push(_promise);
      });
      Promise.all(filePostProcessPromises).then(function(){
        doThen(params, {
          tempFilePaths: results
        });
      });
    }, false);
    document.body.appendChild(imageChoose);
    imageChoose.click();
//  }
}
/*
 * current String 否 当前显示图片的链接，不填则默认为 urls 的第一张 urls StringArray 是 需要预览的图片链接列表
 * success Function 否 接口调用成功的回调函数 fail Function 否 接口调用失败的回调函数 complete Function
 * 否 接口调用结束的回调函数（调用成功、失败都会执行）
 */
function previewImage(params) {
  let urls = params.urls;
  params.current = params.current || urls[0];
  let urlDfds = _.map(urls, (url) => {
    let urlDfd = new Promise(function (resolve, reject) {
      if (isLocalFile(url)) {
        resolveLocalFile(url).then(function (file) {
          const URL = window.URL || window.webkitURL || window.mozURL;
          let objectUrl = URL.createObjectURL(file);
          if (url === params.current) {
            params.current = objectUrl;
          }
          resolve(objectUrl);
        });
      } else {
        resolve(url);
      }
    });
    return urlDfd;
  });
  Promise.all(urlDfds).then(function (resolvedUrls) {
    let preview = new Preview(resolvedUrls, {})
    preview.show()
    preview.active(params.current);
  });
}


function _createTempImg(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    if (isLocalFile(src)) {
      resolveLocalFile(src).then(file => {
        const URL = window.URL || window.webkitURL || window.mozURL;
        const imageObjectUrl = URL.createObjectURL(file);
        img.src = imageObjectUrl;
      })
    } else {
      img.src = src;
    }
    img.onload = function (event) {
      resolve({
        width: `${event.target.width}px`,
        height: `${event.target.height}px`,
        path: event.target.src
      })
    }
    img.onerror = function (event) {
      reject(src + "The image could not found");
    }
  });
}


/*
 *wx.getImageInfo(OBJECT)
 获取图片信息

 OBJECT参数说明：

 参数	类型	必填	说明
 src	String	是	图片的路径，可以是相对路径，临时文件路径，存储文件路径，网络图片路径
 success	Function	否	接口调用成功的回调函数
 fail	Function	否	接口调用失败的回调函数
 complete	Function	否	接口调用结束的回调函数（调用成功、失败都会执行）
 success返回参数说明：

 参数	类型	说明
 width	Number	图片宽度，单位px
 height	Number	图片高度 单位px
 path	String	返回图片的本地路径
 */
function getImageInfo(params = {}) {
  const src = params.src;
  _createTempImg(src).then(result => {
    doThen(params, result);
  }).catch(error => {
    doCatch(params, error);
  })
}

/**
 filePath  String  是  图片文件路径，可以是临时文件路径也可以是永久文件路径，不支持网络图片路径
 success  Function  否  接口调用成功的回调函数
 fail  Function  否  接口调用失败的回调函数
 complete  Function  否  接口调用结束的回调函数（调用成功、失败都会执行）
 */
function saveImageToPhotosAlbum(params) {
  let a = getSaveElement();
  let promise = new Promise(function (resolve) {
    resolveLocalFile(params.tempFilePath).then(file=>{
      let url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = params.fileName;
      a.click();
    })
  });
  promise.then(function (res) {
    doThen(params, res);
  }).catch(function (error) {
    doCatch(params, error);
  });
}

export {chooseImage, chooseAllFile,previewImage, getImageInfo,saveImageToPhotosAlbum};
