import React, { Component,cloneElement } from 'react';
import PropTypes from 'prop-types'
import cx from "classnames";
import './pullDownRefresh.css';
import PageComponent from 'core/framework/PageComponent';
import history from "core/appHistory";

export default class PullToRefresh extends Component {
  constructor(props) {
    super(props);
    this.needScroll = true;
    this.state = {
      initialized: false
    };
    this.xScrollRef = React.createRef();
    this.xScrollContentRef = React.createRef();

  }
  async init() {
    if (!this.state.initialized) {
      let page = this.page;
      let enablePullDownRefresh = page.getPageConfig('enablePullDownRefresh');
      if(enablePullDownRefresh){
        let onReachBottomDistance = Number.parseInt(page.getPageConfig('onReachBottomDistance') || 50);
        let {default:iScroll} = await import('./iScrollLite');
        this.scroller = new iScroll(this.xScrollRef.current,this.page,enablePullDownRefresh,onReachBottomDistance);
        let backgroundTextStyle = page.getPageConfig('backgroundTextStyle');
        if(backgroundTextStyle === "light"){
          this.scroller.$pdiEle.css("filter","invert(100%)");
        }
        page.stopPullDownRefresh = () =>{
          if(this.scroller){
            return this.scroller.refresh();
          }else{
            return new Promise((resolve,reject)=>reject());
          }
        };
        page.startPullDownRefresh = () =>{
          if(this.scroller){
            return this.scroller.startPullDownRefresh();
          }else{
            return new Promise((resolve,reject)=>reject());
          }
        };
      }

      this.setState({
        initialized: true
      });
    }
  }
  componentDidMount() {
    if(this.needScroll){
      this.init();
      let disableScroll = this.page.getPageConfig("disableScroll");
      if(disableScroll){
        this.xScrollContentRef.current.style.overflow = "hidden";
      }
    }

  }

  componentWillUnmount() {
    this.scroller?.destroy();
  }


  render() {
    let children = React.Children.map(this.props.children, (child) => {
      if (child === null) {
        return null;
      }

      return cloneElement(this.props.children, {
        ref: (wxReactPageElement) => {this.page = wxReactPageElement},
        pageId:this.props.pageId,
        renderRoot:this.props.renderRoot,
        configContext:this.props.children?.props?.configContext || history.router.configContext

      });
    });

    /**
     * uix体系下
     *    pcx页面不需要 scrollview 包装 效果为自动撑高
     *
     *    mx页面需要 scrollview 包装 因为是移动端页面
     *
     *    mx页面运行在pc场景下 不需要 scrollview 包装 因为是移动端页面
     *
     * 非uix体系下
     *    统一走scrollview包装 兼容老逻辑
     *
     *
     */

    let [pageComponent] = children;

    if(window.isPcx
      && pageComponent
      && pageComponent.type.__proto__ != PageComponent
    ){
      //pcx。ejs 模块下 加载新体系编译出来的页面
      this.needScroll = false;
      return <React.Fragment>
        {children}
      </React.Fragment>
    }

    return (
      <div {...this.props} ref={this.xScrollRef} className = {cx('x-scroll',this.props.className)}
                           onTouchStart = {(event) => {if(this.scroller){this.scroller.handleStart(event)}}}
                           onTouchMove = {(event) => {if(this.scroller){this.scroller.handleMove(event)}}}
                           onTouchEnd = {(event) => {if(this.scroller){this.scroller.handleEnd(event)}}}
                           onTouchCancel = {(event) => {if(this.scroller){this.scroller.handleCancel(event)}}}
                           onScroll = {(event) => {if(this.scroller){this.scroller.handleScroll(event)}}}>
        <div className="x-content-center x-pull-down">
          <i className="x-pull-down-img x-icon-pull-down"/>
          <span className="x-pull-down-label"/>
        </div>
        <div className="x-scroll-content" ref={this.xScrollContentRef}>
          {children}
        </div>
      </div>
    );
  }
}
