import RestData from "./restData";
import {isArray} from "../../lib/base/util";
import UUID from "../../lib/base/uuid";
import RandomUtilFn from "../../lib/fn/randomUtilFn";

export default class Subquery extends RestData {
	constructor(page, id, props, context){
		super(page, id, props, context);
	}

	getClass(){
		return Subquery;
	}
	
	init(){
		super.init();
	}
	
	_initDefinition() {
		super._initDefinition();
		//产生idCol相关定义
		if(!this.props.options.idColumn){
			this.props.options.idColumn = 'idCol';
			this.props.schema.items.props['idCol'] = {type:'String',label:'ID列',define:'EXPRESS'};
		}
	}
	
	saveData(option) {
		this.showError({message: this.page.i18n("不支持保存")});
	}
	
	deleteData(option) {
		this.showError({message: this.page.i18n("不支持删除")});
	}
	
	
	beforeLoadData(data, options){
		if(isArray(data)){
			let idColumn = this.getIdColumn();
			let defcol = this.getColumnDef(idColumn);
			if(defcol && ('string'===defcol.type || "integer"===defcol.type)){
				for (let i=0; i<data.length; i++){
					if (!data[i].hasOwnProperty(idColumn)){
						if (defcol.type === "string"){
							let uuid = new UUID().toString();
							data[i][idColumn] = uuid.replace(/-/g,'').toUpperCase();
						}else{
							data[i][idColumn] = RandomUtilFn.randomInt(9);
						}
					}
					
				}
			}			
		}
		return super.beforeLoadData(data, options);
	}	
}

wx.comp = wx.comp || {};
wx.comp.Subquery = Subquery
