import {toResUrl} from "../../../../wxsys/lib/base/util";
import {findPageByRoute} from 'core/router';
import {addOpenerRoute,dfsFind} from 'core/utils';

import Eventable from "../../../../wxsys/lib/base/eventable";
import ServiceUtil from "../../../../wxsys/lib/base/serviceUtil";
import URI from 'urijs';
import PageComponent from 'core/framework/PageComponent';
import {debounceNextViewTickRun} from "core/utils";

function getDataType(obj){
	return Object.prototype.toString.call(obj).split(" ")[1].split("]")[0]
}





export default class Page extends PageComponent{
    constructor(props, context){
    	super(props,context);

		//Eventable.prototype._constructor.call(this);
		this.events = this.events || {};
		this.pageType = "uix";
		if (this.listeners) {
			this.on(this.listeners);
			delete this.listeners;
		}
    }
    
    /**
     * mainData.current.fname
     * mainData.current.fcomment.current.fname
     * item.fname
     * item.fcomment.current.fname
     * 
     * root: 表示根变量的值
     * templateDataId: 模板id
     */
    $getDataIdByRef(ref, root, templateDataId){
    	let ret = null;
    	if (ref && root){
    		root = this.$parseRoot(root);
    		this.$checkRef(ref, root);
    		let items = ref.split(".");
    		let prev = null;
			let last = root;
			//忽略最后一项
			for (let i=1; i<items.length-1; i++){
				let name = items[i];
				if (last[name]){
					prev = last;
					last = last[name];
				}else{
					if (i!=items.length-2){
						//最后一项是当前行, 不是最后一项为空时表示真实id为null
						prev = null;
					}
					break;
				}
			}
			
			if (last && last.$data){
				//xx.current.fname的情况
				ret = last.$data.id;
			}else if (last._configCopy && last.id){
				//xx.value的情况
				ret = last.id;
			}else if (prev && prev.id && prev._configCopy){
				ret = prev.id;
			}
    	}
    	if (templateDataId){
    		ret = (ret||"") + ":" + templateDataId + ":" + ref;
    	}
    	return ret;
    }
    
    $parseRoot(root){
    	if (root.$data || root.$isData){
    		return root;
    	}else if (root.$dataId){
    		let data = this.comp(root.$dataId);
    		let row = data.getRowByID(root[data.getIdColumn()]);
    		return row;
    	}else{
    		throw new Error("非法的ref: " + ref + ", ref的根节点必须是数据集或行对象");
    	}
    }
    
    $checkRef(ref, root){
    	let items = ref.split(".");
    	let currentPos = 0;
    	if (root.$data){
    		//第一个是行对象时, current在偶位
    		currentPos = 0;
    	}else if (root._configCopy){
    		//第一个是数据集时, current在奇位
    		currentPos = 1;
    	}else{
    		throw new Error("非法的ref: " + ref + ", ref的根节点必须是数据集或行对象");
    	}
    	
    	for (let i=1; i<items.length; i++){
    		let item = items[i];
    		if ((i%2==currentPos) && (item!=="current" && !item.indexOf("value")==0)){
    			throw new Error("非法的ref: " + ref);
    		}		
    	}
    }


    isReadonly(){
    	return this.$isReadonlyMode();
    }
    
    getParentFrame(){
    	if (window && window.__$$pageFrames 
				&& this.params 
				&& this.params.__pageFrameId__
				&& window.__$$pageFrames[this.params.__pageFrameId__]){
			return window.__$$pageFrames[this.params.__pageFrameId__];
		}else{
			return null;
		}
    }

    comp(key,comp){
    	//首先查找上层组件 主要为数据模型组件 比如restData
        let result = this.$compByCtx(key,comp);
        
        //其次通过ref查找对应的组件实例
        if((!result) && this.owner.reactRefs[key]){
        	result = this.owner.reactRefs[key].current;
        }
        
        //通过ref查找不到 通过idRefMapping查找
        if((!result) && this.owner.__idRefMapping[key]){
        	result = this.owner.__idRefMapping[key].current;
        }
        return result;
    }

    //------------setData相关逻辑开始----------------------------------
	setData(options){

		for (let name in options) {
            let o = this.$getObjectByPath(this.data, name);
			let r = o.obj;
			let i = o.key;
            if (r){
            	r[i] = deepCopy(options[name]);
            }
        }
        
		this.$setDataProxy(options); //只更新需要更新的数据
	}

	getServiceUrl(url, serviceName){
		return ServiceUtil.getServiceUrl(url, serviceName || this.serviceName, this.parentPath);
	}

	//静态资源url 返回带协议的url
	getUIUrl(url){
		return toResUrl(url, false, this.basePath, this.contextPath,this.parentPath);
	}
	getParentPath(){
		return this.parentPath || "";
	}

	getServiceName(){
		return this.serviceName || "";
	}

   	/**
	 *   params.currentPage  是从功能视角看 比如页面上有 多层pageFrame嵌套 或者 多层对话框弹出
	 *   所有对话框打开时候参数 currentPage 永远是跟页面
	 *
	 *   params.currentModel 是父子关系信息 currentModel永远是打开子的父Model
	 *
	 *
	 *   this.ownerPage 是pcx打开对话框的时候 会存储跟页面的page对象到这个域上  （移动端其实没有windowDialog逻辑 所有没有这个域）
	 *   时机是对话框的model onload 之后 onShow之前
	 *
	 *  pc端打开页面的逻辑是
	 *  navigateTo 刺激路由变化 路由变化后 刺激到 index.usePageTabs 逻辑
	 *   	这个逻辑中根据 activePageUrl 从menus中找到 真实的url 形成tab结构 让后给到 tab里面的 pageContainer
	 *
	 * 	mobile端的打开页面逻辑是
	 *   	navigateTo 刺激路由变化 路由变化后 main-ent-mx.js 中的loadPage
	 *

	 *
	 *
	 */


   	async navigateTo(params){

		params.currentPage = this.ownerPage || this;
		params.currentModel = this;

		this._formatPageUrl(params);

		params.openType  = params.openType || "page";
		params.prependServicePath = false;
		
		//传递页面的执行者
		if (!params.executor){
			params.url = wx.ServiceUtil.addExecutor(params.url, this.params.executor);
		}
		/**
		 *  需要支持一个特性 类似 .a的能力  如果目标端没有这个页面 那么可以用对等端的页面打开
		 *        params.compatMode = true
		 */
		if(params.compatMode){
			if(!window.microService.allMenus){
				let {data} = await this.request({
					url:"/entry/manager/authorized/menus"
				});
				window.microService.allMenus = data;
			}
			let uri = new URI(params.url);
			let pathName = uri.pathname();
			let item = dfsFind(window.microService.allMenus,(item)=>{
				return item.originUrl == pathName || item.originUrl == pathName + ".w";
			});
			if(item && item.types){
				if(this.deviceType == "mx" && item.types.indexOf('pcx') !=-1 && item.types.indexOf('mobile') ==-1 ){
					//移动端 打开的功能 有pcx 没有移动的场景
					const deviceRegex = /(.*)(mobile)app\/\1(\2|comp)/g;
					params.url = params.url.replace(deviceRegex,function(all,prefix,deviceName){
						return `${prefix}pcxapp/${prefix}pcx`
					});
				}
			}
		}

		this._enableMessageChannel(params);
		return params.impl?params.impl(params):wx.navigateTo(params).then(res=>{
			return {
				...res,
				postMessage:(message={})=>{
					message.source = this.route;
					let page = findPageByRoute(params.url);
					page.postMessage(message);
				}
			}
		});
	}

	navigateBack(object = {}){
		//hcr 兼容api打开对话框模式场景
		if(this.props?._openByNavigateApi == true){
			if(this.page?.pageType == "uix"){
				console.warn("当前api已经过时 请切换新版本api");
			}
			object.currentPage = this.ownerPage || this;
			object.currentModel = this;
			if (this.$pageOptions){
				object.$onClose = this.$pageOptions.onClose;
			}
			return wx.navigateBack(object);
		}

		//返回前 支持发送最后一个消息
		object.message && this.getOpener().postMessage(object.message);
		//pageFrame时只需要把消息返回 不进行路由处理
		if (this.ownerPage){
			this.getOpener().postMessage({action:"close"});
		}else{
			object.currentPage = this.ownerPage || this;
			object.currentModel = this;
			return wx.navigateBack(object);
		}
	}

	/**
	 *
 	 * 嵌入其他页面到当前页面target dom内  完整生命周期  完整消息通道
	 */
	async compose(params,target){
		//支持页面本身内嵌的pageFrame的数据来源onInitState的数据
		await this.loadDeferred.promise;
		//支持先open 后传递params的场景 所以compose要延迟执行
		return await debounceNextViewTickRun(()=>{
			return this._compose(params,target);
		},target,this);
	}

	async _compose(params,target){
		params.currentPage = this.ownerPage || this;
		params.currentModel = this;

		this._formatPageUrl(params);

		params.openerRoute = "$render";

		//传递页面的执行者
		if (!params.executor){
			params.url = wx.ServiceUtil.addExecutor(params.url, this.params.executor);
		}
		let disposeMessageChannel = this._enableMessageChannel(params);
		let page = await wx.uixPageRender(params,target);
		page.emitter.on('onUnload',()=>{
			disposeMessageChannel?.();
		});
		return page;
	}



    _enableMessageChannel(params){
		addOpenerRoute(params,this.route);
		if (params.onMessage){
			if(params.url && this.emitter['messageHandler']?.[params.url]){
				this.emitter.off('onMessage', this.emitter['messageHandler']?.[params.url]);
			}
			let handler = (message)=>{
				if(message && message.source == params.url){
					params.onMessage.call(this,message);
				}
			};
			this.emitter['messageHandler'] = this.emitter['messageHandler'] || {};
			this.emitter['messageHandler'][params.url] = handler;
			this.emitter.on('onMessage', handler);
			return ()=>{
				this.emitter.off('onMessage', handler);
			}
		}
	}

	/**
	 *
	 规范写法
	 $UI/pcx/a.w
	 为了兼容
	 $UI/pcx/a
	 navigatewrapper 加.w
	 toResUrl中因为需要.w(处理后去掉.w)

	 /entry/pcx/a.w
	 this.getUIUrl 定位为 获取静态资源url 返回带协议路径
	 this.navigateTo 定位为页面跳转。兼容tab场景和dialog
	 2022.4.13 slm
	 调整为
	 打开页面 统一为
	 this.navigateTo()

	 url
	 规范写法
	 $UI/pcx/a.w
	 后续支持小程序合并（如果需要）
	 *
	 */
	_formatPageUrl(params){
    	let url = params.url;
		if (url.indexOf("$UI/") == "0"){
			url = url.substring(3);
			if(this.serviceName){
				url = "/" + this.serviceName + "/" + this.contextName + url;
			}else{
				url = "/" + this.contextName + url;
			}
		}else if(url.indexOf("$ServiceName/") == "0"){
			//跨端场景
			url = url.substring(12);
			if(this.serviceName){
				url = "/" + this.serviceName + url;
			}
		}

		if(url.indexOf("http")!=0){
			//去掉.w后缀
			let uri = new URI(url);
			uri.pathname(uri.pathname().replace(".w",""));
			url = uri.toString();
		}
		params.url = url;
	}
	/**
	 * mx端页面跨端运行 需要打开pcx中的对话框需要获得pcx的对话框路径
	 * @param url
	 * @returns {string}
	 */
	toPcxUrl(url){
		if (url.indexOf("$UI/") == "0" && this.deviceType == "mx" && window.isPcx == true){
			url = url.substring(3);
			if(this.serviceName){
				url = "/" + this.serviceName + "/pcxapp" + url;
			}else{
				url = "/pcxapp"+ url;
			}
		}
		return url;
	}
}

let mixin = Eventable.prototype;
["clearListener","clearListeners","fireEvent","hasListener","off","on","un"].forEach(function(name){
	Page.prototype[name] = mixin[name];
});
Page.isEventable = true;


Page.TOPTIPS_NAME = "__toptips__";
