import TableData from "../tableData/tableData";
import Data from "../tableData/data";
import {ObservableMap, observable, extendObservable, autorun, toJS, isObservableArray, isObservableObject, isObservable, transaction } from  "../../lib/mobx/mobx-2.6.2.umd";
import {parsePath} from "../../lib/base/pageImpl";
import _String from "../../lib/base/string";
import {isArray, isObject} from "../../lib/base/util";
import _Date from "../../lib/base/date";
import UUID from "../../lib/base/uuid";

import ServiceUtil from "../../lib/base/serviceUtil";
function isNumeric(obj){
	return !isNaN( parseFloat(obj) ) && isFinite( obj )
}

class Filter{
	constructor(){
		this.filterList = new ObservableMap();
		this.variables =  new ObservableMap();
	}
	
	getFilterList(){
		return toJS(this.filterList);
	}
	
	getFilterVariables(){
		return toJS(this.variables);
	}
	
	clear() {
		this.filterList.clear();
		this.clearVars();
	}

	clearVars() {
		this.variables.clear();
	}

	getVar(name) {
		return toJS(this.variables.get("__"+name));
	}

	setVar(name, val) {
		this.variables.set("__"+name, val);
	}

	setFilter(name, filter) {
		this.filterList.set(name, filter);
	}

	getFilter(name) {
		return toJS(this.filterList.get(name));
	}

	deleteFilter(name) {
		this.filterList.delete(name);
	}
}


var fns = {};
var names = ['eq', 'neq', 'eqb', 'neqb', 'gt', 'lt', 'gte', 'lte', 'like', 'ilike', 'nlike', 'nilike', 'is', 'ninn', 'inn', 'not', 'isNull', 'isNotNull', 'LBRAC', 'RBRAC'];
for (let i=0; i<names.length; i++){
	let name = names[i];
	fns[name] = function(relation, value, kind, $$dataObj){
		if(('isNull'===name || 'isNotNull'===name) && false===value) return "";//增加isNull，isNotNull动态生效条件
		//兼容只传入三个参数模式
		if(kind instanceof RestData){
			$$dataObj = kind;
			kind = '';
		}
		if('LBRAC'===name){//小括号支持
			return ((typeof(kind)==='string' && 'or'===kind.toLowerCase())?'or':'') + "(";
		}else if('RBRAC'===name) return ")";
		//当操作符不是is时，忽略value为undefined，null，NaN的条件
		if(value===undefined || value===null || value==="" || (typeof(value)==='number' && isNaN(value))){
			if('eqb'!==name && 'neqb'!==name && 'is'!==name && 'isNull'!==name && 'isNotNull'!==name && 'LBRAC'!==name && 'RBRAC'!==name) return "";
			else value = (value!==""?null:"");
		}
		
		if ($$dataObj && $$dataObj.getColumnDef){
			var def = $$dataObj.getColumnDef(relation);
			if (def && def.define && (def.define != relation)){
				relation = def.define;
			}
			//like时不需要进行转换
			if(!(name=='like'||name=='ilike'||name=='nlike'||name=='nilike')){
				//date，time，dateTime
				if(def && def.type && value && 'string'===typeof(value)){
					var type = def.type.toLowerCase();
					//if(type==='date') value = _Date.fromString(value,_Date.STANDART_FORMAT_SHOT);
					//else 
					if(type==='time') value = _Date.fromString(value,_Date.STANDART_TIME_FORMAT);
					else if(type==='datetime') value = _Date.fromString(value,_Date.STANDART_FORMAT);
				}
			}
			if(def && def.type && value instanceof Date){
				if(def.type.toLowerCase()==='date') value = _Date.toString(value,_Date.STANDART_FORMAT_SHOT);
			}
			if (value instanceof Date){
				value = value.toISOString();
			}
			if($$dataObj.join){//有join时才使用表名
				var table = (def && (def.tableAlias || def.table)) || $$dataObj.tableName;
				if(table && !relation.startsWith("$")) relation = table+"."+relation;
			}
			//eqb,neqb情况下非string类型''统一使用null
			if(('eqb'===name || 'neqb'===name) && value===""){
				if(def && def.type && 'string'!==def.type.toLowerCase()){
					value=null;						
				}
			}				
		}
		let fnName = name;
		if(name=='like'||name=='ilike'||name=='nlike'||name=='nilike'){
			if(value){
				if((value+'').indexOf('*')<0) value = '*' + value + '*';
			}else value = '*';
			if(name=='nlike') fnName = 'not.like';
			else if(name=='nilike') fnName = 'not.ilike';
		}else if(name=='isNull'){
			value = null;
			fnName = 'is';
		}else if(name=='isNotNull'){
			value = null;
			fnName = 'not.is';
		}else if(name=='ninn'){
			fnName = 'not.in';
		}else if(name=='inn'){
			fnName = 'in';
		}else if(name=='eqb'){
			fnName = 'eq';
		}else if(name=='neqb'){
			fnName = 'neq';
		}
		value = isArray(value)?value.join(','):value;
		if (value){
			value = encodeURIComponent(value);
		}
		return ((typeof(kind)==='string' && 'or'===kind.toLowerCase())?'or.':'')+relation + "=" + ('inn'!==fnName?fnName:'in') + "." + value;
	}
}

const netErrorTxt = "网络不给力哦～";

export default class RestData extends TableData {
	constructor(page, id, props, context){
		super(page, id, props, context);
		this._treeFilter = new ObservableMap();
	}
	
	getClass(){
		return RestData;		
	}
	
	getTreeFilterOB(){
		return this._treeFilter;
	}
	
	hasTreeFilter(){
		let vals = this._treeFilter.values();
		for (let i=0; i<vals.length; i++){
			if (vals[i]){
				return true;
			}
		}
		return false;
	}
	
	setTreeFilter(key, filter){
		try{
			let val = JSON.stringify(filter);
			if (val=="[]" || val=="{}"){
				filter = null;
			}
		}catch(e){
			
		}
		if (key){
			this._treeFilter.set(key, filter);
		}
	}
	
	getTreeFilter(key){
		if (key){
			return this._treeFilter.get(key);
		}
	}
	
	clearTreeFilter(){
		this._treeFilter.clear();
	}

	init(){
		this.filters = new Filter();
		this.directDeleteMode = true;  //默认直接删除
		this.filterFns = fns;
		this.useHeaderOffset = false;
		super.init();
	}
	
	setTableName(tableName){
		let oldTableName = this.tableName;
		this.tableName = tableName || "";
	   	 var coldefs = this.getColumnDefs();
		 for (let k in coldefs){
			var colDef = coldefs[k];
			if(colDef && colDef['tableAlias']==oldTableName){
				colDef['tableAlias']=tableName;
			}
		 }
		 if(isArray(this.join)){
			for(let i=0,len=this.join.length;i<len;i++){
				let join = this.join[i];
				if(join['leftTable']==oldTableName){
					join['leftTable']=tableName;
				}
			}
		 }
	}
	
	_initDefinition() {
		this.className = this.props.options.className || "";
		if(this.className[0]!=='/') this.className = "/"+this.className;
		this.restResource = this.props.options.restResource || "table";
		this.tableName = this.props.options.tableName || "";
		this.serviceName = this.props.options.serviceName;
		this.microServiceName = this.props.options.microServiceName;
		this.url = this.props.options.url || "/dbrest";
		this.isReference = this.props.options.isReference;
		this.displayProps = this.props.options.displayProps;
		this.multiplePrimary = this.props.options.multiplePrimary;
		this.autoMode = this.props.options.autoMode;
		this.distinct = this.props.options.distinct;
		this.join = this.props.options.join;
		this.subquery = this.props.options.subquery;
		if(isArray(this.subquery)){
			for(let subquery of this.subquery.values()){
				this['$subquery_'+subquery] = '$subquery:'+subquery;
			}
		}
		super._initDefinition();
		this._initJoin();
	}
	
	setDistinct(v){
		this.distinct = v;
	}
	
	getDistinct(){
		return this.distinct;
	}

	_initJoin(){
		// join表别名在设计时生成，删除自动删除的序号逻辑
	}
	
    _initData(force,append){
		super._initData(force,append);
    }
	
	buildUrl(params,noHost) {
		var serviceName = this.getServiceName();
		var url = (serviceName?("/"+serviceName+this.url):this.url);
		!this.isReference && (url = url + "/" + this.buildResourceName());
		if (params) {
			url += "?" + this.buildParam(params);
		}
		!noHost && (url = this.page.getServiceUrl(url, this.microServiceName));
		return url;
	}
	processHeader(headers) {
		var restRes = this.restResource;
		if(!restRes) {
			restRes = "table";
		}
		if("class" == restRes) {
			headers["dbrest-resource"] = restRes;
		}else if("table" == restRes) {
			headers["dbrest-resource"] = restRes;
		} else {
			throw _String.format(this.page.i18n("不支持的 restResource 配置: {0}"), restRes);
		}
	}
	buildResourceName() {
		var restRes = this.restResource;
		if(!restRes) {
			restRes = "table";
		}
		if("class" == restRes) {
			var arr = this.className.split("/");
			return arr[arr.length-1];
		}else if("table" == restRes) {
			return this.tableName;
		} else {
			throw _String.format(this.page.i18n("不支持的 restResource 配置: {0}"), restRes);
		}
	}
	buildParam(params){
		var ret = "";
		if (params) {
			if (isArray(params)) {
				if(params.length>0){
					for (var i = 0; i < params.length; i++) {
						var item = params[i];
						ret += ((i === 0 ? "" : "&") + item);
					}
				}
			} else {
				ret += params;
			}
		}
		return ret;
	}
	
	getRequestInfo(usePaginationInfo = false){
		var self = this;
		let serviceName = this.getServiceName();
		let url = (serviceName?("/"+serviceName+this.url):this.url);
		!this.isReference && (url = url + "/" + this.tableName);
		let servicePath = url;
		url = ServiceUtil.getServiceUrl(url, this.microServiceName || this.page.serviceName);  //不带parentPath

		let queryParam = this._createRefreshParam(0, -1, false, {});
		if(usePaginationInfo){
			queryParam = this._createRefreshParam(this.getOffset(), this.getLimit(), false, {});
		}
		let _params = this._getQueryParam(queryParam) || [];
		let queryStr = this.buildParam(_params);
		let params = {};
		queryStr.split("&").forEach((param)=>{
			if(param){
				let [key,value]  = param.split("=");
				value = decodeURIComponent(value);
				
				if(params[key] !== null && params[key] !== undefined){
					if((typeof(params[key])==='object' && !isNaN(params[key].length))){
						params[key].push(value);
					}else{
						params[key] = [params[key],value];
					}
				}else{
					params[key] =  value;
				}
				
			}
		});
		var headers = {};
		self.processHeader(headers);
		/*
		 * url中不带parentPath， 兼容保留(有parentPath时不可用)
		 * path不带parentPath, 带serviceName
		 * servicePath不带serviceName
		 */
		let path = url.replace(/^(https?:\/\/)([0-9a-z-.]+)(:[0-9]+)?/,""); 
		
		let result = {
			url: url, 
			path: path,
			serviceName: this.microServiceName || this.page.serviceName,
			servicePath: servicePath,
			params:  params,
			headers: headers
		}
		return result;
	}
	
	getFilter(name) {
		return this.filters.getFilter(name);
	}
	
	setFilter(name, filter) {
		return this.filters.setFilter(name, filter);
	}	
	
	_hasIDParam(){
		if(!this.multiplePrimary){
			let paramName = this.className+".id";
			return this.page.params.hasOwnProperty(paramName);					
		}else{
			let idColumns = this.primaryColumns;
			for(let i=0,len=idColumns.length;i<len;i++){
				let paramName = this.className+"."+idColumns[i];
				if(!this.page.params.hasOwnProperty(paramName)) return false;					
			}
			return true;
		}		
	}
	
	_getIDParam(name){
		var paramName = this.className+"."+(name||"id");
		return  this.page.params[paramName];					
	}
	
	// 重新实现orderBy
	getOrderBys() {
		var ret = [];
		var items = this.getOderBysObj();
		for ( var i=0;i<items.length;i++) {
			var o = items[i];
			ret.push('"'+o.name + '".' + ((0 === o.type || '0' === o.type) ? 'desc' : 'asc'));
		}
		return ret.length>0?"order="+encodeURIComponent(ret.join(",")):null;
	}
	
	isRequestError(res){
		if (res.hasOwnProperty("statusCode")){
			return !((res.statusCode >= 200) && (res.statusCode < 300));
		}else{
			return res.data && res.data.hasOwnProperty("exception");
		}
	}
	
	doDeleteBySaveData(){
		let allPromise = [];
		if (!this.directDeleteMode) {//删除标记数据提交
			let rows = this.deleteDatas;
			if(rows.length>0){
				let deletePromise = this._createPromise({});
				let deleteRet = this.doDirectDeleteData(rows, {callback: (state, options, eventData)=>{
					if(state){
						this._clsDeleteDatas();
						deletePromise.res();
					}else{
						deletePromise.rej(eventData);
					}
				}});
				if (!deleteRet.async){
					deletePromise.res();
				}
			}
		}
		//进行从表的删除行处理
		let len = this.slaveDatas.length;
		if(len>0){
			for(let i=0;i<len;i++){
				let slaveData = this.slaveDatas[i];
				allPromise.push(slaveData.doDeleteBySaveData());
			}
		}
		let retPromise = this._createPromise({});
		Promise.all(allPromise).then(()=>{
			retPromise.res();
		}, (err)=>{
			retPromise.rej(err);
		});
		return retPromise;
	}
	
	//保存逻辑
	doSaveData(options) {
		let result = false;
		options = options || {};
		let saveAllData = !!options.allData;
		let self = this;

		let headers = {
				'content-type': 'application/json',
				'SAVE-METHOD' : 'PUT',
				"X-SERVICE" : this.getService()
			};
		self.processHeader(headers);	
		let param = [];
		let propVerLock = this.getVersionLock();
		if(propVerLock) param.push(_String.format("{0}={1}", '$versionLock', encodeURIComponent(propVerLock.define || propVerLock.name)));
		let idColumn = this.getPK();
		
		if(saveAllData){//全数据保存,包括新增和修改的数据
			param.push(_String.format("{0}={1}", 'pk', encodeURIComponent(idColumn)));
			let rowsData = [];
			this.eachAll((p)=>{
				let state = p.row.getState();
				if (Data.STATE.NEW == state || Data.STATE.EDIT == state || this.isSlaveChanged(p.row)){
					let r = this.processSaveRow(p.row);
					r && rowsData.push(r);
				}
			});
			let savePromise = this._createPromise({});
			if(rowsData.length>0){
				self.page.request({
					url: this.buildUrl(param),
					batch: options.batch,
					header: headers,
					dataType: 'json',
					method: 'PUT',
					data: rowsData
				}).then((res)=>{
					if (self.isRequestError(res)){
						savePromise.rej(res);
						//self._saveError(res, options);
					}else{
						savePromise.res();
						//self._saveSuccess(null, options);	
					}
				}, (err)=>{
					if(!self.doErrorStatus509(err, function(result){
						
						savePromise.res();
					})){
						savePromise.rej(err);
						
					}
				});
			}else{
				savePromise.res();
			}
			//进行非立即删除处理
			let deletePromise = this.doDeleteBySaveData();
			Promise.all([savePromise, deletePromise]).then(() => {
				self._saveSuccess(null, options);	
			}, (err) => {
				self._saveError(err, options);
			});
			result = true;
		}else{
			let row = options.row || options.item || options.value || this.getCurrentItem();
			if (row) {
				let state = row.getState();
				if (Data.STATE.NEW == state || Data.STATE.EDIT == state || this.isSlaveChanged(row)) {
					let isNew = (Data.STATE.NEW === state);
					let method = isNew ? "POST" : "PUT";
					let postData = this.processSaveRow(row);
					if(postData){
						if(!isNew){
							param.push(_String.format("{0}={1}", 'pk', encodeURIComponent(idColumn)));
							postData = [postData];
						}
						this.page.request({
							url: this.buildUrl(param),
							batch: options.batch,
							header: headers,
							dataType: 'json',
							method: method,
							data: postData
						}).then((res)=>{
							if (self.isRequestError(res)){
								self._saveError(res, options);
							}else{
								self._saveSuccess({saveRows:[row]}, options);	
							}
						}, (err)=>{
							if(!self.doErrorStatus509(err, function(result){})){
								self._saveError(err, options);
							}
						});
						result = true;
					}
				}
			}
		}

		return {
			success : result,
			async : true
		};
	}
	
	isCalculateCol(col) {
		if (col) {
			if ('string' === typeof (col))
				col = this.getColumnDef(col);
			if ('object' === typeof (col)) {
				return "EXPRESS" === col.define || "STATISTICAL" == col.define;
			}
		}
	}
	
	processSaveRow(row, ignoreSlave, allCol){
		let state = row.getState();
		let idCol = this.getIdColumn();
		let id = row[idCol]; //需要从row中获取主键, 因为row2json后的主键值可以类型不对, 比如长数字类型
		let ret = this._processSaveRow(this.row2json(row), id, ignoreSlave, allCol, state);
		//lzg 特殊逻辑，主从保存时需有主承载从数据导致主数据被提交修改，需修改状态刺激版本号更新
		if (ret && Data.STATE.NEW != state && Data.STATE.EDIT != state){
			row.setState(Data.STATE.EDIT);
		}
		return ret;
	}
	
	_processSaveRow(row, id, ignoreSlave, allCol, rowState){
		if(row){
			//转换成真实的
			delete row['_recoredState'];
			for(var col in row){
				var defCol = this.getColumnDef(col);
				
				//特殊处理时间类型,后续去除
				if(defCol && defCol.type=='datetime' && row[col]){
					let d = new Date(row[col]);
					row[col] = (new Date(d.getFullYear(),d.getMonth(),d.getDate(),d.getHours(),d.getMinutes(),d.getSeconds())).toISOString();
				}				
				
				if(this.isCalculateCol(defCol)){
					!allCol && (delete row[col]);
				}else if(defCol && ((defCol.table && defCol.table!==this.tableName)||defCol.joinColumn)){
					!allCol && (delete row[col]);
				}else if(defCol && defCol.define && col!=defCol.define && !defCol.joinColumn){
					row[defCol.define] = row[col];
					delete row[col];
				}
			}
			if(!ignoreSlave){
				//增加从数据处理
				var slaveRows = this.processSlaveSaveRow(row,id);
				if(isArray(slaveRows)){
					if(slaveRows.length>0){
						for(let i=0; i<slaveRows.length; i++){
							let v = slaveRows[i];
							let k = v.key+(v.$versionLock?("@$versionLock="+v.$versionLock):"");
							if(!isArray(row[k])){
								row[k] = v.rows; 
							}else if(v.rows && v.rows.length>0){
								let idsMap = {};
								for (let r of row[k].values()) {
									idsMap[r[v.idColumn]] = true;
								}
								for(let i=0,len=v.rows.length;i<len;i++){
									let r = v.rows[i];
									if(!idsMap[r[v.idColumn]]){
										row[k].push(r);
									}else{
										throw new Error(this.page.i18n("多个相同从数据中有相同提交记录"));
									}
								}
							}
						}
					}else if(Data.STATE.NEW != rowState && Data.STATE.EDIT != rowState){
						//自身没有修改，同时也不存在从数据修改，忽略保存
						return;
					}
				}
			}
		}
		return row;
	}
	
	processSlaveSaveRow(row,rowid){
		var len = this.slaveDatas.length;
		if(len>0){
			var ret = [];
			for ( let i = 0; i < len; i++) {
				var slaveData = this.slaveDatas[i];
				if (slaveData){
					let idCol = slaveData.getIdColumn();
					let defColID = slaveData.getColumnDef(idCol);
					let idColumn = (defColID.define||idCol);
					let key = slaveData.tableName + '.' + idColumn;
					let rows = [];
					let versionLock = null;
					let propVerLock = slaveData.getVersionLock();
					if(propVerLock) versionLock = propVerLock.define || propVerLock.name;
					
					let mcol = slaveData.master.relation;
					
					//从数据是树形时，需要使用each的方式遍历
					slaveData.eachAll((p)=>{
						let r = p.row;
						let state = r.getState();
						if (rowid===r[mcol] && (Data.STATE.NEW == state || Data.STATE.EDIT == state || slaveData.isSlaveChanged(r))){
							let rr = slaveData.processSaveRow(r);
							rr && rows.push(rr);
						}
					});
					/*
					let values = slaveData.getAllValue();
					for (let j=0,len=values.length; j<len; j++){
						let r = values[j];
						let state = r.getState();
						if (rowid===r[mcol] && (Data.STATE.NEW == state || Data.STATE.EDIT == state || slaveData.isSlaveChanged(r))){
							let rr = slaveData.processSaveRow(r);
							rr && rows.push(rr);
						}
					}
					*/
					
					if(rows.length>0) ret.push({key: key, idColumn: idColumn, $versionLock:versionLock, rows: rows});
				}						
			}
			return ret;
		}
	}
	
	/*TODO
	function add(row, parent, disableCursor, nopush){
		//处理真正的物理映射
		for ( var k in this.defCols) {
			var field = this.defCols[k].define;
			if(!row.hasOwnProperty(k) && field) row[k] = row[field];
		}
		return this.callParent(row, parent, disableCursor, nopush);
	}
	*/
	
	_saveSuccess(data, options) {
		var eventData = {
			source : this,
			data : data
		};
		this.doSaveAfter(true, options, eventData);
	}
	
	 _getErrMsg(err) {
		 return this._getPgsqlErrMsg(err) || this._getMysqlErrMsg(err) 
		 	|| (err && err.data && err.data.exception)
		 	|| (err && err.data && err.data.message);
	  }
	 
	 _getPgsqlErrMsg(err){
			let msg = err && err.data && err.data.exception;
			const str1 = "duplicate key value";
			const str2 = ")=(";
			const str3 = ") already exists.";
			const str4 = "value too long for type";
			const str5 = "For input string: ";
			if (msg) {
				let ipos;
				if(msg.indexOf(str1)>-1){
					ipos = msg.indexOf(str2);
					if(ipos>-1) return msg.substring(ipos + str2.length).replace(str3, this.page.i18n("是已经存在的值"));
				}else if(msg.indexOf(str4)>-1){
					return this.page.i18n("数据长度超出范围")+msg;
				}else if(msg.indexOf(str5)>-1){
					ipos = msg.indexOf(str5);
					if(ipos>-1) return msg.substring(ipos + str5.length)+ this.page.i18n("值无法存储，可能数据长度超出范围");
				}
			}
	 }
	 
	 _getMysqlErrMsg(err){
			let msg = err && err.data && err.data.exception;
			const list = [{str1:"Duplicate entry ",str2:" for key",info: this.page.i18n("是已经存在的值")},
			              {str1:"Data truncation: Data too long for column ",str2:" at row",info: this.page.i18n("数据长度超出范围")},
			              {str1:"Data truncation: Out of range value for column ",str2:" at row",info: this.page.i18n("数据长度超出范围")}];

			if (msg) {
				for(let i=0;i<list.length;i++){
					let item = list[i];
					if(msg.indexOf(item.str1)===0){
						let ipos = msg.indexOf(item.str2);
						if(ipos>-1) return msg.substring(item.str1.length, ipos) + item.info;
					}
				}
			}
	 }
	 
	 _showError(info, msg){
		 let str = info.statusCode!=412?this._getErrMsg(info): this.page.i18n("版本锁冲突");
		 this.showModalError({message: msg + (str?(","+str):"")});
	 }
	 
	  
	_saveError(info, options) {
		var b = this.hasListener(Data.EVENT_SAVEDATA_ERROR);
		if (!b) {
			this._showError(info, this.page.i18n("保存数据失败"));
		}
		var eventData = {
				'errorType' : 'server',
				'info' : info,
				'source' : this
		};
		this.doSaveAfter(false, options, eventData);
	}
	
	buildDeleteParam(options){
		let ret = "";
		//mode=master: 表示删除主表当前行关联的从表
		if (options.mode=="master"){
			if (this.master && this.master.masterData && this.master.masterData.getCurrentRowID()){
				let masterRowId = this.master.masterData.getCurrentRowID();
				let def = this.getColumnDef(this.master.relation);
				let masterRelationName = (def && def.define)||this.master.relation;
				if(this.hasJoinCol()){//有join时才使用表名
					let table = (def && def.tableAlias) || (def && def.table) || this.tableName;
					if(table) masterRelationName = table+"."+masterRelationName;
				}
				ret = _String.format("{0}=eq.{1}", masterRelationName, masterRowId||"");
			}
		}
		return ret;
	}
	
	deleteAllData(options){
		var self = this;
		options = options || {};
		var retPromise = this._createPromise(options);
		var headers = {"X-SERVICE":this.getService()};
		let url = this.buildUrl(this.buildDeleteParam(options));
		self.processHeader(headers);
		var fn = () => {
			var self = this;
			self.page.request({
				method: "DELETE",
				batch: options.batch,
				url: url,
				header: headers,
				dataType: "json"
			}).then((res)=>{
				if (self.isRequestError(res)){
					//this.showModalError({message: "删除全部数据失败"}); //将来使用参数或属性来控制是否显示错误信息
					this._showError(res, this.page.i18n("删除全部数据失败"));
					options.onError();
				}else{
					transaction(()=>{
						if (self.master && this.master.masterData && (options.mode=="master")){
							let items = [];
							for (let i=0; i<self.value.length; i++){
								items.push(self.value[i]);
							}
							self.removeData(items);
						}else{
							self.clear();
						}
						options.onSuccess();
					});
				}
			}, (err)=>{
				//this.showModalError({message: "网络不给你哦～"}); //将来使用参数或属性来控制是否显示错误信息
				this._showError(res, this.page.i18n("删除全部数据失败"));
				options.onError();
			});
		};

		var confirmDelete = this.confirmDelete;
		if (options && options.hasOwnProperty("confirm")){
			confirmDelete = options.confirm;
		}
		if (confirmDelete){
			var confirmDeleteText = options.confirmText||this.confirmDeleteText;
			this._confirm(confirmDeleteText, fn, null);
		}else{
			fn();
		}
		 
		return retPromise;
	}

	doErrorStatus509(res, callback){
		if(res && 509==res.statusCode){
			if('function'===typeof(callback) && res.data){
				if(this.hasListener(Data.EVENT_SAVEDATA_EXCEPTION)){
					var eventData = {
							'errorType' : 'server',
							'errorNode' : res,
							'httpState' : res.statusCode,
							'source' : this
					};
					this.fireEvent(Data.EVENT_SAVEDATA_EXCEPTION, eventData);
				}else{
					this.showModalError({message: res.data.message|| this.page.i18n("删除异常")});
				}
				callback(res.data);
			}
			return true;
		}
	}

	//删除逻辑
	doDirectDeleteData(rows, options) {
		
		var self = this;
		let delRows = [];
		if (rows && rows.length>0){
			let i,len = rows.length;
			for (i = 0; i < len; i++) {
				let row = rows[i];
				if (Data.STATE.NEW != row.getState()) {
					delRows.push(row);
				}
			}
		}

		let doDeleteAfterFn = options.callback;
		let param = delRows.length>0?this.getDeleteParam(delRows):null;
		if (param) {
			let self = this;
			let result = true;
			let headers = {"X-SERVICE":this.getService()};
			let type = "DELETE";
			let url = self.buildUrl(param);
			let data = {};
			
			if(url && url.length>2048){
				headers['X-Method'] = "DELETE";
				type = "POST";
				headers['Content-Type'] = "application/json;charset=utf-8";
				data = JSON.stringify({query: self.buildParam(param)});
				url = self.buildUrl();
			}
			self.processHeader(headers);
			self.page.request({
				method: type,
				batch: options.batch,
				url: url,
				data: data,
				header: headers,
				dataType: "json"
			}).then((res)=>{
				if (self.isRequestError(res)){
					self._deleteError(res, rows, options);
					if (doDeleteAfterFn) doDeleteAfterFn(false, options, res);
				}else{
					let eventData = {
						'source' : self,
						'deleteValue' : rows
					};
					!doDeleteAfterFn?self.doDeleteAfter(true, options, eventData):doDeleteAfterFn(true, options, eventData);
				}
			}, (err)=>{
				if(!self.doErrorStatus509(err, function(result){
					var failedPKValues = (result && result.pkValues && result.pkValues[self.tableName] && result.pkValues[self.tableName].failedPKValues)||[];
					var eventData = {
							'source' : self,
							'deleteValue' : rows
						};
					//处理已经删除的行
					if(failedPKValues && failedPKValues.length>0){
						eventData.deleteValue = [];
						for(let i=0,len=rows.length; i<len; i++){
							let row = rows[i];
							let rowid = row[self.getIdColumn()];
							(failedPKValues.indexOf(rowid)<0) && eventData.deleteValue.push(row);
						}
					}
					self.doDeleteAfter(true, options, eventData);
					!doDeleteAfterFn?self.doDeleteAfter(true, options, eventData):doDeleteAfterFn(true, options, eventData);
				})){
					self._deleteError(err, rows, options, self.page.i18n(netErrorTxt));
					if (doDeleteAfterFn) doDeleteAfterFn(false, options, err);
				}
			});
			return {success : result, async : true};
		}else{
			return {success : true,	async : false};	
		}		
	}
	
	_deleteError(response, rows, options, info){
		var onError = options ? options.onError : null;
		var eventData = {
			'errorType' : 'server',
			'deleteValue': rows,
			'response': response,
			'source' : this
		};
		var b = this.hasListener(Data.EVENT_DELETEDATA_ERROR);
		if (!b) {
			this._showError(response, this.page.i18n("删除数据失败"));
		}
		this.doDeleteAfter(false, options, eventData);
	}
	
	getDeleteCascade(){
		let cascade = [];
		//增加级联删除从表处理,cascade=主表名称.主表主键名称.子表名称.子表外键名称多个使用','风格
		//目前支持两层主从级联删除
		let idColName = this.getPK();
		let len = this.defSlaves.length;
		if(len>0){
			for (let i = 0; i < len; i++) {
				let slaveData = this.defSlaves[i];
				if (slaveData && slaveData.table && slaveData.field){
					let masterTable = slaveData.masterTable;
					let masterIdColName = slaveData.masterIdColName;
					if(!masterTable){
						masterTable = this.tableName;
						masterIdColName = idColName;
					}
					masterTable && masterIdColName &&
						cascade.push(masterTable + '.' +masterIdColName + '.' + slaveData.table + '.' + slaveData.field);
				}
			}
		}
		len = this.slaveDatas.length;
		if(len>0){
			for (let i = 0; i < len; i++) {
				let slaveData = this.slaveDatas[i];
				if (slaveData){
					var slaveCascade = slaveData.getDeleteCascade();
					if(slaveCascade && slaveCascade.length>0){
						cascade.push.apply(cascade, slaveCascade);
					}
				}
			}
		}
		
		return cascade;
	}
	
	getDeleteParam(rows) {
		let self = this;
		if (!rows)
			return;
		let ids = [];
		let i,len = rows.length;
		for (i = 0; i < len; i++) {
			let row = rows[i];
			if (Data.STATE.NEW != row.getState()) {				
				if(!this.multiplePrimary){
					ids.push(this.getRowID(row));
				}else{
					let delFilters = [];
					let idColumns = this.primaryColumns;
					for(let j=0,jlen=idColumns.length;j<jlen;j++){
						let idColumn = idColumns[j];
						let defcol = this.getColumnDef(idColumn);
						let idColName = (defcol && defcol.define) || idColumn;
						delFilters.push(_String.format("{0}=eq.{1}",idColName, encodeURIComponent(row[idColumn])));
					}
					ids.push(i!=0?"or(":"(")
					ids.push(delFilters.join('&'));
					ids.push(")")
				}
			}
		}


		let ret = [];
		//没有要删除数据时不返回任何参数
		if(ids.length > 0){
			//版本锁
			let propVerLock = this.getVersionLock();
			if(propVerLock) ret.push(_String.format("{0}={1}", '$versionLock', encodeURIComponent(propVerLock.define || propVerLock.name)));
			if(!this.multiplePrimary){
				let idCol = this.getIdColumn();
				let defColID = this.getColumnDef(idCol);
				let idColName = (defColID && defColID.define) || idCol;

				
				ret.push(idColName + "=in." + encodeURIComponent(ids.join(",")));
				
				if("table" == self.restResource) {
					let cascade = this.getDeleteCascade();
					if(cascade.length > 0) ret.push("cascade="+ encodeURIComponent(cascade.join(",")));
				}
				//传递pk
				ret.push(_String.format("{0}={1}", 'pk', encodeURIComponent(idColName)));
			}else{
				ret.push("(");
				ret.push(ids.join("&"));
				ret.push(")");				
			}
		}

		return ret.length>0?ret.join("&"):null;
	}
	

	//刷新逻辑
	doRefreshData(options) {
		this.open = true;
		let parentRow = options ? options.parentRow : null;
		let offset = this.getOffset(parentRow);
		let limit = this.getLimit(parentRow);
		if (options){
			if (options.hasOwnProperty("offset")) offset = options.offset;
			if (options.hasOwnProperty("limit")) limit = options.limit;
		}
		return this._doRefreshData(offset, limit, options);
	}
	
	hasJoinCol(){
		if(isArray(this.join) && this.join.length>0){
			for(var i=0;i<this.join.length;i++){
				var columns = this.join[i].columns;
				if(isArray(columns) && columns.length>0) return true;
			}
		}
		return false;
	}

	getSelectColumns() {
		if (this.isReference && this.displayProps) {
			return undefined; // this.displayProps;
		}
		if(!this.hasJoinCol()){
			//if(this.props.options.isAllColumns)return '*';
			//else{
			{//全部使用as模式，解决字段大小写问题
				let result = [];
				let items = this.getColumnDefs();
				for ( let o in items) {
					var define = items[o].define;
					if(define && define.startsWith("$")) {
						result.push(define);
					}
					else if(!this.isCalculateCol(o)){
						let field = items[o].define;
						if(field==o) result.push(field);
						else result.push(_String.format("{0} as \"{1}\"", field, o));
					}
				}
				return result.join(this.delim);
			}
		}else{
			let result = [];
			//if(this.props.options.isAllColumns) result.push(this.tableName+".*");
			let items = this.getColumnDefs();
			for ( let o in items) {
				let tableName = items[o].tableAlias || items[o].table;
				let field = items[o].define;
				if(field && field.startsWith("$")) {
					if(tableName) {
						result.push(_String.format("{0}.{1} as \"{2}\"", tableName, field, o));
					}else {
						result.push(field);
					}
				}
				else if(!this.isCalculateCol(o)){
					let tableName = items[o].tableAlias || items[o].table;
					//if(this.props.options.isAllColumns && !tableName) continue;
					//if(!tableName) continue;//全部使用as模式，解决字段大小写问题
					tableName = tableName || this.tableName;
					
					result.push(_String.format("{0}.{1} as \"{2}\"", tableName, field, o));
				}
			}
			return result.join(this.delim);
		}
	}
	
  	canSmartLoad(){
  		let smartLoad = super.canSmartLoad();
  		if(!smartLoad){
  			//进行restData判断
  			smartLoad = this.smartKey===this.oldSmartKey;
  		}
  		return smartLoad;
  	}

  	getService(){
  		let servicePath = this.className||"";
  		let ipos = servicePath.lastIndexOf("/");
  		if(ipos>0) return servicePath.substring("/"===servicePath[0]?1:0,ipos);
  		else return "";	
  	}
  	
  	getServiceName(){
  		if(!this.isReference){
  			if(this.serviceName){
  				return this.serviceName;
  			}
  			let servicePath = this.className||"";
  			let ls = servicePath.split('/');
  			if(ls.length>1) return ls[ls.length-2];
  			else return "";	
  		}
  	}
  	
	getSubquery(){
		if(this.subquery && this.subquery.length>0){
			let page = this.page;
			if(page){
				let ret = {};
				for(let subqID of this.subquery.values()){
					let comp = page.comp(subqID);
					if(comp && comp instanceof RestData){
						let queryParam = comp._createRefreshParam(0, -1, false, {});
						if (queryParam){
							let params = comp._getQueryParam(queryParam);
							let url = comp.buildUrl(params, true);
							ret[subqID] = url;
						}
					}
				}
				return wx.Base64.encode(JSON.stringify(ret));
			}
		} 
	}
	
	
	_isLoadAllTreeData(offset, limit, options){
		options = options || {};
		//如果有_treeFilter时表示不是全量加载
		if (this.isVirtualTree()){
			let treeFilter = toJS(this._treeFilter);
			for (let name in treeFilter){
				if (treeFilter.hasOwnProperty(name) && treeFilter[name]){
					return false;
				}
			}
		}
		
		var treeOption = this.getTreeOption();
		if (this.isTree() && treeOption && treeOption.loadAll && !options.parentRow) return true;
		/* -1不在认为是加载全部，使用loadAll表示
		if (this.isTree() && (limit == -1) && (offset==0) && !options.parentRow 
				&& treeOption && !!treeOption.parent 
				&& (!treeOption.rootFilter || JSON.stringify(treeOption.rootFilter)==="{}")){
			return true;
		}else{
			return false;
		}
		*/
	}
	

	_doRefreshData(offset, limit, options) {
		var self = this;
		//restData 频繁刷新控制，5秒内刷新连续10次抛出异常
		let now = new Date();
		let _refreshFlag = this._refreshFlag || (this._refreshFlag={})
		if(_refreshFlag._refreshTimestamp && _refreshFlag.offset==offset  && _refreshFlag.limit==limit && (now.getTime()-_refreshFlag._refreshTimestamp.getTime())<(5*1000)){
			_refreshFlag._refreshCount++;
			if(_refreshFlag._refreshCount>1000)
				throw new Error(this.page.i18n("中断当前刷新操作，原因：过于频繁刷新数据，确认代码是否存在刷新死循环"));
		}else{
			_refreshFlag._refreshTimestamp = now;
			_refreshFlag._refreshCount = 1;
			_refreshFlag.offset = offset;
			_refreshFlag.limit = limit;
		}
		
		var onError = null, onSuccess = null, onLoad;
		if (options) {
			if ("function"===typeof options)
				onSuccess = options;
			else {
				onError = options.onError;
				onSuccess = options.onSuccess;
				onLoad = options.onLoad;
			}
		}

		var queryParam = this._createRefreshParam(offset, limit, options ? options.append : false, options);
		if (!queryParam)
			return false;

		var params = this._getQueryParam(queryParam);
		var headers = {"X-SERVICE":this.getService()};
		self.processHeader(headers);
		if (isNumeric(queryParam.limit) && queryParam.limit > 0) {
			if(this.useHeaderOffset){
				headers['Range-Unit'] = 'items';
				headers['Range'] = queryParam.offset + "-" + (queryParam.offset + queryParam.limit - 1);
			}
		}

		if(this.page.pageType == "uix"){
			if (this.getCalcTotal()){
				headers['x-calc-total'] = this.getCalcTotal();
			}	
		}else{
			headers['x-calc-total'] = "exact";
		}
		

		var type = "GET";
		var url = this.buildUrl(params);
		if ((options||{}).cache && (url === this._cacheKey)){
			//如果cache模式且和最后一次的请求url一样，直接返回
			return {
				success : true,
				promise: Promise.resolve(),
				async : true,
				cancelEvent: true
			};
		}
		
		this._cacheKey = url;
		
		var data = {};
		if(url && url.length>2048){
			headers['X-Method'] = "GET";
			headers['content-type'] = 'application/json;charset=utf-8';
			type = "POST";
			data = JSON.stringify({query: this.buildParam(params)});
			url = this.buildUrl();
		}
		var self = this;
		let refreshPromise = self.page.request({
			method: type,
			dataType: "json",
			header: headers,
			url: url,
			data: data
		}).then((res)=>{
			transaction(()=>{
				if (self.isRequestError(res)){
					self._refreshError(res, onError, options);	
					return;
				}
				var treeOption = self.getTreeOption();
				var data = res.data || [];
				self._parseRange(res, offset, limit, options);
				
				
				if ("function" === typeof onLoad){
					onLoad({source: self, rows: data});
				}
				let loadOptions = {append: options ? options.append : false, parent: options ? options.parent : null};
				if (self.isTree() && !options.parentRow){
					if (self.hasTreeFilter()){
						loadOptions.buildVirtualNode = true;
						loadOptions.buildTreeData = true;
					}else{
						if (self._isLoadAllTreeData(offset, limit, options)){
							loadOptions.buildTreeData = true;
							loadOptions.loadAll = true;
						}
						
						if (options.buildTreeData) loadOptions.buildTreeData = true;
						if (options.loadAll) loadOptions.loadAll = true;
						if (options.buildVirtualNode) loadOptions.buildVirtualNode = true;
					}
				}
				self.loadData(data, loadOptions);
				if (self.isTree() && !options.parentRow && self.hasTreeFilter()){
					//修正treeFilter且没有父时的total
					self.setTotal(self.value.length);
				}
				self.oldSmartKey = self.smartKey;
				var eventData = {
					source : self,
					rows : data
				};				
				self.doRefreshAfter(true, options, eventData);
			});
		}, (res)=>{
			self._refreshError(res, onError, options, self.page.i18n(netErrorTxt));
		});
		
		//默认查询首页数据时查询统计数据
		if(0===offset && this.defAggCols){
			this.refreshAggregateValue(options ? options.parentRow : null);
		}
		return {
			success : true,
			promise:refreshPromise,
			async : true,
			cancelEvent: true
		};
	}
	
	_getQueryAggregateParam(queryParam) {
		var ret = [];
		if(queryParam.aggColumns) ret.push("select="+ encodeURIComponent(queryParam.aggColumns));
		if(isArray(queryParam.filter))
			for(let i in queryParam.filter){
				let filter = queryParam.filter[i];
				ret.push(filter);
			}
		return ret.length>0?ret:null; 
	}

	refreshAggregateValue(parentRow){
		var queryParam = this._createRefreshParam(0, 20, false, {parentRow:parentRow});
		if (!queryParam)
			return false;
		var params = this._getQueryAggregateParam(queryParam);
		if(!params) return;
		var self = this;
		var headers = {"X-SERVICE":self.getService()};
		self.processHeader(headers);
		var ret = new Promise(function(resolve, reject){
			self.page.request({
				type : "GET",
				dataType : "json",
				header : headers,
				url : self.buildUrl(params)
			}).then((res)=>{
				if (self.isRequestError(res)){
					self._refreshError(res, null, {});	
					reject(res);
					return;
				}
				var items = res.data || [];
				var o = isArray(items)?(items.length>0?items[0]:{}):items,fn;
				for ( var c in self.defAggCols) {
					fn = self.defAggCols[c];
					if(o.hasOwnProperty(fn))
						self._aggOB[c] = o[fn];
				}
				resolve(res);
			}, (res)=>{
				self._refreshError(res, null, {}, self.page.i18n(netErrorTxt));
				reject(res);
			});
		});
		//防止Uncaught (in promise)异常
		ret.catch(()=>{});
		
		return ret;
	}

	_refreshError(response, onError, options, info){
		var eventData = {
			'errorType' : 'server',
			'response' : response,
			'source' : this
		};
		var b = this.hasListener(Data.EVENT_REFRESHDATA_ERROR);
		if (!b) {
			this._showError(response, this.page.i18n("数据加载失败"));
			//this.showError({message:info||"数据加载失败"});
		}
		this.doRefreshAfter(false, options, eventData);				
	}
	
	_getJoinOnField(field, expr){
		if(expr){
			let v = this.page[expr] && this.page[expr](this.context.vars);
			if(v && typeof(v)=="string" && v.startsWith("$subquery:")) {
				return v;
			}else {
				return "{{{"+((v!==undefined&&v!==null)?encodeURIComponent(v):"")+"}}}";
			}
		}
		return field;
	}
	
	buildTreeSearchRootFilter(rootFilter){
		let ret = null;
		let emptyFilter = this.getIdColumn() + "=is.null";
		if (rootFilter && rootFilter.length>0){
			let queryParams = this._createTreeRootRefreshParam(rootFilter);
			if (!queryParams){
				ret = emptyFilter;
			}else{
				var params = this._getQueryParam(queryParams);
				var headers = {"X-SERVICE":this.getService()};
				this.processHeader(headers);
				var type = "GET";
				var url = this.buildUrl(params);
				var data = "";
				if(url && url.length>2048){
					headers['X-Method'] = "GET";
					headers['content-type'] = 'application/json;charset=utf-8';
					type = "POST";
			        data = JSON.stringify({query: this.buildParam(params)});
					url = this.buildUrl();
				}
				let xhr = this.createXhr();
				xhr.open(type, url, false);
				for (let key in headers){
					if (headers.hasOwnProperty(key)){
						xhr.setRequestHeader(key, headers[key]);
					}
				}
				xhr.send((type=="GET")?null:data);
				
				if ((xhr.status >= 200) && (xhr.status < 300)){
					try{
						let items = JSON.parse(xhr.responseText) || [];
						let hasRecordCount = items.length && undefined!==items[0].recordCount;
						items = hasRecordCount?items.splice(1):items;
						if (items && items.length>0){
							ret = [];
							let fullProName = this.getTreeOption().fullId;
							for (let i=0; i<items.length; i++){
								let fid = items[i][fullProName] || "";
								fid = encodeURIComponent(fid);
								ret.push("&or." + fullProName + "=like." + fid + "*");
							}
							ret = "(" + ret.join("") + "&)";
						}else{
							ret = emptyFilter;
						}
					}catch(e){
						console.error("status: " + xhr.status + ", responseText: "+ xhr.responseText);
						throw new Error(this.page.i18n("查询组件的根节点出错"));
					}
				}else{
					console.error("status: " + xhr.status + ", responseText: "+ xhr.responseText);
					throw new Error(this.page.i18n("查询组件的根节点出错"));
				}
			}
		}
		return ret;
	}
	
	createXhr(){
    	try {	
			return new XMLHttpRequest();
		}catch (tryMS) {	
			var version = ["MSXML2.XMLHttp.6.0",
			               "MSXML2.XMLHttp.3.0",
			               "MSXML2.XMLHttp",
			               "Miscrosoft.XMLHTTP"];
			for(var i = 0; i < version.length; i++){
				try{
			    	return new ActiveXObject(version[i]);
				}catch(e){}
			}
		}
		throw new Error(this.page.i18n("您的系统或浏览器不支持XHR对象"));
	}
	
	_createTreeRootRefreshParam(rootFilter) {
		//备份parentFilter和masterFilter
		let parentFilter = this.getFilter("_sys_parent_filter_");
		let masterFilter = this.getFilter("_sys_master_id_filter_");
		
		var self = this;
		var queryParam = {};
		// 主从过滤处理
		if (this.master) {
			//TODO 主从数据查询处理
			var masterData = this.master.masterData, masterRow = masterData && masterData.getCurrentRow();
			if (!masterRow){
				this.setFilter("_sys_parent_filter_", parentFilter); //还原
				this.setFilter("_sys_master_id_filter_", masterFilter); //还原
				console.warn('从数据查询时缺少主数据');
				return false;
			}
			if (masterData) {
				let masterRowId = masterData.getRowID(masterRow);
				/*主表没有数据时，从表过虑条件不对的问题
				if (!masterRowId){
					console.warn('从数据查询时缺少主数据');
					return false;
				}
				*/
				let def = this.getColumnDef(this.master.relation);
				let masterRelationName = (def && def.define)||this.master.relation;
				if(this.hasJoinCol()){//有join时才使用表名
					let table = (def && def.tableAlias) || (def && def.table) || this.tableName;
					if(table) masterRelationName = table+"."+masterRelationName;
				}
				this.setFilter('_sys_master_id_filter_',_String.format("{0}=eq.{1}",masterRelationName,masterRowId||""));
			}
		}
		
		this.setFilter("_sys_parent_filter_",rootFilter);

		var filter = this.buildFilter();
		if (filter)
			queryParam.filter = filter;
		queryParam.limit = -1;
		queryParam.offset = 0 ;

		//查询全部列时不传递列
		queryParam.columns = this.getTreeOption().fullId;
		
		//生成join
		if(this.join && this.join.length>0){
			let joins = [];
			for(let join of this.join.values()){
				let leftTable = join['leftTable'];
				let rightTable = join['rightTable'];
				let rightTableAlias = join['rightTableAlias'];
				let joinType = join['type'] || "left";
				let joinOns = [];
				if(join.on && join.on.length>0){
					let j=0;
					for (let joinOn of join.on.values()){
						let fn = joinOn['fn'];
						let or = (j>0 && joinOn['or']) || false;
						if(fn=='(' || fn==')'){
							joinOns.push((or?'or':'')+fn);
						}else{
							var leftField = this._getJoinOnField(joinOn.leftField,joinOn.leftValue);
							var rightField = this._getJoinOnField(joinOn.rightField,joinOn.rightValue);
							var tmpFn = fn;
							if(fn==="isNull") {
								tmpFn="is";
								rightField="null";
							}
							if(fn==="isNotNull") {
								tmpFn="isnot";
								rightField="null";
							}
							joinOns.push(_String.format("{0}{1}.{2}.{3}", or?'or.':'', leftField, tmpFn, rightField));
						}
						j++;
					}
				}
				joins.push(_String.format("{0}.{1}.{2}[{3}]", leftTable,joinType,rightTable+(rightTableAlias?('|'+rightTableAlias):''),joinOns.join(':')));
			}
			queryParam.join = joins.join();
		}

		//处理子查询
		let subq = this.getSubquery();
		if(subq){
			queryParam.$subquery = subq;
		}
		
		this.setFilter("_sys_parent_filter_", parentFilter); //还原
		this.setFilter("_sys_master_id_filter_", masterFilter); //还原
		return queryParam;
	}	
	
	/**
		如果有treeSearchFilter, 基于rootFitler查出fid后与treeSearchFilter合并成根过滤;
		如果没有treeSearchFilter, 直接返回rootFitler作为根过滤
	**/
	buildRootFilter(){
		let rootFilter = null;
		let treeOption = this.getTreeOption()||{};
		if (treeOption.rootFilter){
			let filters = this.buildFilter(treeOption.rootFilter);
			if (filters){
				rootFilter = filters;
			}
		}

		let treeSearchFilter = [];
		if (this.isVirtualTree()){
			let treeFilter = toJS(this._treeFilter);
			for (let key in treeFilter){
				if (treeFilter.hasOwnProperty(key) && treeFilter[key]){
					treeSearchFilter.push(treeFilter[key]);
				}
			}
		}
		
		let ret = null;
		if (treeSearchFilter.length > 0){
			ret = treeSearchFilter;
			var treeSearchRootFilter = this.buildTreeSearchRootFilter(rootFilter);
			if (treeSearchRootFilter){
				ret.push(treeSearchRootFilter);
			}
			ret = this.buildFilter(ret);
		}else{
			ret = rootFilter;
		}
		if (ret && ret.length>0){
			return ret;
		}else{
			return null;
		}
	}
	
	
	_createRefreshParam(offset, limit, append, options) {
		var self = this;
		var queryParam = {};
		// 主从过滤处理
		if (this.master) {
			//TODO 主从数据查询处理
			var masterData = this.master.masterData, masterRow = masterData && masterData.getCurrentRow();
			/*
			if (!masterRow){
				console.warn('从数据查询时缺少主数据');
				return false;
			}
			*/
			if (masterData) {
				let masterRowId = masterRow ? masterData.getRowID(masterRow) : "";
				/*主表没有数据时，从表过虑条件不对的问题
				if (!masterRowId){
					console.warn('从数据查询时缺少主数据');
					return false;
				}
				*/
				let def = this.getColumnDef(this.master.relation);
				let masterRelationName = (def && def.define)||this.master.relation;
				if(this.hasJoinCol()){//有join时才使用表名
					let table = (def && def.tableAlias) || (def && def.table) || this.tableName;
					if(table) masterRelationName = table+"."+masterRelationName;
				}
				if (masterRowId==="" || masterRowId===null || masterRowId===undefined){
					//从表引用主表的主键不会为空
					this.setFilter('_sys_master_id_filter_',_String.format("{0}=is.null",masterRelationName));
				}else{
					this.setFilter('_sys_master_id_filter_',_String.format("{0}=eq.{1}",masterRelationName,masterRowId||""));
				}
				
			}
		}
		
		//增加id自动过滤
		if(this._hasIDParam()){					
			//this.setFilter("_sys_id_filter_", "eq('" + this.getIdColumn() + "', '" + this._getIDParam() + "')");
			let idColumns = this.primaryColumns;
			for(let i=0,len=idColumns.length;i<len;i++){
				let idCol = idColumns[i];
				let defColID = this.getColumnDef(idCol);
				let idColName = (defColID && defColID.define) || idCol;
				if(this.hasJoinCol()){//有join时才使用表名
					let table = (defColID && defColID.tableAlias) || (defColID && defColID.table) || this.tableName;
					if(table) idColName = table+"."+idColName;
				}
				this.setFilter("_sys_"+idCol+"_filter_", idColName + "=eq." + this._getIDParam(!this.multiplePrimary?"":idCol));
			}
		}else	if (this.isTree()) {
			let treeOption = this.getTreeOption();
			if (this._isLoadAllTreeData(offset, limit, options)){
				//树形以-1的方式加载根数据时，表示加载全量数据, 不添加过滤条件
				this.setFilter("_sys_parent_filter_", "");
			}else{
				// 树形数据处理
				let parentId = null;
				if (options.parentRow) {
    				if (treeOption.parentIdColumn){
    					//支持树形数据指定父列指向的值不是idColumn
    					parentId = options.parentRow[treeOption.parentIdColumn];
    				}else{
    					parentId = this.getRowID(options.parentRow);
    				}
    			}
				//解决树的根过虑条件有join时没有加别名的问题
				//var treeFilter = _String.format("{0}={1}.{2}", parentRelation, parentId!==null?"eq":"is", encodeURIComponent(parentId));
				var treeFilter = {op: parentId!==null?"eq":"isNull", name: treeOption.parent, value: parentId};
				if (!options.parentRow && parentId===null){
					let rootFilter = this.buildRootFilter();
					if (rootFilter){
						treeFilter = rootFilter;
					}
				}
				this.setFilter("_sys_parent_filter_",treeFilter);
			}
		}
		
		if (this.isTree() && (options.FetchParent || options.fetchParent) && !options.parentRow){
			//树形支持查找指定节点所有父节点的直接子
			//强制修改options, 表示对结果数据需要进行处理
			options.buildTreeData = true;
			options.buildVirtualNode = options.buildVirtualNode !==false;
			queryParam.FetchParent = true;
			queryParam.subNode = !!options.subNode;
			let rootFilter = this.getFilter("_sys_parent_filter_");
			if (rootFilter){
				rootFilter = typeof rootFilter == "string" ? rootFilter : this.buildFilter(rootFilter);
				queryParam.rootFilter = rootFilter;
			}
			let oldRootFilter = this.getFilter("_sys_parent_filter_");
			this.setFilter("_sys_parent_filter_", "");  //清空rootfilter, 只需要一般的filter
			var filter = this.buildFilter();
			if (filter)
				queryParam.filter = filter;
			this.setFilter("_sys_parent_filter_", oldRootFilter); //还原rootfilter
			queryParam.limit = -1;
			queryParam.offset = 0 ;
		}else{
			var filter = this.buildFilter();
			if (filter)
				queryParam.filter = filter;
			
			queryParam.offset = offset;
			queryParam.limit = limit;
			var feature = self.props.options.feature;
			if(feature && !feature.paging) {
				queryParam.limit = -1;
				queryParam.offset = 0 ;
			}
		}
		

		


		//查询全部列时不传递列
		queryParam.columns = this.getSelectColumns();
		
		//生成join
		if(this.join && this.join.length>0){
			let joins = [];
			for(let join of this.join.values()){
				let leftTable = join['leftTable'];
				let rightTable = join['rightTable'];
				let rightTableAlias = join['rightTableAlias'];
				let joinType = join['type'] || "left";
				let joinOns = [];
				if(join.on && join.on.length>0){
					let j=0;
					for (let joinOn of join.on.values()){
						let fn = joinOn['fn'];
						let or = (j>0 && joinOn['or']) || false;
						if(fn=='(' || fn==')'){
							joinOns.push((or?'or':'')+fn);
						}else{
							var leftField = this._getJoinOnField(joinOn.leftField,joinOn.leftValue);
							var rightField = this._getJoinOnField(joinOn.rightField,joinOn.rightValue);
							var tmpFn = fn;
							if(fn==="isNull") {
								tmpFn="is";
								rightField="null";
							}
							if(fn==="isNotNull") {
								tmpFn="isnot";
								rightField="null";
							}
							joinOns.push(_String.format("{0}{1}.{2}.{3}", or?'or.':'', leftField, tmpFn, rightField));
						}
						j++;
					}
				}
				joins.push(_String.format("{0}.{1}.{2}[{3}]", leftTable,joinType,rightTable+(rightTableAlias?('|'+rightTableAlias):''),joinOns.join(':')));
			}
			queryParam.join = joins.join();
		}

		//处理子查询
		let subq = this.getSubquery();
		if(subq){
			queryParam.$subquery = subq;
		}
		
		//生成统计列
		if(0===offset && this.defAggCols){
			var temps = [];
			for ( var c in this.defAggCols) {
				temps.push(this.defAggCols[c]);
			}
			queryParam.aggColumns = temps.join();
		}

		var o = this.getOrderBys();
		if (o)
			queryParam.orderBy = o;

		return queryParam;
	}
	
	getFilterOB(){
		return this.filters.filterList;
	}
	
	getFilterVariablesOB(){
		return this.filters.variables;
	}
	
	buildFilter(filters) {
		var ret = [];
		var filterList = filters || this.filters.getFilterList();
		var variables = this.filters.getFilterVariables();
		for ( var o in filterList) {
			var filter = filterList[o];
			if (!filter) continue;
			var s = this._processFilter(filter,variables);
			if(isArray(s)) ret = ret.concat(s);
		}
		this.processFilterBrac(ret);
		return ret.length>0?ret:null;
	}
	
	processFilterBrac(filters){
		//进行filter中空括号处理
		let bContinue = false;
		for(let i=filters.length-1;i>=0;i--){
			let f = filters[i];
			if(')'===f){
				let j = i-1;
				if(j>=0 && ('('===filters[j] || 'or('===filters[j])){
					i--;
					bContinue = true;
					filters.splice(i,2);
				}
			}
		}
		if(bContinue) this.processFilterBrac(filters);//递归处理到所有括号全部完成
		if(filters.length>0 && 'or('===filters[0]) filters[0]='('; //第一个条件不能是or(
	}
	
	evalFilterObj(filter, variables){
		var result = null;
		if (filter.op && this.filterFns[filter.op]){
			result = this.filterFns[filter.op](filter.name, filter.value, filter.kind, this);
		}
		return result;
	}
	
	___processFilter(filter, variables){
		let result;
		var vars = Object.assign({}, this.context.vars,  {$page: this.page}, {params: this.page.params}, this.filterFns);
		vars.$$dataObj = this; //添加data对象自身
		//slm 支持函数方式的过滤条件 返回标准filter结构
		if (typeof filter === 'function'){
			filter = filter(vars);
		}
		if (typeof this.page[filter] === 'function'){
			result = this.page[filter](vars);
		}else if(isArray(filter) && filter.length>0){
			let ret = [];
			for(let i=0,len=filter.length;i<len;i++){
				let f = this.___processFilter(filter[i], variables);
				f && ret.push(f);
			}
			result = ret;
        }else if (typeof(filter) === "object" && filter.constructor.name === "DbrestFilter"){
            return filter.getUrlString();
		}else if (wx.Util.isObject(filter)){
			result = this.evalFilterObj(filter, vars);
		}else if(typeof(filter)==='string')
			result = filter;
		return result;
	}
		
	_processFilter(f, variables){
		let filter = this.___processFilter(f, variables);
		if (filter==null || filter==undefined || filter===""){
			return [];
		}else if (isArray(filter)){
			return filter;
		}else{
			return [filter];
		}
	}	

	_getQueryParam(queryParam) {
		var ret = [];
		if(!this.useHeaderOffset){
			if('number' == typeof(queryParam.limit)) ret.push("limit="+queryParam.limit);
			if('number' == typeof(queryParam.offset)) ret.push("offset="+queryParam.offset);
		}
		
		if(this.distinct) ret.push("distinct=true");
		if (this.isTree() && queryParam.FetchParent){
			ret.push("$treeProcessor=FetchParent");
			ret.push("$treeSubNode=" + !!queryParam.subNode);
			if (queryParam.rootFilter){
				let rootFilter = [];
				if(isArray(queryParam.rootFilter)){
					for (let i=0; i<queryParam.rootFilter.length; i++){
						rootFilter.push(queryParam.rootFilter[i]);
					}
				}else{
					rootFilter.push(queryParam.rootFilter);
				}
				rootFilter = this.buildParam(rootFilter);
				if (rootFilter){
					ret.push("$treeRootFilter=" + encodeURIComponent(rootFilter));
				}
			}
		}
		if(queryParam.columns) ret.push("select="+ encodeURIComponent(queryParam.columns));
		if(queryParam.join) ret.push("join="+ encodeURIComponent(queryParam.join));
		if(queryParam.orderBy) ret.push(queryParam.orderBy);
		if(isArray(queryParam.filter)){
			for (let i=0; i<queryParam.filter.length; i++){
				ret.push(queryParam.filter[i]);
			}
		}
		
		if(queryParam.$subquery) ret.push("$subquery="+queryParam.$subquery);
		this.smartKey = ret.length>0?ret.join("##"):null;
		return ret.length>0?ret:null; 
	}
	
	//解决主从保存时，从表的版本号没有加1的问题
    applyUpdates(data,options){
    	 // 更新版本字段和状态不触发事件和状态变化
		 this.disableRecordChange();
		 try {
			 if(data && data.data && data.data.saveRows && data.data.saveRows.length>0){
				 var self = this;
				 for(let i=0,len=data.data.saveRows.length;i<len;i++){
					 let row = data.data.saveRows[i];
					 row && this.resetState(row);
				 };
			 }else{
				 options = options || {};
				 var parent = options.parent || this._allvalue;
				 this.resetState(parent);
			     let len = this.slaveDatas.length;
				 if(len>0){
					for(let i=0;i<len;i++){
						let slaveData = this.slaveDatas[i];
						slaveData.applyUpdates(data, options);
					}
				}
				 
			 }
		} finally {
			this._dataChangedUUID.set(new UUID().toString());
			this.enabledRecordChange();
		}
	 }
	
	
    initOperation(){
   	 	super.initOperation();
   	 	this.defineOperation('setTreeFilter',{
			label : this.page.i18n('设置树过滤'),
			argsDef : [ {
				name : 'filters',
				displayName : '过滤'
			},{
				name : 'name',
				displayName : '过滤名称'
			} ],
			method : function(args) {
				var filters = args.filters,s='';
				/*和微信小程序格式统一
				 * [{name:列,value:值,op:比较操作符,kind:and/or}]
				*/
				//如果args.name为空，使用随机生成的name, 保存上层每次setFilter都起使用
				let uuid = new UUID().toString();
				uuid = uuid.replace(/-/g,'').toUpperCase();
				var name = args.name || ('__op_tree_filter__'+ uuid);
				return this.owner.setTreeFilter(name, (isArray(filters) && filters.length>0)?filters:null);
			}
		});
   	 	this.defineOperation("clearTreeFilter",{
			label : this.page.i18n('清除所有树过滤'),
			method : function(args) {
				return this.owner.clearTreeFilter();
			}
		});
		
		
   	 	this.defineOperation('setFilter',{
			label : this.page.i18n('设置过滤'),
			argsDef : [ {
				name : 'filters',
				displayName : '过滤'
			},{
				name : 'name',
				displayName : '过滤名称'
			} ],
			method : function(args) {
				var filters = args.filters,s='';
				/*和微信小程序格式统一
				 * [{name:列,value:值,op:比较操作符,kind:and/or}]
				*/
				//如果args.name为空，使用随机生成的name, 保存上层每次setFilter都起使用
				let uuid = new UUID().toString();
				uuid = uuid.replace(/-/g,'').toUpperCase();
				var name = args.name || ('__op_filter__'+ uuid);
				return this.owner.setFilter(name, (isArray(filters) && filters.length>0)?filters:null);
			}
		});
   	 	this.defineOperation("clearFilter",{
			label : this.page.i18n('清除所有过滤'),
			method : function(args) {
				return this.owner.filters.clear();
			}
		});
   	 	this.defineOperation("setOrderBy",{
			label : this.page.i18n('设置排序'),
			argsDef : [ {
				name : 'col',
				displayName : '列'
			},{
				name : 'type',
				displayName : '排序方式'
			} ],
			method : function(args) {
				if(args.col)
					return this.owner.setOrderBy(args.col,args.type==='desc'?0:(args.type==='asc'?1:null));
			}
		});
   	 	this.defineOperation("clearOrderBy",{
			label : this.page.i18n('清除所有排序'),
			method : function(args) {
				return this.owner.clearOrderBy();
			}
		});
    }	
}

RestData.Filter = Filter;
RestData.fns = fns;
RestData.getSelectByAlias = function(select, alias) {
	if (select.indexOf(" as ") < 0) {
		return alias;
	}
	var aliasArr = alias.split(",");
	var selectArr = select.split(",");
	var retArr = [];
	var getSelectField = function(arr, alias) {
		const sub = '"' + alias + '"';
		for (var i=0; i<arr.length; i++) {
			if (arr[i].indexOf(sub) > 0) {
				return arr[i];
			}
		}
		return alias;
	};
	for (var i=0; i<aliasArr.length; i++) {
		retArr.push(getSelectField(selectArr, aliasArr[i]));
	}
	return retArr.join(",");
};
RestData.getConditionFieldByAlias = function(select, alias) {
	if (select.indexOf(" as ") < 0) {
		return alias;
	}
	var aliasArr = alias.split(",");
	var selectArr = select.split(",");
	var retArr = [];
	var getConditionField = function(arr, alias) {
		const sub = '"' + alias + '"';
		for (var i=0; i<arr.length; i++) {
			if (arr[i].indexOf(sub) > 0) {
				return arr[i].split(" as ")[0];
			}
		}
		return alias;
	};
	for (var i=0; i<aliasArr.length; i++) {
		retArr.push(getConditionField(selectArr, aliasArr[i]));
	}
	return retArr.join(",");
};

wx.comp = wx.comp || {};
wx.comp.RestData = RestData
