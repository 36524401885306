import {omitBy, forEach, merge, isEqual} from 'lodash';
import moment from 'moment';
import RestData from '../restData/restData';

export default class FilterData extends RestData {
    static getInstance({refData}) {
        let key = `${refData.id}__filterData`;
        if (!refData[key]) {
            refData[key] = refData.clone({
                class: FilterData,
                id: key,
                buildConfig: (config) => {

                    /*config.schema.items.props = omitBy(config.schema.items.props,(_,key)=>{
                        return key != columnName && key != idColumn;
                    });*/


                    config.schema.items.props['_rowKey'] = {
                        "define": "_rowKey",
                        "label": "唯一标识",
                        "type": "string",
                        "extType": "String"
                    }
                    forEach(config.schema.items.props, (value, key) => {
                        value.type = "string";
                        value.extType = "String";
                    });
                    config.options.primaryColumns = ["_rowKey"];
                    config.options.idColumn = "_rowKey";
                    config.options.treeOption = {};
                    config.options.refData = refData;
                }
            });
            refData[key].setCheckIdMode?.('error');
        }
        return refData[key];
    }


    init() {
        super.init();
        this.refData = this.props.options.refData;
        this.initDefaultRow()

    }

    constructor(page, id, props, context) {
        super(page, id, props, context);

    }

    setFilter(columnName, filterValue) {
        if (!this.refData.getFilter(`filterData_filter`)) {
            //if(this.refData.isTree()){
            //    this.refData.setTreeFilter(`filterData_filter`,this.buildDataFilter.bind(this));
            //}else {
            this.refData.setFilter(`filterData_filter`, this.buildDataFilter.bind(this));
            //}
        }
        if (filterValue) {
            //不能用对象展开 否则每次set都会造成mobx的json变化 刺激ui重绘  this._filterData.setValue(columnName,{...filterValue});
            //不采用mobx的属性直接修改的方案 这个方案 对react非常友好  对vue理论上也是友好的比较 但是我们useData兼容的是col的mobx变化。修改json内的值 col的值不会触发变化
            //最终采用比较的方案 如果有变化 设置新的json对象
            let filter = this.getValue(columnName);
            if (!isEqual(filter, filterValue)) {
                this.setValue(columnName, {...filterValue});
            }
        } else {
            this.setValue(columnName, null);
        }
    }

    buildOptionsDataFilter(columnName) {
        let optionsData = this.getColumnOptionsData(columnName);
        let {searchValue} = this.getFilter(columnName);
        let filters = [];
        if (searchValue) {
            filters.push({
                op: "ilike",
                value: searchValue,
                name: columnName
            });
        }
        return filters;
    }


    buildDataFilter() {
        let row = this.getCurrentRow().toJson();
        let idColumn = this.getIdColumn();
        let sysChildrenColumnName = '_sys_children_';
        let filters = [];
        row = omitBy(row, (value, key) => {
            if (key == idColumn) {
                return true;
            } else if (key == sysChildrenColumnName) {
                return true;
            } else if (!value) {
                return true;
            } else if (!this.isValidFilter(key)) {
                return true;
            }
            return false;
        })
        forEach(row, (value, key) => {
            let format = value.format;
            let values = value.values.map((item) => {
                if (format) {
                    return format.replace('%s', item);
                }
                return item;
            });

            if (value.op == "range") {
                let beginValue = values[0];
                let endValue = values[1];
                if (beginValue instanceof Date) {
                    beginValue = moment(beginValue).startOf("day").toDate();
                }

                if (endValue instanceof Date) {
                    endValue = moment(endValue).endOf("day").toDate();
                }

                filters.push({
                    op: "gte",
                    value: beginValue,
                    name: key
                });
                filters.push({
                    op: "lte",
                    value: endValue,
                    name: key
                });
            } else {
                filters.push({
                    op: value.op,
                    value: values.join(','),
                    name: key
                });
            }


        });
        return filters;
    }


    getFilter(columnName) {
        let filter = this.getValue(columnName) || {values: []};
        return {...filter};
    }

    updateFilter(columnName, newFilterValue) {
        let filter = this.getFilter(columnName);

        //修改操作方案
        if (!(newFilterValue.op == filter.op && newFilterValue.format == filter.format)) {
            filter.values = [];
            filter.searchValue = null;
        }

        merge(filter, newFilterValue);
        if (newFilterValue.values) {
            //修改值的场景 这个应该从ui管控 不合法的值不应能被选出来
            if (filter.valueType == "none" && newFilterValue.values.length != 0) {
                return false;
            } else if (filter.valueType == "single" && newFilterValue.values.length != 1) {
                return false;
            }/*else if(filter.valueType == "multiply" && newFilterValue.values.length != 2){
                return false;
            }*/
        } else if (newFilterValue.searchValue) {
            //任何情况都允许设置过滤值
        }

        this.setFilter(columnName, filter);
        return true;
    }

    reset(columnName) {
        if (columnName) {

            let columnDef = this.refData.getColumnDef(columnName);
            let defaultValue = {};
            let extType = columnDef.extType;
            if (extType == "DateTime") {
                defaultValue = {
                    key: "range",
                    op: "range",
                    values: []
                }
            } else if (extType == "Date") {
                defaultValue = {
                    key: "gte",
                    op: "gte",
                    values: []
                }
            } else if (extType == "Integer" || extType == "Long" || extType == "Float" || extType == "Decimal" || extType == "Double") {
                defaultValue = {
                    key: "eq",
                    op: "eq",
                    values: []
                }
            } else if (extType == "Image" || extType == "File") {
                defaultValue = {
                    key: "ilike",
                    op: "ilike",
                    values: []
                }
            } else {
                defaultValue = {
                    op: "like",
                    key: "like",
                    values: []
                }
            }
            this.setFilter(columnName, defaultValue);
            this.refData.refreshData();
            this.getColumnOptionsData(columnName).refreshData({cache: true});
        } else {
            let setting = this.getUserSetting();
            this.clear();
            this.initDefaultRow(setting);
        }
    }

    getUserSetting() {
        let userSetting = {}
        this.toJson().forEach(row => {
            for (let key in row) {
                if (row[key].userData) {
                    userSetting[key] = {...row[key].userData, userData: row[key].userData};
                }
            }
        });
        return userSetting;
    }

    initDefaultRow(setting = {}) {
        if (!this.getCurrentRowID()) {
            let defaultValues = {};
            forEach(this.refData.getColumnDefs(), (value, key) => {
                let extType = value.extType;
                if (extType == "DateTime") {
                    defaultValues[key] = {
                        key: "range",
                        op: "range",
                        values: []
                    }
                } else if (extType == "Date") {
                    defaultValues[key] = {
                        key: "gte",
                        op: "gte",
                        values: []
                    }
                } else if (extType == "Integer" || extType == "Long" || extType == "Float" || extType == "Decimal" || extType == "Double") {
                    defaultValues[key] = {
                        key: "eq",
                        op: "eq",
                        values: []
                    }
                } else if (extType == "Image" || extType == "File") {
                    defaultValues[key] = {
                        key: "ilike",
                        op: "ilike",
                        values: []
                    }
                } else {
                    defaultValues[key] = {
                        op: "like",
                        key: "like",
                        values: []
                    }
                }
                defaultValues[key] = {...defaultValues[key], ...setting[key]}
            });


            this.newData({
                defaultValues
            });
        }
    }

    apply() {
        this.refData.refreshData({FetchParent: true, buildVirtualNode: false});
    }

    applyOptionsDataSearch(columnName) {
        this.getColumnOptionsData(columnName).refreshData({cache: true});
    }

    isValidFilter(columnName) {
        let columnDefs = this.refData.getColumnDefs();
        let check = (filterInfo) => {
            let {op, values} = filterInfo;
            if (!op) {
                return false;
            }
            if (op == "eqb"
                || op == "neqb"
                || op == "isNull"
                || op == "isNotNull") {
                if (values.length > 0) {
                    return false;
                }
            } else {
                if (values.length == 0) {
                    return false;
                }
            }
            return true;
        }
        let result = true;
        forEach(columnDefs, (_, _columnName) => {
            if (columnName == _columnName) {
                let filterInfo = this.getFilter(_columnName);
                let checkResult = check(filterInfo);
                if (!checkResult) {
                    result = false;
                    return false;
                }
            }
        })
        return result;
    }


    // column options data 相关
    getColumnOptionsData(columnName) {
        let key = `${this.refData.id}__columnFilterData_${columnName}`;

        // TODO _requestInfo.params.select = RestData.getSelectByAlias(requestInfo.params.select, dataIndex);
        if (!this.refData[key]) {
            let __columnOptionsData = this.refData.clone({
                id: key,
                buildConfig: (config) => {
                    config.schema.items.props = omitBy(config.schema.items.props, (_, key) => {
                        return key != columnName;
                    });
                    config.options.distinct = true;
                    config.options.idColumn = columnName;
                    config.options.treeOption = {};
                }
            });

            __columnOptionsData.setFilter("__columnOptions_default", [{
                op: "isNotNull",
                name: columnName,
                value: null
            }])

            this.refData[key] = __columnOptionsData;

            __columnOptionsData.setFilter(`__columnFilter_optionsData_searchFilter`, () => {
                return this.buildOptionsDataFilter(columnName);
            })
        }

        return this.refData[key];
    }
}
