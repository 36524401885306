import URI from 'urijs';
import 'template/css/app.css';
import {createPath, parsePath} from 'history'
import 'template/css/default.css';
import 'template/css/index.css';
import 'core/passiveEvent';
import PullDownRefresh from 'components/pullDownRefresh/pullDownRefresh';
import 'core/disableChromePullToRefresh';
import 'core/keyboard';
import 'url-polyfill';
// pwa support
import 'core/error';
import * as offlineRuntime from 'offline-plugin/runtime';
import React, {cloneElement} from 'react';
import ReactDOM from 'react-dom';
import FastClick from 'fastclick';
import router from 'core/router';
import history from 'core/appHistory';
import 'core/api/wxApi';

import tabbar from 'core/tabbar';
import header from 'core/header';
import uniqueId from 'lodash/uniqueId';
import last from 'lodash/last';
import {animationEnd, isX5App, attachDoubleClickExitApp, addOpenerRoute} from 'core/utils';
import pageLoader from 'core/framework/loader';
import 'inobounce';
import detector from "detector";
import {_formatUrl} from "core/api/ui/navigate";

import ConfigContextProcess from 'core/framework/ConfigContextProcessor';
import PageContainer from 'core/container/PageContainer';
import PageComponent from "core/framework/PageComponent";
import LoadErrorPage from 'core/container/LoadErrorPage';
