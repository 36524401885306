import cx from 'classnames'
import React, {Component} from 'react'
import ReactDom from 'react-dom'
import  {getAppConfig,isTabbarPage,navigateHome,isX5App,isDingTalk,isStandalone,isWeixinBrowser,isIphonex,setAppTitle} from './utils';
import {showActionSheet} from './api/ui/actionSheet';
import history from '../core/appHistory';
import _ from 'lodash';

import detector from 'detector';
import URI from 'urijs';

let rootContainer = document.querySelector('#container');

class Header extends Component {
  constructor(props) {
    super(props)
    let appConfigOfWindow = getAppConfig('window');
    this.state = {
      backgroundColor: appConfigOfWindow.navigationBarBackgroundColor || "black",
      color: appConfigOfWindow.navigationBarTextStyle || "white",
      title: appConfigOfWindow.navigationBarTitleText,
      loading: false,
      launched:false,
      backText: '',
	    back: false,      sendText: false
    }
  }
  show(config) {
    const currentPage = _.last(getCurrentReactPages());
    if(!currentPage){
      return;
    }
    let tabBar = getAppConfig('tabBar');
    let top = tabBar && tabBar.position == 'top'

    let navigationStyle = getAppConfig('navigationStyle');
    navigationStyle = config.navigationStyle || navigationStyle ||  'default';

    let hide = top && this.tabbar.isTabPage(history.location.pathname);

    let navigationBarTitleText = currentPage?.wxPageDeclaration.title || currentPage.title || config.navigationBarTitleText || getAppConfig('appName');
    if(typeof navigationBarTitleText == "object"){
      navigationBarTitleText = "";
    }
    this.setState({
      hide,
      backgroundColor: config.navigationBarBackgroundColor  || "black",
      color: config.navigationBarTextStyle || "white",
      title: navigationBarTitleText,
      loading: false,
      backText: '',
      sendText: false,
      back: getCurrentReactPages().length > 1,
      navigationStyle:navigationStyle
    })
  }

  onBack(e) {
    if(window.microService.isMicroService){
      let currentPageElement = document.querySelector('#container>.current_page');
      if(currentPageElement && currentPageElement.classList.contains("single-spa")){
        window.history.go(-1);
        return;
      }
    }
    wx.navigateBack();
  }
  setTitle(title) {
    this.setState({title})
  }
  showLoading() {
    this.setState({
      loading: true
    })
  }
  hideLoading() {
    this.setState({
      loading: false
    })
  }
  backHome(){
	  navigateHome()
  }
  share(){
    const currentPage = _.last(getCurrentReactPages());
    const isFunc = Boolean(currentPage.wxPageDeclaration.onShareAppMessage && _.isFunction(currentPage.wxPageDeclaration.onShareAppMessage));
    let shareMsg = isFunc && currentPage.wxPageDeclaration.onShareAppMessage() || {};
    if(isX5App && navigator.weixin && navigator.weixin.share){
      let webpageUrl = new URL(location.href);
      webpageUrl.hash = "#" + shareMsg.path;
    	navigator.weixin.share({
        message : {
          title : shareMsg.title,
          description : "",
          thumb : shareMsg.imageUrl,
          media : {
            webpageUrl : webpageUrl.toString()
          }
        },
        scene : navigator.weixin.Scene.SESSION
      },shareMsg.success,shareMsg.fail);
    }else if(window.isDebug){
      setTimeout(async function(){
        let {default: html2canvas}  = await import('html2canvas');
        let canvas = await html2canvas(document.body);
        wx.showModal({
          title:"发送给:<br/>x5 <hr/>",
          content:"<div style='width:100%;height:300px;background-size:100% auto;background-image: url('" + canvas.toDataURL() +"')'></div>"
        });
      },0);
    }
  }
  menuClick(e){
	  let self = this;
	  const currentPage = _.last(getCurrentReactPages());
    let customPageMenuClickHandler = currentPage.wxPageDeclaration.onPageMenuClick || global._app.onPageMenuClick;
    if(customPageMenuClickHandler){
      customPageMenuClickHandler(e);
    }else{
      const isFunc = Boolean(currentPage.wxPageDeclaration.onShareAppMessage && _.isFunction(currentPage.wxPageDeclaration.onShareAppMessage));
      showActionSheet({
        itemList: isFunc && wx.showShareMenu.show ? ['分享'] : [],
        success: function(res){
          if(!res.cancel && res.tapIndex == 0){
            self.share();
          }
        }
      })
    }
  }

  exitClick() {
    if (global._app.onHide) {
      global._app.onHide();
    }
    wx.showModal({
      title: "提示",
      content: "小程序已经进入后台运行,点击激活,重新进入小程序!",
      showCancel: false,
      confirmText: "激活",
      success: function () {
        if (global._app.onShow) {
          global._app.onShow();
        }
      }
    });
  }

  render() {
    //等待appLaunch之后才能渲染


    let state = this.state;
    if(!state.launched){
      return <div style={{display:"none"}}></div>;
    }


    let iconStyle = {
      borderLeft: `1px solid ${state.color}`,
      borderBottom: `1px solid ${state.color}`
    };

    let title = state.title;
    title = getCurrentReactPage()?.i18n?.(title) || title;
    if (window.isPcx == false) {
      setAppTitle(title);
    }


    let backVisible = state.back ? 'visible' : 'hidden';
    if(window.microService.isMicroService && window.routes){
      let isMain = location.hash.substring(1) == (window.routes[0] ? window.routes[0].path.replace('/:pagetime?', ''):"");
      if(!isMain){
        backVisible = 'visible';
      }
    }


    let customHeadOptionRender = global._app.onHeadOptionRender;
    if(state.navigationStyle == "custom"){
      return <div style={{display:"none"}}></div>;
    }

    if(isStandalone){

      let isIosHeader = false;
      if(detector.os.name == "ios" && detector.os.version >= 8){
          isIosHeader = true;
      }


      return (
          <div>
            <div style={{backgroundColor: state.backgroundColor}}
                 className={cx('ui-flex','system-navigation-bar',isIosHeader ? "ios-header" : "",isIphonex?"ios-iphonex":"")}>
              <div onClick={this.onBack} className="head-back" style={{visibility: backVisible}}>
                {(!state.sendText) ? <i className="head-back-icon" style={iconStyle}/> : null}
                <span style={{color: state.color, fontSize: '16px'}}>{state.backText}</span>
              </div>
              <h3 className="head-title" style={{color: state.color}}>
                <i className="head-title-loading" style={{display: state.loading ? 'inline-block' : 'none'}}/>
                <span style={{fontSize: '16px'}}>{title}</span>
              </h3>

              {customHeadOptionRender?.({color: state.color})}
            </div>
          </div>
      )
    }else{
      return <div style={{display:"none"}}></div>;
    }
  }
}

let header = React.createElement(Header, null);
export default ReactDom.render(header, document.querySelector('#header'))
