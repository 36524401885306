import _,{isArray} from 'lodash';
import ReactDOM from 'react-dom';
import React from 'react'
import URI from 'urijs';
import { toObject } from 'core/inline-style-transformer'
import detector from "detector";
import { processChildren } from "./render/ComponentRenderWrapper";

function __styleToJSON(style) {
  // 兼容style为null, undefined, ""的情况
  if (!style) return {};
  if (typeof style == "string" && style.endsWith(";")) {
    style = style.substring(0, style.length - 1);
  }
  if (!style) return {};
  if (typeof style == "object") {
    return style;
  }
  style = fixRpx(style);
  return toObject(style);
}


function quote(str) {

  return str.replace(/\\/g, "\\\\").replace(/'/g, "\\'").replace(/[\n]/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t').replace(/\f/g, '\\f').replace(/\"/g, "\\\"");
}
window._quote = quote;
/**
 * 表达式出来的的字符串必须以双引号引起字符串
 * exp 字面值一定为字符串  实际值不一定为字符串
 * __exRun('"\"￥"+list2item.fyuanjia','list2item'))(list2item)
 * 1. 惰性求值遇到的问题是 异常情况编程整体求值 不是分段求值 不能解决部分异常的情况
 * 2. 字符串的处理应该在返回前转义 不在传入后转义 否则不能区分是字符串引号 还是值里面包含引号
 */
function __exRun(exp, ...args) {
  let method = "try{";
  for (let index in args) {
    method += "var " + args[index] + "= arguments[" + index + "];";
  }
  if (exp === "") {
    method += "var result= '';";
  } else if (exp === "()") {
    //兼容表达式分析中生成的保证优先级的括号
    method += "var result = undefined;";
  } else {
    method += "var result = " + exp + ";";
  }
  method += "if(typeof result == 'object'){" +
    "return '" + quote(exp) + "'}" +
    "else if(typeof result == 'string')" +
    "{return '\"' + _quote(result) + '\"';}" +
    "else" +
    "{" +
    "return result;}";
  method += "}catch(e){}";
  return new Function(method);
}

function _exRun(exp, ...args) {

  let method = "try{";
  for (let index in args) {
    method += "var " + args[index] + "= arguments[" + index + "];";
  }
  if (exp && typeof exp == "string") {
    //escape \n
    exp = exp.replace(/\n/g, "\\n");
  }
  if (exp === "") {
    method += "return '';";
  } else {
    method += "return " + exp + ";";
  }
  method += "}catch(e){}";
  return new Function(method);
}


function fixRpx(contents) {
  let rpxReg = /\b(\d+(\.\d+)?)rpx\b/g;
  if (rpxReg.test(contents)) {
    contents = contents.replace(rpxReg, function ($0, $1) {
      return $1 / 37.5 + "rem";
    });
  }
  return contents;
}



window.inputFiles = {};
window.blobs = {};

// 浏览器兼容代码
window.URL = window.URL || window.webkitURL;
navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;


function _resolveCordovaLocalFile(path) {
  return new Promise(function (resolve, reject) {
    window.resolveLocalFileSystemURL(path, function (entry) {
      entry.file(function (file) {
        let reader = new FileReader();
        reader.onloadend = function () {
          let blob = new Blob([this.result], { type: file.type });
          resolve(blob);
        };
        reader.readAsArrayBuffer(file);
      }, function (error) {
        reject(error);
      });
    }, function (error) {
      reject(error);
    });
  });
}

function isLocalFile(filePath) {
  if (_.startsWith(filePath, 'inputFile://') ||
    _.startsWith(filePath, 'blob://') ||
    _.startsWith(filePath, 'file://') ||
    _.startsWith(filePath, 'file:/') ||
    _.startsWith(filePath, 'cdv://')) {
    return true;
  }
  return false;
}



function resolveFileUrl(filePath) {
  return new Promise((resolve, reject) => {
    if (window.resolveLocalFileSystemURL) {
      window.resolveLocalFileSystemURL(filePath, function (entry) {
        resolve(entry.toInternalURL());
      }, function (error) {
        reject(error);
      });
    } else {
      resolveLocalFile(src).then((file) => {
        const URL = window.URL || window.webkitURL || window.mozURL;
        resolve(URL.createObjectURL(file));
      });
    }
  });
}

//taro中tempfileurl到object的逻辑
const convertObjectUrlToBlob = url => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (this.status === 200) {
        resolve(this.response);
      }
      else {
        reject({ status: this.status });
      }
    };
    xhr.send();
  });
};

function resolveLocalFile(filePath, file) {
  let promise = new Promise(function (resolve, reject) {
    if (file) {
      resolve(file);
    } else {
      let uplodFile;
      if (_.startsWith(filePath, 'inputFile://')) {
        uplodFile = window.inputFiles[filePath];
        resolve(uplodFile);
      } else if (_.startsWith(filePath, 'blob://')) {
        uplodFile = window.blobs[filePath];
        resolve(uplodFile);
      } else if (window.resolveLocalFileSystemURL) {
        _resolveCordovaLocalFile(filePath).then(function (blob) {
          resolve(blob);
        }).catch(function (error) {
          convertObjectUrlToBlob(filePath).then((blob) => {
            resolve(blob);
          }).catch((error) => {
            reject(error);
          })
        })
      } else {
        convertObjectUrlToBlob(filePath).then((blob) => {
          resolve(blob);
        }).catch((error) => {
          reject(error);
        })
      }
    }
  });
  return promise;
}

function doThen(params, res) {
  if (!params) {
    return;
  }
  let successCallback = params.success;
  let completeCallback = params.complete;
  if (_.isFunction(successCallback)) {
    successCallback.call(wx, res);
  }
  if (_.isFunction(completeCallback)) {
    completeCallback.call(wx, res);
  }
}
function doCatch(params, error) {
  if (!params) {
    return;
  }
  let errorInfo = { errMsg: ((error && error.errMsg) || error) };

  let failCallback = params.fail;
  let completeCallback = params.complete;
  if (_.isFunction(failCallback)) {
    failCallback.call(wx, errorInfo);
  }
  if (_.isFunction(completeCallback)) {
    completeCallback.call(wx, errorInfo);
  }
}

/*@deprecated*/
function checkStatus(response) {
  return new Promise(function (resolve, reject) {
    if (response.status >= 200 && response.status < 300) {
      resolve(response);
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      reject(error);
    }
  });
}

function fireEvent({eventName, event, defaultAction,eventHandler,currentTarget}) {
  let result;
  //  fireEvent 自动传递source  event instanceof CustomEvent
  if(event instanceof CustomEvent && typeof event.detail == 'object'){
    event.detail.source = currentTarget;
  }

  if(eventHandler){
    if (event && event instanceof Array) {
      result = eventHandler.apply(currentTarget,event);
    } else {
      //标准w3c 事件 或者平台封装可以cancel的事件 可以作为标准event传递 否则展开
      result = eventHandler(event);
    }
  }

  if (!(event && (event.cancel == true || event.defaultPrevented))) {
    result = defaultAction?.apply(currentTarget,[result]);
  }
  return result;
}

function fireWxEvent(self, type, detail, currentTarget) {
  const eventTypeNames = ['bind', 'catch'];
  for (let eventName of eventTypeNames) {
    if (self.props[`${eventName}${type}`]) {
      let rootNode = ReactDOM.findDOMNode(self);
      let event = self.createEventObjByNode(rootNode, type, new Event('mockEvent').timeStamp, currentTarget);
      if (detail) {
        event.detail = detail;
      }
      return self.__root__.__$$callFn(self.props[`${eventName}${type}`], [event]);
    }
  }
  /*
   * let bindName = "bind" + type; let catchName= "catch" + type; if
   * (self.props[bindName]||self.props[catchName]) { let rootNode =
   * ReactDOM.findDOMNode(self); let event =
   * self.createEventObjByNode(rootNode,type, new
   * Event('mockEvent').timeStamp,currentTarget) if(detail){ event.detail =
   * detail; } return self.__root__.__$$callFn(self.props[bindName]||
   * self.props[catchName], [event]); }
   */
}


function findReact(dom, traverseUp = 0) {
  const key = Object.keys(dom).find(key => {
    return key.startsWith("__reactFiber$") // react 17+
      || key.startsWith("__reactInternalInstance$"); // react <17
  });
  const domFiber = dom[key];
  if (domFiber == null) return null;

  // react <16
  if (domFiber._currentElement) {
    let compFiber = domFiber._currentElement._owner;
    for (let i = 0; i < traverseUp; i++) {
      compFiber = compFiber._currentElement._owner;
    }
    return compFiber._instance;
  }

  // react 16+
  const GetCompFiber = fiber => {
    //return fiber._debugOwner; // this also works, but is __DEV__ only
    let parentFiber = fiber.return;
    while (typeof parentFiber.type == "string") {
      parentFiber = parentFiber.return;
    }
    return parentFiber;
  };
  let compFiber = GetCompFiber(domFiber);
  for (let i = 0; i < traverseUp; i++) {
    compFiber = GetCompFiber(compFiber);
  }
  return compFiber.stateNode;
}


function getComponentEventTarget(dom) {
  let ownerInstance = findReact(dom);
  if (ownerInstance) {
    return ReactDOM.findDOMNode(ownerInstance);
  } else {
    return dom;
  }
}

function __toUrl(contextPath, relativePath, page) {
  let isCodePath = false;
  if (typeof page == "boolean") {
    isCodePath = page;
  }
  if (!relativePath) return relativePath;


  if (relativePath.indexOf("[") == 0 || relativePath.indexOf("{") == 0) {
    return relativePath;
  }

  if (relativePath.indexOf('://') != -1) {
    return relativePath;
  }
  // 兼容wx附件的地址
  if (relativePath == "//:0") {
    return relativePath;
  }
  if (relativePath == "about:blank") {
    return relativePath;
  }

  if (relativePath.indexOf("$UI") != -1) {
    return relativePath;
  }

  if (_.startsWith(relativePath, 'data:')) {
    return relativePath;
  }
  if (isLocalFile(relativePath)) {
    return relativePath;
  }
  let path = URI(relativePath).absoluteTo(contextPath);

  let result = path.toString();

  if (result.charAt(0) === "/" && !isCodePath) {
    //如果用户原本写的是 相对路径 或者$UI  编译后是相对路径  ./  那么需要添加 前面的路径  比如 /entry/pcxapp
    //如果用户原本写的是 绝对路径 那么 直接返回即可
    if (relativePath.charAt(0) === "/") {
      return result;
    } else {
      if (page && typeof page == "object" && page.serviceName) {
        return "/" + page.serviceName + "/" + page.contextName + result;
      }
      return "." + result;
    }
  }
  return result;
}

function normalizeUrl(url) {
  let uri = new URI(url);
  uri.pathname(uri.pathname().replace(".w", ""));
  url = uri.toString();
  let currentPage = _.last(getCurrentReactPages());
  if (currentPage && currentPage.wxPagePathResolve) {
    let _url = currentPage.wxPagePathResolve(url, true);
    return _url;
  } else {
    return url;
  }
}

function __renderBlock(items) {
  return React.Children.map(items,
    function (__react_current_item) {
      if (__react_current_item.type === "block") {
        return processChildren(__react_current_item.props.children);



      }
      return __react_current_item;
    }
  )
}


function __toJSONObjectSlot(items) {
  let result = {};
  React.Children.forEach(items.props.children,
    function (__react_current_item) {
      /**
       <attr:icon>
       <block key="aaa">
       <Icon ></Icon>
       <Icon ></Icon>
       </block>


       </attr:icon>
       *
       */


      let key = __react_current_item.props["attr_key"];
      if (key) {
        result[key] = __react_current_item;
      }
    }
  )
  return result;
}


function __toJSONArraySlot(items) {
  let result = [];
  React.Children.forEach(items.props.children,
    function (__react_current_item) {
      /**
       <attr:icon>
       <block>
       <Icon key="aaa"></Icon>
       <Icon key="bbb"></Icon>
       </block>

       <Icon key="aaa"></Icon>
       </attr:icon>
       *
       */

      if (__react_current_item.type === React.Fragment) {
        let childItem = {};
        React.Children.forEach(__react_current_item.props.children, (child) => {
          let key = child.props["attr_key"];
          if (key) {
            childItem[key] = child;
          }
        });
        result.push(childItem);
      } else {
        let childItem = {};
        let key = __react_current_item.props["attr_key"];
        if (key) {
          childItem[key] = __react_current_item;
          result.push(childItem);
        }
      }
    }
  )
  return result;
}

function __toArraySlot(items) {
  return React.Children.map(items,
    function (__react_current_item) {
      return __react_current_item;
    }
  );
}

function __getPopupContainer(triggerNode) {
  //不能基于触发元素 比如toolbar里面用了绝对布局 造成弹出窗口被下面内容遮挡
  /*if(triggerNode){
    return triggerNode.parentNode;
  }*/
  let pageNode = ReactDOM.findDOMNode(this);
  return pageNode || document.querySelector('#pcx-page-root');
}



/**
 *
 *
 __expression(({$row,restData1})=>{
      return $page.func1() + $row.fa + restData1.current.fb;
    },{$page,$row:item.listProviderInfo:""},["restData1"])


 __expression(
    (args)=>{
      return (text,record,index)=>{
        return this.getPage().xxxevent({..args,$event:{
            text,record,index
        }});
      }
    }
    ,
    {$page,$row:item.listProviderInfo:""},["restData1"])


  (text,record,index)=>{text}
 1.  event  $event
 2. meta
 $event={
  text
 }

 */



function __expression(fn, contextParams, compIdOrStateTreeVariables) {

  let $page = contextParams.$page;
  if (contextParams.listProviderInfo) {
    let listProviderInfo = typeof contextParams.listProviderInfo == "string" ? decodeURIComponent(contextParams.listProviderInfo) : contextParams.listProviderInfo;

    //listProviderInfo = JSON.parse(listProviderInfo);

    //通过listProviderInfo的refDataId  获取$row纯文本值的id  获取 行对象
    //hcr 通过$row上的$dataId获取数据对象
    if (listProviderInfo['$row'] && contextParams.$row) {
      let ref = $page.comp(contextParams.$row.$dataId);
      let idColumn = ref.getIdColumn();
      let rowId = contextParams.$row[idColumn];
      contextParams.$row = ref.getRowByID(rowId);
    }

    if (listProviderInfo['$parent'] && contextParams.$parent) {
      let ref = $page.comp(contextParams.$parent.$dataId);
      let idColumn = ref.getIdColumn();
      let rowId = contextParams.$parent[idColumn];
      contextParams.$parent = ref.getRowByID(rowId);
    }
  }


  //slm @compat 为了兼容pcx下 新旧机制的组件共存 比如这种情况  <span bind:text="button1.text"/> 所以参数列表 优先状态树 状态树上没有 才走comp查找
  for (let compId of compIdOrStateTreeVariables) {
    //data 组件优先组件本身
    let compInstance = $page.comp(compId);
    if (compInstance && typeof compInstance.newData == "function") {
      contextParams[compId] = compInstance;
    } else {
      let compData = $page.data[compId] || $page.comp(compId);
      contextParams[compId] = compData;
    }

  }

  try {
    return fn(contextParams);
  } catch (e) {
    console.debug('表达式执行异常:');
    console.debug(fn);
    console.debug(e);
    return '';
  }
}





function getAppConfig(key) {
  //扩张app的config逻辑。支持微服务描述自身的app config通过serviceMetaInfo中menu内容进行注册 合并 配置文件逻辑
  let config = getApp().getAppConfig()[key] || {};
  //获取微服务的配置
  if (key == "tabBar" && window.microService.tabBars.length > 0) {
    config.list = window.microService.tabBars;
  }

  return config;
}

function isTabbarPage(url) {
  let list = getAppConfig('tabBar').list;
  if (!list) return;
  let pages = list.map(o => o.pagePath)
  return pages.indexOf(url) !== -1
}

function toBoolean(value) {
  if (typeof value === "boolean")
    return value;
  else if (value == null || value == undefined || (value == "") || (value == "false"))
    return false;
  else
    return true;
}

//无效的props react会自动去掉，所有转化为data- 保留在html节点上 供css的selector获取
const componentProps = ['plain', 'loading', "size", "canvas-id"];
function compatComponentProps(props) {
  var result = {};
  _.forEach(props, (prop, key) => {
    if (componentProps.indexOf(key) != -1 && toBoolean(prop)) {
      result[`data-prop-${key}`] = prop;
    } else {
      result[key] = prop;
    }
  })
  return result;
}

function navigateHome() {
  let home = `${location.protocol}//${location.host}${__contextPath}`
  if (typeof location.replace == 'function') {
    location.replace(home)
  } else if (typeof history.replaceState == 'function') {
    window.history.replaceState({}, '', home)
    location.reload()
  } else {
    location.hash = '#'
    location.reload()
  }
}

function isInViewport(el) {
  let rect = el.getBoundingClientRect();
  return (
    rect.top >= 20 &&
    rect.left >= 0 &&
    rect.bottom <= window.outerHeight - 20 &&
    rect.right <= window.outerWidth
  );
}

/*此方法为wx:for服务*/
function _toArray(val) {
  //兼容propWrapper逻辑
  if (typeof val == "function") {
    val = val();
  }

  if (!val) return [];
  if (_.isNumber(val)) {
    let result = [];
    for (let i = 0; i < val; i++) {
      result[i] = i;
    }
    return result;
  } else if (_.isString(val)) {
    return val.split('');
  } else if (_.isArrayLike(val)) {
    //处理react节点类型 hidden=true的情况，直接过滤掉
    val.forEach(v => {
      if (v?.type == Symbol.for('react.fragment') || (v?.type?.toString() == Symbol().toString() && v?.__v_isVNode)) {
        //react|vue fragment对象 往下找一层子
        let children = v.props.children || v.children;
        if (children) {
          children.forEach(child => {
            if (child?.type?.isRenderWrapper && child?.props?.hidden) {
              _.pull(children, child);
            }
          })
        }
      } else if (v?.type?.isRenderWrapper && v?.props?.hidden) {
        // react|vue 普通reactnode|vnode对象 找hidden=true  直接删除
        _.pull(val, v)
      }
      //其他情况不处理
    })
    return val;
  } else if (_.isPlainObject(val)) {
    let result = []; for (let prop in val) {
      result.push(val[prop]);
    }
    return result;
  } else {
    return [];
  }
}

function createOrUpdateStyle(id, styleContent) {
  let style = document.getElementById(id);
  if (style) {
    style.innerHTML = styleContent
  } else {
    style = document.createElement('style');
    style.type = 'text/css';
    style.id = id;
    style.innerHTML = styleContent;
    document.getElementsByTagName('head')[0].appendChild(style);
  }
}

function openContactPage(sessionFrom) {
  if (window.isDebug) {
    wx.showModal({
      content: "进入客服会话, sessionFrom: " + sessionFrom,
      showCancel: false,
      confirmText: "返回"
    });
  }
}

function animationEnd(target, func) {
  if (detector.os.name == "ios" && document.body.classList.contains("ios-wkwebview")) {
    setTimeout(function () {
      func.call(target, {});
    }, 100);
  } else {
    let onceFunc = function (event) {
      target.removeEventListener('animationend', onceFunc);
      func.call(target, event);
    };
    target.addEventListener('animationend', onceFunc);
  }
}

function getSaveElement() {
  let a = document.querySelector('#_saveFile');
  if (!a) {
    a = document.createElement("a");
    a.id = "_saveFile";
    document.body.appendChild(a);
    a.style.display = "none";
  }
  return a;
}

const isX5App = (navigator.userAgent.indexOf("x5app") >= 0) || (navigator.userAgent.indexOf("Crosswalk") >= 0);
const isDingTalk = (navigator.userAgent.indexOf("DingTalk") >= 0);
const isWeixinBrowser = navigator.userAgent.indexOf("MicroMessenger") >= 0;
const isWeixinWebView = navigator.userAgent.indexOf("miniprogramhtmlwebview") >= 0;
const isIphonex = /iphone/gi.test(window.navigator.userAgent) && window.screen.height >= 812;

const isStandalone = (function () {
  let standalone = new URI(location.href).query(true).standalone;
  if (standalone == undefined) {
    standalone = isX5App || navigator.standalone;
  } else {
    standalone = toBoolean(standalone);
  }
  return standalone;
})();

const isDebug = (function () {
  return window.isDebug;
})();


function setAppTitle(title) {
  if ((!title) || (title && "undefined" == title)) {
    return;
  }
  if (isDingTalk) {
    if (detector.os.name == "ios") {
      window.WebViewJavascriptBridge && window.WebViewJavascriptBridge.callHandler("biz.navigation.setTitle", Object.assign({}, {
        "title": title,
        onFail: function () { },
        onSuccess: function () { }
      }), function (e) { });
    } else {
      if (!window.WebViewJavascriptBridgeAndroid_x5) {
        window.WebViewJavascriptBridgeAndroid_x5 = window.nuva && window.nuva.require();
      }
      window.WebViewJavascriptBridgeAndroid_x5 && window.WebViewJavascriptBridgeAndroid_x5(
        function () { },
        function () { },
        "biz.navigation",
        "setTitle",
        {
          "title": title,
          onFail: function () { },
          onSuccess: function () { }
        }
      )
    }
  } else {
    document.title = title;
  }
}

function propWrapper(propFn, contextVars) {
  let result = () => {
    return propFn(this.wxPageDeclaration.data, contextVars);
  }
  result.$$typeof = "dataPropFn";
  return result;
}




function childPropWrapper(propFn, wrapperIds, contextVars) {
  let result = () => {
    let children = propFn(this.wxPageDeclaration.data, contextVars);
    return children;
  }
  result.$$typeof = "dataPropFn";
  result.$$wrapperIds = wrapperIds;
  return result;
}




let doubleClickAttached = false;
function attachDoubleClickExitApp(conditionFn) {
  if (doubleClickAttached === false) {
    doubleClickAttached = true;
    document.addEventListener("deviceready", function () {
      let exitAppTicker = 0;
      let listener = function () {
        if (conditionFn()) {
          if (exitAppTicker === 0) {
            exitAppTicker++;
            var div = document.createElement('div');
            div.style.display = 'none';
            div.style.zIndex = '99999';
            div.style.position = 'fixed';
            div.style.width = '100%';
            div.style.bottom = '25px';
            div.style.textAlign = 'center';

            var span = document.createElement('span');
            span.style.fontSize = '18px';
            span.style.borderRadius = '3px';
            span.style.padding = '7px';
            span.style.backgroundColor = '#383838';
            span.style.color = '#F0F0F0';
            span.textContent = '再按一次退出应用';

            div.appendChild(span);
            document.body.appendChild(div);

            setTimeout(()=>{
              exitAppTicker = 0;
              document.body.removeChild(div);
            },400);
          } else if (exitAppTicker == 1) {
            navigator.app.exitApp();
          }
        } else {
          if (document.documentElement.classList.contains("x-focus-in")) {
            document.documentElement.classList.remove("x-focus-in");
          } else {
            history.back();
          }
        }
      };
      document.addEventListener('backbutton', listener, false);
      window.addEventListener('beforeunload', function () {
        document.removeEventListener('backbutton', listener, false);
      });
    }, false);
  }
}


/**
 *
 * 根据绑定列的元信息进行通用属性的处理
 *
 * 没有refColumnName 返回 {} 不处理
 * 有refColumnName
 *      currentRow不存在 返回 undefined
 *
 *
 */

function getRefUserData(props, page) {
  let { refColumnName } = props;
  let refRow = getRefRow(props, page);
  if (!refColumnName) {
    return {};
  }

  if (refRow) {
    return refRow._userdata;
  }
}

function getRefRow(props, page) {
  let { refDataId, refRow, refColumnName } = props;
  if (refDataId) {
    let data = getRefData(props, page);
    if (refRow && data) {
      let idColumn = data.getIdColumn();
      let rowId = refRow[idColumn];
      return data.getRowByID(rowId);
    }
  }
  return;
}

function getRefData(props, page) {
  let { refDataId } = props;
  if (refDataId) {
    if (refDataId.indexOf(":") != -1) {
      refDataId = refDataId.split(":")[0];  //第一项是真实的id
    }
    let data = page.comp(refDataId);
    return data;
  }
  return;
}

function addOpenerRoute(params, defaultRoute) {
  /**
   *  默认打开页面时候 自动传递当前页面route做为openerRoute 移动门户需要特殊指定为$router
   *
   */
  let uri = new URI(params.url);
  if (uri.hasSearch('openerRoute')) {
    return;
  }
  if (params.openerRoute) {
    uri.addSearch("openerRoute", params.openerRoute);
  } else {
    uri.addSearch("openerRoute", defaultRoute);
  }
  params.url = uri.toString();
}


class Deferred {
  constructor() {
    this.state = "pending";
    //"pending" : "fulfilled", "rejected"
    this.promise = new Promise((resolve, reject) => {
      this.resolve = (data) => {
        this.state = "fulfilled";
        resolve(data);
      };
      this.reject = (data) => {
        this.state = "rejected";
        reject(data);
      };
    });
  }
}


async function debounceNextViewTickRun(action, debounceHolder,page) {
  debounceHolder._lastDebounceNextViewTick = action;
  if (!debounceHolder._debounceNextViewTickWaiters) {
    debounceHolder._debounceNextViewTickWaiters = [];
    setTimeout(async () => {
      try {
        //等待页面动画
        await document?._currentViewTransition?.updateCallbackDone;

        //等待batch完成
        await new Promise((resolve, reject) => {
          let checkTimeout = false;
          setTimeout(()=>{
            checkTimeout = true;
          },1000);
          let checkBatchState = ()=>{
            if(checkTimeout){
              resolve();
            }else if(page.dataStateLinker.batchInfo?.stateDispatched === false){
              (window.requestIdleCallback || window.setTimeout)(() => {
                checkBatchState();
              })
            }else {
              resolve();
            }
          }
          checkBatchState();

        });

        let result = await debounceHolder._lastDebounceNextViewTick();
        if (debounceHolder._debounceNextViewTickWaiters) {
          for (const _debounceNextViewTickWaiter of debounceHolder._debounceNextViewTickWaiters) {
            _debounceNextViewTickWaiter.resolve(result);
          }

        }
      } catch (e) {
        if (debounceHolder._debounceNextViewTickWaiters) {
          for (const _debounceNextViewTickWaiter of debounceHolder._debounceNextViewTickWaiters) {
            _debounceNextViewTickWaiter.reject(e);
          }
        }
      } finally {
        debounceHolder._debounceNextViewTickWaiters = undefined;
      }

    }, 30)
  }
  let _result = new Deferred();
  debounceHolder._debounceNextViewTickWaiters.push(_result);
  return _result.promise;
}

function parseMicroServiceSrc(src) {
  let prefixPath = src.replace(/(mobileapp|pcapp|pcxapp).*/, "$1");
  let contextName, serviceName, parentPath;
  let parts = prefixPath.split("/");
  contextName = parts.splice(-1)[0];
  serviceName = parts.splice(-1)[0];
  parentPath = parts.join("/");
  return {
    parentPath, serviceName, contextName
  }
}


function dfsFind(data, condition) {
  if(isArray(data)){
    data = {
      children : data
    }
  }
  if(data.children && data.children.length > 0){
    for (const item of data.children) {
      const result = dfsFind(item, condition);
      if (result !== undefined) {
        return result;
      }
    }
  }else {
    if (condition(data)) {
      return data;
    }
  }
}


let requestIdleCallback = window.requestIdleCallback || function(cb) {
    let start = Date.now();
    return setTimeout(function() {
      cb({
        didTimeout: false,
        timeRemaining: function() {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };

let cancelIdleCallback = window.cancelIdleCallback || function(id) {
    clearTimeout(id);
  };


export {
  fireEvent,fireWxEvent, isStandalone, isX5App, isDingTalk, isWeixinBrowser, isIphonex, isWeixinWebView, doThen, doCatch, toBoolean, resolveLocalFile, checkStatus,
  findReact, __toUrl,
  attachDoubleClickExitApp,
  __renderBlock,
  __toArraySlot,
  __toJSONObjectSlot,
  __toJSONArraySlot,
  __expression,
  __getPopupContainer,
  __styleToJSON,
  addOpenerRoute,
  getRefUserData,
  getRefData,
  getRefRow,
  _toArray, _exRun, __exRun, normalizeUrl, getAppConfig, compatComponentProps, isLocalFile, getComponentEventTarget,
  navigateHome, resolveFileUrl, isInViewport, createOrUpdateStyle, openContactPage, animationEnd, getSaveElement, setAppTitle,
  propWrapper, childPropWrapper, Deferred, debounceNextViewTickRun, parseMicroServiceSrc,dfsFind,requestIdleCallback,cancelIdleCallback
};
